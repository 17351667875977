import { Component, Input, AfterViewInit, ChangeDetectionStrategy, ElementRef, Renderer2, ViewChild } from '@angular/core';

@Component({
    selector: 'before-after',
    templateUrl: './before-after.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./before-after.component.scss']
})
export class BeforeAfterComponent implements AfterViewInit {
    @Input() before = '';
    @Input() after = '';

    @ViewChild('after_element') afterImageElement!: ElementRef;


    constructor(
        private renderer: Renderer2,
        private el: ElementRef
    ) {
    }

    ngAfterViewInit() {
        const img = this.afterImageElement.nativeElement;
        if (img.complete) {
            this.processImage(img);
        } else {
            img.onload = () => {
                this.processImage(img);
            };
        }
    }

    processImage(img: HTMLImageElement) {
        const aspectRatio = img.naturalWidth / img.naturalHeight;
        if (aspectRatio < 1) {
            this.renderer.setStyle(this.el?.nativeElement, 'width', '40rem');
        }
    }
}