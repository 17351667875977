import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
    public sameColorButtons: boolean = false;
    public optionTitle: string = '';
    public optionValue: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data.sameColorButtons) {
            this.sameColorButtons = data.sameColorButtons;
        }
        if (data.option) {
            this.optionTitle = data.option;
        }
    }

    confirm() {
        if (this.optionTitle === '') {
            this.dialogRef.close(true);
        } else {
            this.dialogRef.close({ confirm: true, optionValue: this.optionValue });
        }
    }

    reject() {
        if (this.optionTitle === '') {
            this.dialogRef.close(false);
        } else {
            this.dialogRef.close({ confirm: false, optionValue: this.optionValue });
        }
    }
}
