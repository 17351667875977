<div
    class="wrapper"
    [class]="breakpointService.currentBreakpointClass">
    <div class="drawer-header">
        <h2>{{ selectedServiceType.name }} options</h2>
        <button
            mat-icon-button
            (click)="dismissUnsaved()"
            class="close">
            <mat-icon svgIcon="xMark"></mat-icon>
        </button>
    </div>
    <div
        class="content"
        cdkScrollable>
        <div
            class="image"
            *ngIf="selectedServiceType.name === 'Item Removal'">
            <item-removal
                [serviceRequest]="serviceRequest"
                [imageHeight]="imageHeight"
                [itemData]="irData"
                (update)="setImageData($event)" />
        </div>
        <div
            class="service-info"
            [ngClass]="selectedServiceType.name">
            <ng-template #serviceInfoAnchor></ng-template>
        </div>
    </div>
    <div class="drawer-footer">
        <div class="actions">
            <button
                class="remove"
                [disabled]="addingService"
                *ngIf="selectedServiceType.added"
                (click)="addRemoveService()">
                Remove
                {{ selectedServiceType.name }}
            </button>
            <button
                *ngIf="
                    selectedServiceType.added &&
                    selectedServiceType.dirty &&
                    !addingService
                "
                class="save"
                (click)="saveService()">
                Save
            </button>
            <div class="right">
                <button
                    class="add"
                    [disabled]="
                        addingService || !componentRef?.instance.isValid()
                    "
                    *ngIf="!selectedServiceType.added"
                    (click)="addRemoveService()">
                    Add
                    {{ selectedServiceType.name }}
                </button>
            </div>
        </div>
    </div>
</div>
