import { Component } from '@angular/core';
import { BreakpointService } from '../services/breakpoint.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import { BaSliderComponent } from '../components/ba-slider/ba-slider.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { FooterComponent } from '../components/footer/footer.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { CreateAnonymousComponent } from '../components/create-anonymous/create-anonymous.component';


@Component({
    selector: 'app-public',
    templateUrl: './public.component.html',
    standalone: true,
    imports: [
        BaSliderComponent,
        CommonModule,
        MatIconModule,
        FooterComponent,
        MatDialogModule,
        CreateAnonymousComponent
    ],
    styleUrls: ['./public.component.scss']
})
export class PublicComponent {
    public project: any;
    public loading = true;
    public publicToken: any;
    public showVideo = false;
    public slideOut = false;

    constructor(
        public breakpointService: BreakpointService,
        private route: ActivatedRoute,
        private api: ApiService,
        private dialog: MatDialog
    ) { }

    async ngOnInit() {
        let token = this.route.snapshot.paramMap.get('token');
        const response = await this.api.get(`project/${token}/get-public`);
        this.project = response;
        this.loading = false;
        this.preloadWebsite();
    }

    exploreVs() {
        this.slideOut = true;

        setTimeout(() => {
            window.location.href = "https://www.virtualstaging.com/try-virtual-staging";
        }, 500);
    }

    preloadWebsite() {
        let preloadFrame = document.createElement('iframe');
        preloadFrame.style.display = 'none';
        preloadFrame.src = "https://www.virtualstaging.com/try-virtual-staging";
        document.body.appendChild(preloadFrame);
    }

    getLogo(): string {
        if (this.breakpointService.currentBreakpointClass === "is-phone-portrait" || this.breakpointService.currentBreakpointClass === "is-phone-landscape" || this.breakpointService.currentBreakpointClass === "is-tablet-portrait" || this.breakpointService.currentBreakpointClass === "is-tablet-landscape") {
            return "logoSmall";
        } else {
            return "logo";
        }
    }

    goToTrustpilot() {
        window.open("https://www.trustpilot.com/review/vs24.com", "_blank");
    }

    openAnonProjectModal(uploads: any) {
        let images = Array.from(uploads).map((file: any) => {
            return {
                url: URL.createObjectURL(file),
                name: file.name,
                file: file
            }
        });
        this.dialog.open(CreateAnonymousComponent, {
            data: { images, inviteLink: this.project.inviteLink },
            maxWidth: '100vw',
            maxHeight: '100dvh',
        });
    }

    uploadImages(event: any) {
        let uploads = event.target.files;
        this.openAnonProjectModal(uploads);
    }

}
