import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../services/api.service";
import { ConfirmDialogComponent } from "../components/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "claim",
    templateUrl: "./claim.component.html",
    styleUrls: ["./claim.component.scss"],
})
export class ClaimComponent implements OnInit {
    @ViewChild("newProject", { static: false })
    private user: any = null;
    public remainingTime = "";
    private timer: number | undefined = undefined;

    constructor(
        private api: ApiService,
        private router: Router,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.api.get("user/claim/check").then((user) => {
            if (user.eligible) {
                this.user = user;
                this.setTimer();
            } else {
                this.router.navigate(["/projects"]);
            }
        });
    }

    async finish() {
        const dialogData = {
            message: `Congratulations!`,
            moreInfo: `Your account has just been credited with some free Virtual Staging credits! Please use your credits in the next ${this.remainingTime}, before they expire.`,
            confirm: { title: `Start new project` },
            reject: { title: `Back to project list` }
        };

        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: dialogData,
        });

        const confirmed = await firstValueFrom(confirmDialogRef.afterClosed());

        if (confirmed) {
            this.router.navigate(["/new-project"]);
        } else {
            this.router.navigate(["/projects"]);
        }
    }

    acquireCredits() {
        this.api.post("user/claim/acquire").then(async () => {
            this.finish();
        });
    }

    setTimer() {
        const endDate: any = new Date(this.user.created);
        endDate.setDate(endDate.getDate() + 30);

        const updateCountdown = () => {
            const now: any = new Date();
            const duration = endDate - now;

            if (duration < 0) {
                this.remainingTime = "Finished";
                clearInterval(this.timer);
                this.timer = undefined;
            } else {
                const days = Math.floor(duration / (1000 * 60 * 60 * 24));
                const hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
                this.remainingTime = `${days} days ${hours} hours ${minutes} minutes`;
            }
        };

        if (this.timer) {
            updateCountdown();
        } else {
            updateCountdown();
            this.timer = setInterval(updateCountdown, 60000);
        }
    }

}
