<img-comparison-slider>
    <img
        #after_element
        id="before_element"
        width="100%"
        slot="after"
        [src]="before" />
    <img
        id="after_element"
        width="100%"
        slot="before"
        [src]="after" />
    <div
        class="divider-hint"
        slot="handle"></div>
</img-comparison-slider>
