import { Injectable } from '@angular/core';

declare const fbq: any;
declare const gtag: any;
declare const ga: any;

@Injectable()
export class PixelService {
    constructor() { }

    public trackEvent(event: string, properties: any = {}) {
        switch (event) {
            case 'Purchase':
                this.fPixelTrackEvent(event, properties);
                this.gAdsTrackEvent('conversion', {
                    'send_to': 'AW-695086855/a_fcCP78nLgBEIfeuMsC',
                    'value': properties.value,
                    'currency': properties.currency,
                    'transaction_id': Date.now()
                });
                this.gAdsTrackEvent('payment', {
                    event_category: 'Buyers',
                    value: properties.value
                })
                break;

            case 'CompleteRegistration':
                this.fPixelTrackEvent(event, properties);
                this.gAdsTrackEvent('conversion', {
                    'send_to': 'AW-695086855/nwzqCND1jLkBEIfeuMsC'
                });
                this.gAdsTrackEvent('registration', {
                    event_category: 'Leads',
                });
                break;

            case 'creditsUsed':
                this.fPixelTrackEvent('credits', properties);
                this.gAdsTrackEvent('conversion', {
                    'send_to': 'AW-695086855/rdJzCLvDosEDEIfeuMsC',
                    'value': properties.value,
                    'currency': properties.currency
                });
                this.gAdsTrackEvent('payment', {
                    event_category: 'Buyers',
                    value: properties.value
                })
                break;
        }
    }

    public fPixelTrackEvent(event: string, properties: any = {}) {
        if (typeof fbq === 'undefined') {
            return;
        }

        fbq('track', event, properties);
    }

    public gAdsTrackEvent(event: string, properties: any = {}) {
        if (typeof gtag === 'undefined') {
            return;
        }

        gtag('event', event, properties);
    }
}