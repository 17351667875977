import { Component } from '@angular/core';

@Component({
  selector: 'app-blank-page',
  standalone: true,
  imports: [],
  templateUrl: './blank-page.component.html',
  styleUrl: './blank-page.component.scss'
})
export class BlankPageComponent {

}
