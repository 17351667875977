import { Component } from '@angular/core';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { Router } from '@angular/router';
import { Fp2GuidelinesComponent } from '../fp2-guidelines/fp2-guidelines.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'app-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {
	constructor(
		public breakpointService: BreakpointService,
		private router: Router,
		public dialog: MatDialog
	) { }

	goToProjectPage(param?: string) {
		this.router.navigate(['new-project'], { queryParams: { type: param } });
	}

	goTo(path: string) {
		window.open(path, "_blank");
	}

	open3dFloorPlanModal() {
		this.dialog.open(Fp2GuidelinesComponent, {
			maxWidth: '100vw',
			panelClass: 'fp-guidelines-dialog',
			data: {
				price: 120,
				noAccept: true
			}
		});

	}
}
