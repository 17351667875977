import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../services/api.service";

@Component({
    selector: "validate",
    templateUrl: "./validate.component.html",
    styleUrls: ["./validate.component.scss"],
})
export class ValidateComponent {
    constructor(
        private api: ApiService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        const email = this.route.snapshot.paramMap.get("email");
        const token = this.route.snapshot.paramMap.get("token");
        this.api
            .post("validate/user", { email: email, token: token })
            .then(() => {
                return this.router.navigate(["/projects"]);
            });
    }
}
