<div
    class="wrapper"
    [ngClass]="breakpointService.currentBreakpointClass">
    <div class="slider">
        <img
            *ngIf="!breakpointService.isTouchDevice"
            (mouseenter)="carouselImages()"
            (mouseleave)="stopCarousel()"
            (click)="openProject()"
            [src]="getThumbnail()"
            alt="" />
        <img
            *ngIf="breakpointService.isTouchDevice"
            (swipeleft)="swipe(1)"
            (swiperight)="swipe(-1)"
            (click)="openProject()"
            [src]="getThumbnail()"
            alt="" />
        <div class="actions">
            <button
                *ngIf="!project.shared && project.status !== 'Draft'"
                class="share-project"
                (click)="openShareModal()">
                <mat-icon svgIcon="share" />
                <h6>Share</h6>
            </button>
            <button
                class="options"
                [class.disabled]="!project.accepted && project.shared"
                [matMenuTriggerFor]="projectMenu">
                <mat-icon svgIcon="options" />
            </button>
        </div>
        <div class="image-triggers">
            <div
                class="radio-input"
                *ngFor="let button of buttons.slice(0, 5); let i = index">
                <input
                    type="radio"
                    [(ngModel)]="currentImage"
                    [value]="i"
                    name="{{ project.id }}"
                    id="{{ project.id }}-{{ i }}"
                    hidden />
                <label for="{{ project.id }}-{{ i }}"></label>
            </div>
        </div>
        <div
            class="shared-project"
            *ngIf="!project.accepted && project.shared">
            <p>
                <span>{{ project.owner }}</span> wants to share their project
                with you
            </p>
            <div class="share-actions">
                <button
                    class="accept"
                    (click)="confirmAccept()">
                    Accept
                </button>
                <button
                    class="decline"
                    (click)="confirmDecline()">
                    Decline
                </button>
            </div>
        </div>
        <h6
            class="shared-by"
            *ngIf="project.shared && project.accepted">
            Shared by <br />{{ project.owner }}
        </h6>
    </div>
    <div class="meta">
        <div class="row-1">
            <button
                class="title"
                (click)="openProject()">
                <h5>{{ project.name }}</h5>
            </button>
            <p class="number-of-images">
                <mat-icon svgIcon="photo" />{{
                    project.service_requests_length
                }}
                images
            </p>
        </div>
        <div class="row-2">
            <p class="status">{{ getStatus() }}</p>
            <p
                class="eta"
                *ngIf="shouldDisplayEta(project)">
                ETA
                {{
                    remainingTime.split(":")[0] +
                        "h" +
                        remainingTime.split(":")[1] +
                        "m"
                }}
            </p>
        </div>
    </div>
</div>

<mat-menu
    #projectMenu="matMenu"
    xPosition="before"
    yPosition="below">
    <button
        mat-menu-item
        (click)="openDraft()"
        [disabled]="project.status !== 'Draft'">
        <span>Edit project</span>
    </button>
    <button
        mat-menu-item
        (click)="addMorePhotos()"
        [disabled]="project.status === 'Draft'">
        <span>Add more photos</span>
    </button>
    <button
        mat-menu-item
        [disabled]="project.status !== 'Draft'"
        (click)="openRenameModal()">
        <span>Rename project</span>
    </button>
    <button
        mat-menu-item
        [disabled]="project.status !== 'Draft'"
        (click)="openDeleteModal()">
        <span>Delete project</span>
    </button>
</mat-menu>

<ng-template #renameProjectModal>
    <div class="rename-project">
        <h4>Enter a new name for the project</h4>
        <input
            type="text"
            [(ngModel)]="project.name"
            id="project-name" />
        <div class="buttons">
            <button
                mat-button
                (click)="renameProject()"
                class="primary">
                <p>Rename</p>
            </button>
            <button
                mat-button
                (click)="closeRenameModal()"
                class="transparent">
                <p>Close</p>
            </button>
        </div>
    </div>
</ng-template>
