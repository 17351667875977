<div class="wrapper">
    <div class="header">
        <button
            mat-icon-button
            (click)="dialog.closeAll()">
            <mat-icon svgIcon="xMark" />
        </button>
    </div>
    <div class="content">
        <h2>You're About to Start a New Project</h2>
        <p>
            Please be aware that once a project is submitted, photos cannot be
            added to it. Instead, a new project will be initiated, and our
            designers will
            <b>recognize it as a continuation of the existing project.</b>
        </p>
        <p>
            For example, if you submit another angle of a room already being
            staged, our designers will ensure that the furniture pieces and
            style match exactly with the first project.
        </p>
        <div class="buttons">
            <button
                mat-button
                (click)="continueProject()"
                class="primary">
                <p>Continue</p>
            </button>
        </div>
    </div>
</div>
