<div
    class="wrapper"
    [class]="breakpointService.currentBreakpointClass">
    <div class="modal-header">
        <h2
            class="modal-title"
            id="modal-basic-title">
            Invite email preview
        </h2>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="close()">
            <mat-icon svgIcon="xMark"></mat-icon>
        </button>
    </div>
    <div class="modal-body">
        <p class="modal-intro-txt">
            Here's a preview of your invite email. It tells your friend a little
            bit about VirtualStaging.com, so you don't have to. Add a personal
            message to tell them why you use VirtualStaging.com.
        </p>
        <div class="form-group">
            <label for="mailTxt">Personal message</label>
            <textarea
                class="form-control"
                id="mailTxt"
                rows="5"
                [(ngModel)]="mailTxt"></textarea>
        </div>
        <button
            class="save-email"
            (click)="saveEmailChange()">
            Save Changes
        </button>

        <div class="email-template">
            <div class="head">
                <div class="logo">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50.321"
                        height="44.83"
                        viewBox="0 0 50.321 44.83">
                        <g
                            id="Logo"
                            transform="translate(-981.795 -710.39)">
                            <path
                                id="Fill_8_copy"
                                data-name="Fill 8 copy"
                                d="M1029.757,755.22h-2.83v-1.867h-4.492v1.867h-7.885v-.427l3.236-3.54c1.013-1.115,1.6-1.912,1.6-2.689,0-.619-.316-.974-.866-.974-.651,0-.992.457-1.106,1.481H1014.7a3.581,3.581,0,0,1,3.969-3.814,4.016,4.016,0,0,1,2.754.9,2.969,2.969,0,0,1,.936,2.274,4.7,4.7,0,0,1-1.771,3.4l-1.093,1.085h2.944v-.76l3.817-6.9h3.506v5.935h1.168v2.159h-1.168v1.867h0Zm-4.621-4.027H1027V747.64Zm-18.4,4.027a.475.475,0,0,1-.422-.261l-19.658-38.6a2.207,2.207,0,0,0-1.963-1.214h-2.894v-3.623h15.114a2.577,2.577,0,0,1,2.235,1.3l17.155,29.452a.537.537,0,0,1,0,.531l-6.92,12.173a.475.475,0,0,1-.412.241Zm10.777-16.422a6.035,6.035,0,0,0,.838-2.2,2.119,2.119,0,0,0-.551-1.46,3.684,3.684,0,0,0-1.448-.874,10.543,10.543,0,0,0-1.906-.448l-10.183-17.8a10.534,10.534,0,0,1,2.208-2.461,13.374,13.374,0,0,1,4.453-2.337,18.893,18.893,0,0,1,5.736-.827,17.389,17.389,0,0,1,12.712,4.943.275.275,0,0,0,.433-.06l1.958-3.424.356.208-6.5,11.362a.412.412,0,0,1-.562.152c-1.246-2.044-5.053-4.208-7.97-4.12-2.09.063-3.136.672-3.136,2.014a1.617,1.617,0,0,0,.535,1.222,5.562,5.562,0,0,0,1.461.934,19.025,19.025,0,0,0,2.138.827q1.211.4,2.565.9a26.472,26.472,0,0,1,3.207,1.437c.209.111.414.225.616.346l-6.674,11.876Z"
                                fill="#416aa8" />
                        </g>
                    </svg>
                </div>
            </div>
            <div class="body">
                <div class="logo">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="167.439"
                        height="128.687"
                        viewBox="0 0 167.439 128.687">
                        <defs>
                            <linearGradient
                                id="linear-gradient"
                                x1="1.25"
                                y1="1.025"
                                x2="-0.25"
                                y2="-0.025"
                                gradientUnits="objectBoundingBox">
                                <stop
                                    offset="0"
                                    stop-color="#564cb2" />
                                <stop
                                    offset="0.502"
                                    stop-color="#437fbf" />
                                <stop
                                    offset="1"
                                    stop-color="#58c9ea" />
                            </linearGradient>
                        </defs>
                        <g
                            id="Icon"
                            transform="translate(-927.313 -827.117)">
                            <path
                                id="Shape_11_copy_2"
                                data-name="Shape 11 copy 2"
                                d="M944.053,873.814s-2.28-2.528-6.17,0a4.71,4.71,0,0,1-6.438-.632"
                                fill="none"
                                stroke="#70b9de"
                                stroke-width="1.83"
                                opacity="0.502" />
                            <path
                                id="Shape_11_copy_4"
                                data-name="Shape 11 copy 4"
                                d="M1094.073,892.008s-2.28-2.528-6.17,0a4.71,4.71,0,0,1-6.438-.632"
                                fill="none"
                                stroke="#70b9de"
                                stroke-width="1.83"
                                opacity="0.502" />
                            <path
                                id="Shape_11_copy_3"
                                data-name="Shape 11 copy 3"
                                d="M1069.06,839.337s-2.28-2.528-6.17,0a4.71,4.71,0,0,1-6.438-.632"
                                fill="none"
                                stroke="#019dd9"
                                stroke-width="1.83"
                                opacity="0.502" />
                            <ellipse
                                id="Ellipse_3_copy"
                                data-name="Ellipse 3 copy"
                                cx="53.85"
                                cy="53.84"
                                rx="53.85"
                                ry="53.84"
                                transform="translate(960.38 837.97)"
                                opacity="0.8"
                                fill="url(#linear-gradient)" />
                            <g
                                id="Ellipse_3_copy_2"
                                data-name="Ellipse 3 copy 2"
                                transform="translate(953.02 837.97)"
                                fill="none"
                                stroke="#70b9de"
                                stroke-width="1.83"
                                opacity="0.502">
                                <ellipse
                                    cx="53.85"
                                    cy="53.84"
                                    rx="53.85"
                                    ry="53.84"
                                    stroke="none" />
                                <ellipse
                                    cx="53.85"
                                    cy="53.84"
                                    rx="52.935"
                                    ry="52.925"
                                    fill="none" />
                            </g>
                            <path
                                id="Shape_9"
                                data-name="Shape 9"
                                d="M1005.426,927.568l1.428,2.47,2.9.532-2.022,2.059.367,2.8-2.678-1.2-2.678,1.2.367-2.8-2.022-2.059,2.9-.532,1.428-2.47"
                                fill="#335c9c" />
                            <path
                                id="Shape_9_copy_2"
                                data-name="Shape 9 copy 2"
                                d="M931.647,904.036l1.428,2.47,2.9.532-2.022,2.059.367,2.8-2.678-1.2-2.678,1.2.367-2.8-2.022-2.059,2.9-.532,1.428-2.47"
                                fill="#2b5595" />
                            <path
                                id="Shape_9_copy"
                                data-name="Shape 9 copy"
                                d="M1036.973,950.3l.909,1.729,1.849.372-1.287,1.441.234,1.959-1.7-.838-1.7.838.234-1.959-1.287-1.441,1.849-.372.909-1.729"
                                fill="#335c9c" />
                            <path
                                id="Shape_10"
                                data-name="Shape 10"
                                d="M983.325,855.289s7.418-.854,7.418-8.253c0,0,.285,8.253,9.13,8.253-8.844,0-9.13,8.253-9.13,8.253C990.743,856.143,983.325,855.289,983.325,855.289Z"
                                fill="#70b9de" />
                            <path
                                id="Shape_10_copy_3"
                                data-name="Shape 10 copy 3"
                                d="M972.674,831.833s4.239-.488,4.239-4.716a4.927,4.927,0,0,0,5.216,4.716,4.927,4.927,0,0,0-5.216,4.716C976.913,832.321,972.674,831.833,972.674,831.833Z"
                                fill="#70b9de" />
                            <path
                                id="Shape_10_copy_4"
                                data-name="Shape 10 copy 4"
                                d="M1042.041,893.455s4.239-.488,4.239-4.716a4.927,4.927,0,0,0,5.217,4.716,4.927,4.927,0,0,0-5.217,4.716C1046.28,893.943,1042.041,893.455,1042.041,893.455Z"
                                fill="#049bd7" />
                            <path
                                id="Shape_10_copy_2"
                                data-name="Shape 10 copy 2"
                                d="M1063.589,930.326s5.651-.651,5.651-6.288a6.57,6.57,0,0,0,6.956,6.288,6.569,6.569,0,0,0-6.956,6.287C1069.24,930.975,1063.589,930.326,1063.589,930.326Z"
                                fill="#70b9de" />
                            <path
                                id="Shape_10_copy"
                                data-name="Shape 10 copy"
                                d="M958.623,945.4s4.239-.487,4.239-4.715a4.926,4.926,0,0,0,5.216,4.715,4.927,4.927,0,0,0-5.216,4.716C962.862,945.887,958.623,945.4,958.623,945.4Z"
                                fill="#70b9de" />
                            <path
                                id="Shape_14"
                                data-name="Shape 14"
                                d="M1030.059,884.693a3.848,3.848,0,0,0,0-4c-3.295.121-3.609,2.4-3.609,2.4-.233-6.847,7.217-8.006,7.217-8.006C1035.2,881.906,1030.059,884.693,1030.059,884.693Zm0-2.4s-3.522,4.331-3.721,13.611h5.469s.927,14.284-4.48,17.614h-4.486c-5.444-3.329-4.511-17.614-4.511-17.614h6.329a15.117,15.117,0,0,0-3.623-9.607v-1.6s3.385,2.309,4.333,7.99a5.038,5.038,0,0,1,.178-1.585s.12-5.944,3.609-9.607Zm-8.12,7.206s-4.911-.269-5.413-6.405-2.706-8.006-2.706-8.006,10.84,3.411,10.826,10.408v1.6l-5.413-4.8S1018.615,885.318,1021.94,889.5Zm-10.407,7.549h-29.28a2.288,2.288,0,0,1,0-4.575h1.372v-21.5a1.373,1.373,0,1,1,2.745,0v2.287h21.045v-2.287a1.373,1.373,0,1,1,2.745,0v21.5h1.372a2.288,2.288,0,1,1,0,4.575ZM1007.415,876H986.37v3.66h21.045Zm0,6.405H986.37v3.66h21.045Zm0,6.4H986.37v3.66h21.045Zm-23.332,9.15h25.62a1.372,1.372,0,1,1,0,2.745h-1.372v12.352a1.372,1.372,0,0,1-2.745,0V900.705H988.2v12.352a1.372,1.372,0,0,1-2.745,0V900.705h-1.372a1.372,1.372,0,1,1,0-2.745Z"
                                fill="#fff" />
                        </g>
                    </svg>
                </div>
                <div class="mainTxt">
                    {{ user?.first_name }} {{ user?.last_name }} sent you a free
                    <br />
                    Virtual Staging trial with VirtualStaging.com
                </div>
                <div class="dynamicTxt">
                    {{ mailTxt }}
                </div>
                <div class="claim-btn">
                    Claim your free virtual staging trial now
                </div>
            </div>
            <div class="footer">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="350"
                    height="142.6"
                    viewBox="0 0 350 142.6">
                    <g
                        id="Footer"
                        transform="translate(-831.958 -1451.54)">
                        <text
                            id="Our_mailing_address:_VS24_Inc._113_Cherry_St_Seattle_WA_98104-2205_US_1_206_350-7773"
                            data-name="Our mailing address:
                  VS24, Inc. 113 Cherry St, Seattle, WA 98104-2205, US
                  +1 (206) 350-7773"
                            transform="translate(1006.958 1531.14)"
                            fill="#0068bb"
                            font-size="16.47"
                            font-family="Roboto-Bold, Roboto"
                            font-weight="700">
                            <tspan
                                x="-76.749"
                                y="0">
                                Our mailing address:
                            </tspan>
                            <tspan
                                fill="#1a3150"
                                font-size="14.64"
                                font-family="Roboto-Medium, Roboto"
                                font-weight="500">
                                <tspan
                                    x="-174.483"
                                    y="29.28">
                                    VS24, Inc. 113 Cherry St, Seattle, WA
                                    98104-2205, US
                                </tspan>
                                <tspan
                                    x="-61.023"
                                    y="58.56">
                                    +1 (206) 350-7773
                                </tspan>
                            </tspan>
                        </text>
                        <circle
                            id="Ellipse_1"
                            data-name="Ellipse 1"
                            cx="18.3"
                            cy="18.3"
                            r="18.3"
                            transform="translate(943.36 1451.54)"
                            fill="#1d4e8e" />
                        <circle
                            id="Ellipse_1_copy"
                            data-name="Ellipse 1 copy"
                            cx="18.3"
                            cy="18.3"
                            r="18.3"
                            transform="translate(989.12 1451.54)"
                            fill="#1d4e8e" />
                        <circle
                            id="Ellipse_1_copy_2"
                            data-name="Ellipse 1 copy 2"
                            cx="18.3"
                            cy="18.3"
                            r="18.3"
                            transform="translate(1033.95 1451.54)"
                            fill="#1d4e8e" />
                        <image
                            id="Layer_10"
                            data-name="Layer 10"
                            width="15"
                            height="19"
                            transform="translate(954 1460)"
                            xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAATCAYAAABPwleqAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAD6ADAAQAAAABAAAAEwAAAACB7oOwAAACbUlEQVQ4EYVUz2sTQRT+Znc2m0aTtgmlDWn8dRBP6qkXL3oW1JPgtR5E8I/Q4EFR+1eIJ08FQUEQpBehJ9GDiCAVlKYhaUuTdJPsru97s9sGL86GmXnzvvfe977diWneeNHGP8PAHJ2ksqOVysNBH/dcrdgL0wc5SJH/mby8yvSaJzBS0uNkpBKXrGqe0x4BMwcBfBKhdjgY4WAQIYkFLsG12SKCsMAelLo1TJkZeWZ2NIzGqC1Ucf3iGVRKvkZ/3PyBXndf6HhawDKQIw9ksnQcY+XSeaw9uIrlxTmUCvQCtx5GaLe/wIrNQcGUAldNkABhWETr3jVcaM7xGN+2++j3xzjo9ZG6OD3Xnl1eSZKmSrc8exKXz5UVsPF9G/db6xgNI0STMYLA1yJ0epwoGh8bWJxequJsQypOhAJ9oxS+NQiK0reij/Hm1M01FsRkEqPZWMTrp7cRmARLczMafBhP0NuLMZSqdx6t49fP3wgsBWTPKhgnqeD5qIRWnCKaHPFFFIxF6QRQSix8P1AcA5UpJ/bs+x463S5Wn7xBoz6PZ6tXRDgPX7d28fjVBpII6OzsCM4pxpgjtfmKBiLK2w+bqC/X8fzuiuQPsSUKv3v/GWkco1yekWBHmdWFoPvQabB6rVLBbKUolqtQsB7mRf1YNDFTgtHvKW02R7D2zzTs2RlJkh+6lXg3UvnuM+BxvLsEYRgqpl4VtXRIckcms6TnXDAm5D4V6v39CK2XnwTs409nDwlvBjkKZrpNI38GOY+sgiyJXIyxyCt0PEkQBlmzGUK/f8lEwXZZMR+aWe5vIKpy736OL3Hqz/T4C0iS8NkwpxNnAAAAAElFTkSuQmCC" />
                        <image
                            id="Layer_11"
                            data-name="Layer 11"
                            width="13"
                            height="14"
                            transform="translate(1001 1463)"
                            xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAOCAYAAAD0f5bSAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAADaADAAQAAAABAAAADgAAAADNokBEAAABGElEQVQoFY3SsUtCURTH8VtIc+Ba4NJuUzgk0ppmUP4Bjf09TdHWICTR4CKGgjRFtNtSUDlUWKlkQxTP7+95T1weGB34cM497513L5fnoihy2MIlXvGCp8Cz77fI63Aa2MQIf0WPh++4R1FDDSTjh8aXb2r3AnLQxzsp59wKwmizOMAAGexhGX3o/Yx2uoPFCUUWNbzhCHnoNJ9Q3GjoNi6neZv61K8tHVLsY+Ab3Xm2s3igWETZGj7vksfQ8eIIh2b15uyB5XBoieYQdXvo8xl5AenfPucML6LKehV1fOAYa7iARTe8CGueUxShW6vgCmHEQ82w4+tv8hjKyWhopzJ0lP/EIy+VNCQ7uEYf+kGTP6x6+mE34CZW4PVK2BicdwAAAABJRU5ErkJggg==" />
                        <image
                            id="Layer_12"
                            data-name="Layer 12"
                            width="20"
                            height="20"
                            transform="translate(1042 1459)"
                            xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAFKADAAQAAAABAAAAFAAAAACRFdLHAAADQUlEQVQ4EW1US08TURg982gprUoAC6JSMGI0kYAmqCtDYnChUYwbjYmuTIx/wMfWlSswcelCF66IC1wYNphoXBA1QRfGB5GAUURCqVQRWtuZuZ7v3pk+Eu90Ovd+j3POd+d+Y2XOjC4rpdIWLMgPSn4KliULzhUNHNpvJpWYGqvOoTvrCoA4BMTMTVgEJN66YfANATklTshCLbAl2GgBfD9AuewjUEEFI1ImhIJlngY1IjUeA6sBpSoBaUs3Y19PBo2xOAI/LDtiC4kjgohRryWGtwDbwlLyyuje2Y7x2xfw7O55XL88hLWyB4+3lqXjzbYYNRGcAdExFCXgtigThTs6mtC9Lakj+/a0IrV5E0osv/JymGWKMpIFOFJrnmKhQtuy4doOPs0sYXzqK+bzBTx48g7F3+toSMRIFlSBKuUbaGGXmYaiT8itzuGRZT7SKvChaHBcB17JI5PDcJ81KQikzcsPAvptONx57hLtAVxH4sLSYWVd2UMv8NDR1opTx/qRSsYxM5fFo4lXONzXhaHBfjTEHUxOfUCx4OPK8CHs3dWEp9M/MDYxjcWFRbiJeLjXCq6I9jyF9uYUbl0c0GzP33zD/YcvMLC7HTfP9mrb8b7tyGxNYktjTK8PdrXg5EAHTt8Yw1ruF5IUIu/C5gZwWPCVAOtYbHgleixslFhyOHo7m/BydgX3JmeRL/zV1v2ZFlw6cQDrRQ/Klw22qNAyb0f+q0OcXFdeAvD49XdcGxnHSjaPj+eO4s7VQR1+pKcVAcl9xX1XstPMMlc9nF7VcLx9v4Dc6h8qdzA7v1IJTiYTbAR2sDQCq7QjfZUITgxOjTzaeLrg6A8Gz5pT9Vl0WCSRlysXxcpVPwxgjbzQzRYws/8naF+osB7QxEub12RySi31gZVVlVyyDC/LcXmIZDTG5GgESCW0W9tc5hg4doacj7pRrVNnCLNXUvi8VMRPPueya0DCRclxkSsorNKW3+BRIrVsY9nz8SVXRJ6mJbaqa/FzF4q3MsOjy4xMC6HNthKPKAiYxEbnzaPKYOlp/UkTP6OkRWVEseFHhF9sMZqi4euDzGxh4+0T2PfYyXzqBLHrQTtjZal4jmUSYfwDeyxhpUtFHdkAAAAASUVORK5CYII=" />
                    </g>
                </svg>
            </div>
        </div>
    </div>
</div>
