import { Component, Inject, ComponentRef, ViewContainerRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { VsInfoComponent } from './service-info/vs-info/vs-info.component';
import { FcInfoComponent } from './service-info/fc-info/fc-info.component';
import { IrInfoComponent } from './service-info/ir-info/ir-info.component';
import { IeInfoComponent } from './service-info/ie-info/ie-info.component';
import { TwilightInfoComponent } from './service-info/twilight-info/twilight-info.component';
import { WcInfoComponent } from './service-info/wc-info/wc-info.component';
import { VrInfoComponent } from './service-info/vr-info/vr-info.component';
import { FpInfoComponent } from './service-info/fp-info/fp-info.component';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/app/environments/environment';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { firstValueFrom } from 'rxjs';
import { Subject } from 'rxjs';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { ChangeDetectorRef } from '@angular/core';
import tiers from './service-info/fp-info/tiers';
import { Fp2InfoComponent } from './service-info/fp2-info/fp2-info.component';
import { Fp2GuidelinesComponent } from '../fp2-guidelines/fp2-guidelines.component';


@Component({
	selector: 'app-services-modal',
	templateUrl: './services-modal.component.html',
	styleUrls: ['./services-modal.component.scss']

})
export class ServicesModalComponent {
	public serviceRequest: any;
	public serviceTypes: any;
	@ViewChild('serviceInfoAnchor', { read: ViewContainerRef }) serviceInfoAnchor: ViewContainerRef | undefined;
	public componentRef: ComponentRef<any> | undefined;
	public selectedServiceType: any = {};
	public imageHeight: string = '60rem';
	public annotationsOverlayVisible: boolean = true;
	public cart: any = [];
	public currentAnnotations: any = [];
	public irData: any = [];
	public addingService: boolean = false;
	public savingService: boolean = false;
	public furnitureStyles: any = [];
	public roomTypes: any = [];
	public missingInfo: boolean = false;
	private addServiceCompleteSubject = new Subject<void>();
	public vrAdded: boolean = false;
	public fp2Added: boolean = false;
	@Output() public reopen: EventEmitter<any> = new EventEmitter();

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private api: ApiService,
		public dialogRef: MatDialogRef<ServicesModalComponent>,
		private dialog: MatDialog,
		public breakpointService: BreakpointService,
		public cdr: ChangeDetectorRef
	) { }

	ngOnInit() {
		this.serviceRequest = this.data.serviceRequest;
		this.roomTypes = this.data.roomTypes;
		this.furnitureStyles = this.data.furnitureStyles;
		if (this.data.projectType === 'floorplan') {
			this.serviceTypes = this.data.tasks.filter((task: any) => task.name === 'Floor Plan');
		} else {
			this.serviceTypes = this.data.tasks.filter((task: any) => task.name !== 'Floor Plan');
		}
		// this.serviceTypes = this.serviceTypes.filter((task: any) => task.name !== '3D Floor Plan');
		this.checkIf360AndAddFlags();
		this.serviceTypes.sort((a: any, b: any) => a.sort - b.sort);
		if (!this.vrAdded) {
			this.selectedServiceType = this.serviceTypes[0];
		} else {
			this.selectedServiceType = this.serviceTypes.find((service: any) => service.name === "Virtual Renovation");
		}
		if (!this.fp2Added) {
			this.selectedServiceType = this.serviceTypes[0];
		} else {
			this.selectedServiceType = this.serviceTypes.find((service: any) => service.name === "3D Floor Plan");
		}
		this.serviceTypes = this.serviceTypes.map((service: any) => {
			if (this.serviceRequest.service_request_tasks.find((task: any) => task.settings_task?.id === service.id)
			) {
				service.added = true;
			}
			return service;
		});
		if (this.serviceRequest.service_request_tasks.length > 0) {
			let irTask = this.serviceRequest.service_request_tasks.find((task: any) => task.name === "Item Removal");
			if (irTask) {
				if (typeof irTask.data === 'string') {
					this.irData = JSON.parse(irTask.data).imageData;
				} else {
					this.irData = irTask.data.imageData;
				}
			}
		}
	}

	ngAfterViewInit() {
		this.selectService(this.selectedServiceType);
		this.cdr.detectChanges();
	}

	toCamelCase(str: string) {
		return str
			.split(" ")
			.map((word, index) => {
				if (index === 0) {
					return word.toLowerCase();
				}
				return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
			})
			.join("");
	}

	async saveOrAdd(): Promise<void> {
		const dialogData = {
			icon: 'warning',
			message: `Do you want to save your work first?`,
			moreInfo: `We noticed you've entered some information for this service but haven't saved it yet. To include this service in your project, please make sure to save your changes.`,
			confirm: { title: `Yes, save my work` },
			reject: { title: `No, don't save my work` }
		};

		const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: dialogData,
		});

		const confirmed = await firstValueFrom(confirmDialogRef.afterClosed());

		if (confirmed) {
			if (this.selectedServiceType.added) {
				await this.saveService();
			} else {
				if (this.componentRef && this.componentRef.instance) {
					if (this.componentRef.instance.validateService()) {
						this.getServiceInfo();
					} else {
						this.missingInfo = true;
					}
				}
			}
		} else {
			if (this.selectedServiceType.added && this.componentRef) {
				this.componentRef.instance.service = JSON.parse(this.componentRef.instance.hardCopy);
				this.selectedServiceType.dirty = false;
			} else {
				this.selectedServiceType.dirty = false;
			}
		}
	}

	async checkBeforeSelect(service: any) {
		if (this.selectedServiceType.dirty && service.id !== this.selectedServiceType.id) {
			await this.saveOrAdd();
		} else if (this.selectedServiceType.dirty && service.id === this.selectedServiceType.id) {
			return;
		} else {
			this.selectService(service);
		}
		if (this.missingInfo) {
			this.missingInfo = false;
			return;
		}
		this.addServiceCompleteSubject.subscribe(() => {
			this.selectService(service);
		});

	}

	selectService(service: any) {
		this.selectedServiceType = service;
		if (service.added) {
			this.currentAnnotations = this.serviceRequest.service_request_tasks.find((task: any) => task.settings_task.id === service.id).data.imageData;
			if (service.name === "Item Removal") {
				this.irData = this.serviceRequest.service_request_tasks.find((task: any) => task.settings_task.id === service.id).data.imageData;
			}
			if (this.currentAnnotations?.length > 0) {
				this.annotationsOverlayVisible = false;
			}
		} else {
			this.currentAnnotations = [];
			this.irData = [];
			this.annotationsOverlayVisible = true;
		}

		if (this.componentRef) {
			this.componentRef.destroy();
		}

		let component: any;

		switch (service.name) {
			case "360 Virtual Staging":
				component = VsInfoComponent;
				break;
			case "Virtual Staging":
				component = VsInfoComponent;
				break;
			case "Floor Change":
				component = FcInfoComponent;
				break;
			case "Item Removal":
				component = IrInfoComponent;
				break;
			case "Image Enhancement":
				component = IeInfoComponent;
				break;
			case "Twilight":
				component = TwilightInfoComponent;
				break;
			case "Wall Change":
				component = WcInfoComponent;
				break;
			case "Virtual Renovation":
				component = VrInfoComponent;
				break;
			case "Floor Plan":
				component = FpInfoComponent;
				break;
			case "3D Floor Plan":
				component = Fp2InfoComponent;
				break;
			default:
				break;
		}

		if (component !== undefined && this.serviceInfoAnchor !== undefined) {
			this.componentRef = this.serviceInfoAnchor.createComponent(component);
			if (service.name === "Virtual Staging" || service.name === "360 Virtual Staging" || service.name === "Virtual Renovation" || service.name === "Floor Plan" || service.name === "3D Floor Plan") {
				this.componentRef.instance.allStyles = this.furnitureStyles;
				this.componentRef.instance.roomTypes = this.roomTypes;
			}
			this.componentRef.instance.serviceInfo.subscribe(async (serviceInfo: any) => {
				await this.addService(serviceInfo);
				this.addServiceCompleteSubject.next();
			});
			this.componentRef.instance.serviceRequest = this.serviceRequest;
			this.componentRef.instance.task = this.selectedServiceType;
			this.componentRef.instance.projectGuid = this.data.projectGuid;
			this.componentRef.instance.annotations = this.currentAnnotations;
			this.componentRef.instance.annotationsChange?.subscribe((annotations: any) => {
				this.handleAnnotationsChange(annotations);
			});
			this.componentRef.instance.changed = (dirty: boolean) => {
				this.selectedServiceType.dirty = dirty;
			}
		}
	}

	async closeDialog() {
		if (this.selectedServiceType.dirty) {
			await this.saveOrAdd();
		}
		if (this.missingInfo) {
			this.missingInfo = false;
			return;
		}
		this.dialogRef.close();
	}

	checkIfAnyServiceAdded(): boolean {
		return this.serviceTypes.some((service: any) => service.added);
	}

	hideAnnotationsOverlay() {
		this.annotationsOverlayVisible = false;
	}

	checkIf360AndAddFlags() {
		for (let service of this.serviceTypes) {
			if (this.serviceRequest.service_request_tasks.length === 0) {
				service.added = false;
			} else if (this.serviceRequest.service_request_tasks.find((task: any) => task.settings_task.id === service.id)) {
				service.added = true;
				if (service.name === "Virtual Renovation") {
					this.vrAdded = true;
				}
				if (service.name === "3D Floor Plan") {
					this.fp2Added = true;
				}
			}
			service.disabled = false;
			if (service.name === "360 Virtual Staging") {
				service.icon = 'virtualStaging';
				if (!this.serviceRequest.asset.is360) {
					service.disabled = true;
					service.sort = 7
					service.selected = false;
				} else {
					service.disabled = false;
					service.sort = 1
					service.selected = true;
				}
				continue;
			}
			if (service.name === "Virtual Staging") {
				if (this.serviceRequest.asset.is360) {
					service.disabled = true;
					service.sort = 7
				} else {
					service.disabled = false;
					service.sort = 1
				}
			}
			service.icon = this.toCamelCase(service.name);
		}
	}

	markNot360() {
		let dialogData = {
			icon: 'warning',
			message: `Are you sure that this image isn't a 360 panoramic shot?`,
			confirm: { title: `Yes, I'm sure` },
			reject: { title: `No, cancel` }
		};
		let confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: dialogData,
		});

		confirmDialogRef.afterClosed().subscribe(async (confirmed) => {
			if (confirmed === true) {
				let service360 = this.serviceRequest.service_request_tasks.find((task: any) => task.name === "360 Virtual Staging");
				if (service360) {
					await this.removeService();
					await this.addService({
						name: "Virtual Staging",
						price: 24,
						settings_task: {
							id: 5
						},
						sort: 1,
						data: service360.data,
						is_special: false,
						reference_assets: service360.reference_assets,
						extra: false,
					})
				}
				this.api
					.post(
						`assets/${this.serviceRequest.asset.id}/set-non-360`
					).then(() => {
						this.serviceRequest.asset.is360 = false;
						this.reopen.emit(this.serviceRequest);
					})
			}
		});
	}

	async addService(serviceInfo: any): Promise<void> {
		this.addingService = true;
		if (serviceInfo) {
			let updatedTypes = this.serviceTypes.map((service: any) => {
				if (service.id === serviceInfo.settings_task.id) {
					service.added = true;
				}
				return service;
			});
			this.serviceTypes = [...updatedTypes];
			if (serviceInfo.name === "Virtual Staging" || serviceInfo.name === "360 Virtual Staging" || serviceInfo.name === "Virtual Renovation") {
				localStorage.setItem(`style-${this.data.projectGuid}`, `${serviceInfo.data.furnitureStyles}`);
			}
		}
		if (serviceInfo.name === "Virtual Renovation") {
			if (serviceInfo.extra) {
				serviceInfo.price = this.selectedServiceType.price + this.selectedServiceType.extra_price;
			} else {
				serviceInfo.price = this.selectedServiceType.price;
			}
		}

		await this.api.post(
			`project/${this.data.projectGuid}/serviceRequest/${this.serviceRequest.id}/task`,
			{
				name: serviceInfo.name,
				price: serviceInfo.price,
				settingsTask: serviceInfo.settings_task.id,
				sort: serviceInfo.sort,
				data: JSON.stringify(serviceInfo.data),
				isSpecial: serviceInfo.is_special,
				assets: serviceInfo.reference_assets,
				extra: serviceInfo.extra,
			}).then((res) => {
				res.data = JSON.parse(res.data);
				this.serviceRequest.service_request_tasks.push(res);
				if (serviceInfo.name === "3D Floor Plan") {
					this.serviceRequest.is_floor_plan = true;
				}
				this.addingService = false;
				this.selectedServiceType.dirty = false;
				if (this.componentRef) {
					this.componentRef.instance.hardCopy = JSON.stringify(res);
					this.componentRef.instance.service = res;
				}
				this.data.getEta();
				if (serviceInfo.name === "Virtual Renovation") {
					this.vrAdded = true;
				}
				if (serviceInfo.name === "3D Floor Plan") {
					this.fp2Added = true;
				}
				this.cdr.detectChanges();
				setTimeout(() => {
					let serviceWrapper = document.querySelector('#services-wrapper');
					serviceWrapper?.scrollIntoView({ behavior: "smooth", block: "center" });
				}, 50)
			}).catch((err) => {
				console.log(err)
				this.addingService = false;
			})
	}

	async saveService() {
		this.savingService = true;
		const serviceToSave = this.serviceRequest.service_request_tasks.find((task: any) => task.settings_task?.id === this.selectedServiceType.id);
		if (serviceToSave.name !== "Item Removal") {
			serviceToSave.data.imageData = this.currentAnnotations;
		} else {
			serviceToSave.data.imageData = this.irData;
			serviceToSave.price = serviceToSave.data.imageData.length * 0.5;
		}
		if (serviceToSave.name === "Floor Plan") {
			let tier = tiers.find((tier: any) => tier.name === serviceToSave.data.tier);
			serviceToSave.price = tier.price;
		}
		if (serviceToSave.name === "Virtual Renovation") {
			if (serviceToSave.extra) {
				serviceToSave.price = this.selectedServiceType.price + this.selectedServiceType.extra_price;
			} else {
				serviceToSave.price = this.selectedServiceType.price;
			}
		}
		await this.api.put(
			`project/${this.data.projectGuid}/serviceRequest/${this.serviceRequest.id}/task/${serviceToSave.id}`,
			{
				name: serviceToSave.name,
				price: serviceToSave.price,
				settingsTask: serviceToSave.settings_task.id,
				sort: serviceToSave.sort,
				data: JSON.stringify(serviceToSave.data),
				isSpecial: serviceToSave.is_special,
				assets: serviceToSave.reference_assets,
				extra: serviceToSave.extra,
			}).then((res) => {
				this.selectedServiceType.dirty = false;
				this.savingService = false;
				if (this.componentRef) {
					this.componentRef.instance.hardCopy = JSON.stringify(serviceToSave);
				}
				this.data.getEta();
			}).catch((err) => {
				console.log(err)
			})
	}

	async addRemoveService() {
		if (this.selectedServiceType.name === "Virtual Renovation" && this.serviceRequest.service_request_tasks.length > 0 && !this.selectedServiceType.added) {
			let proceed = await this.virtualRenovationCheck();
			if (!proceed) {
				return;
			}
			this.removeAllServices();
		}
		if (this.selectedServiceType.name === "3D Floor Plan" && this.serviceRequest.service_request_tasks.length > 0 && !this.selectedServiceType.added) {
			let proceed = await this.fp2Check();
			if (!proceed) {
				return;
			}
			this.removeAllServices();
		}
		if (this.selectedServiceType.name === "3D Floor Plan" && this.serviceRequest.service_request_tasks.length === 0 && !this.selectedServiceType.added && this.componentRef?.instance.validateService()) {
			let proceed = await this.fpGuidelinesCheck();
			if (!proceed) {
				return;
			}
		}
		if (this.selectedServiceType.added) {
			this.removeService();
		} else {
			this.getServiceInfo();
		}
	}


	async fpGuidelinesCheck(): Promise<boolean> {
		let fpDialog = this.dialog.open(Fp2GuidelinesComponent, {
			maxWidth: '100vw',
			panelClass: 'fp-guidelines-dialog',
			data: {
				price: this.selectedServiceType.price
			}
		});

		const result = await firstValueFrom(fpDialog.afterClosed());

		return !!result;
	}


	async virtualRenovationCheck(): Promise<boolean> {
		const dialogData = {
			icon: 'warning',
			message: `If you add Virtual Renovation, all other services will be removed and your input will be lost. Do you want to continue?`,
			confirm: { title: `Yes, continue` },
			reject: { title: `No, cancel` }
		};

		const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: dialogData,
		});

		const confirmed = await firstValueFrom(confirmDialogRef.afterClosed());

		if (confirmed) {
			return true;
		} else {
			return false;
		}
	}

	async fp2Check(): Promise<boolean> {
		const dialogData = {
			icon: 'warning',
			message: `If you add 3D Floor Plan, all other services will be removed and your input will be lost. Do you want to continue?`,
			confirm: { title: `Yes, continue` },
			reject: { title: `No, cancel` }
		};

		const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: dialogData,
		});

		const confirmed = await firstValueFrom(confirmDialogRef.afterClosed());

		if (confirmed) {
			return true;
		} else {
			return false;
		}
	}

	removeAllServices() {
		for (let task of this.serviceRequest.service_request_tasks) {
			this.api.delete(
				`project/${this.data.projectGuid}/serviceRequest/${this.serviceRequest.id}/task/${task.id}`
			).then((res) => {
				console.log(res)
			}).catch((err) => {
				console.log(err)
			})
		}
		this.serviceTypes = this.serviceTypes.map((service: any) => {
			service.added = false;
			return service;
		});
		this.serviceRequest.service_request_tasks = [];
	}

	getServiceInfo() {
		if (this.componentRef && this.componentRef.instance) {
			this.componentRef.instance.triggerServiceInfoEvent();
		}
	}

	async removeService() {
		let task = this.serviceRequest.service_request_tasks.find((task: any) => task.settings_task?.id === this.selectedServiceType.id);

		if (task) {
			this.serviceRequest.service_request_tasks = this.serviceRequest.service_request_tasks.filter((service: any) => service.id !== task.id);
			await this.api.delete(
				`project/${this.data.projectGuid}/serviceRequest/${this.serviceRequest.id}/task/${task.id}`
			)
			if (task.name === "3D Floor Plan") {
				this.serviceRequest.is_floor_plan = false;
			}
			if (this.componentRef && this.componentRef.instance) {
				this.componentRef.instance.serviceRequest = this.serviceRequest;
				this.componentRef.instance.setService();
			}
			this.selectedServiceType.added = false;
			this.selectedServiceType.dirty = false;
			this.currentAnnotations = [];
			this.annotationsOverlayVisible = true;
			if (this.selectedServiceType.name === "Item Removal") {
				this.irData = [];
			}
			if (task.name === "Virtual Renovation") {
				this.vrAdded = false;
			}
			if (task.name === "3D Floor Plan") {
				this.fp2Added = false;
			}
		}

	}

	checkIfServiceAdded(serviceId: any) {
		return this.serviceTypes.find((service: any) => service.id === serviceId).added;
	}

	handleAnnotationsChange(annotations: any) {
		this.currentAnnotations = [...annotations];
		if (this.componentRef && this.componentRef.instance) {
			this.componentRef.instance.service.data.imageData = this.currentAnnotations;
			this.componentRef.instance.checkIfNeedsToSave();
		}
	}

	getThumbnailFromUrl() {
		let url = environment.cdn + this.serviceRequest.asset.url;
		return environment.server + "/asset/thumbnail/" + url.split("/").slice(-1)[0];
	}

	getInitialTask() {
		return this.serviceRequest.service_request_tasks.find((task: any) => task.name === this.selectedServiceType.name);
	}

	setImageData(imageData: any) {
		this.irData = imageData;
		if (this.irData.length === 0) {
			this.removeService();
		}
		if (this.componentRef && this.componentRef.instance) {
			this.componentRef.instance.service.data.imageData = this.irData;
			this.componentRef.instance.checkIfNeedsToSave();
		}
	}

	calculatePrice(service: any) {
		if (service.name === "Item Removal") {
			return this.calculateIrTotal();
		} else if (service.name === "Virtual Renovation") {
			return this.calculateVrPrice();
		} else {
			return service.price;
		}
	}

	calculateVrPrice(): number {
		let vr = this.serviceRequest.service_request_tasks.find((task: any) => task.name === "Virtual Renovation");
		return vr?.price;
	}

	calculateIrTotal(): number {
		let total = 0;
		for (let task of this.serviceRequest.service_request_tasks) {
			if (task.name === "Item Removal" && task.data?.imageData?.length > 0) {
				let boxes = task.data.imageData.length;
				total += boxes * 0.5;
			}
		}
		return total;
	}

	async goToNextImage() {
		if (this.selectedServiceType.dirty) {
			await this.saveOrAdd();
		}
		if (this.missingInfo) {
			this.missingInfo = false;
			return;
		}
		this.dialogRef.close({
			goToNextImage: true,
		});
	}
}
