<button
    class="trigger"
    mat-button
    (menuOpened)="isMenuOpen = true"
    (menuClosed)="isMenuOpen = false"
    [class.active]="isMenuOpen"
    [matMenuTriggerFor]="mainMenu"
    [ngClass]="breakpointService.currentBreakpointClass">
    <mat-icon svgIcon="menu" />
    <div
        class="avatar"
        *ngIf="
            breakpointService.currentBreakpointClass !== 'is-phone-portrait'
        ">
        <img
            [src]="user?.avatar?.url || 'assets/profile.png'"
            alt="" />
    </div>
</button>

<mat-menu
    #mainMenu="matMenu"
    xPosition="before"
    yPosition="below"
    class="wide main-menu">
    <div
        mat-menu-item
        *ngIf="
            breakpointService.isTouchDevice &&
            balance &&
            (balance['vs credits'] > 0 || balance.credits > 0)
        ">
        <app-wallet />
    </div>
    <button
        class="floorplan-button"
        *ngIf="breakpointService.isTouchDevice"
        (click)="goToProjectPage('floorplan')">
        Order 3D Floor Plan<mat-icon svgIcon="newProject" />
    </button>
    <button
        mat-menu-item
        (click)="getRouteLink()">
        <span>Billing</span>
    </button>
    <hr />
    <button
        mat-menu-item
        (click)="goTo('my-profile')">
        <span>My account</span>
    </button>
    <button
        mat-menu-item
        (click)="goTo('referral-program')">
        <span>Referral program</span>
    </button>
    <hr />
    <button
        mat-menu-item
        (click)="openIntercom()">
        <span>Help</span>
    </button>
    <button
        mat-menu-item
        (click)="logOut()">
        <span>Logout</span>
    </button>
</mat-menu>
