export const environment = {
    production: false,
    app_url: "https://test.vs24.com/",
    api: "https://server.vs24develop.com/api/",
    noncfendpoint: "https://server.vs24develop.com/api/",
    server: "https://server.vs24develop.com",
    downloadServer: "https://server.vs24develop.com/",
    cdn: "https://vs24.ams3.digitaloceanspaces.com/Old/",
    stripeKey: "pk_test_935H5WP4yBLNTpeeNxhJTMni",
    googleClient: "664339024373-np3fm75ts8m1h076r1s3eq6io0q83nqp.apps.googleusercontent.com",
    appleClient: "-",
    intercomKey: "rbvh0uu8",
    mixpanelToken: "",
    cohereKey: "-",
    webHook: 'https://hooks.slack.com/services/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',
};

import "zone.js/plugins/zone-error"; // Included with Angular CLI.
