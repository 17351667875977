import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-redfin-coupon',
    templateUrl: './redfin-coupon.component.html',
    styleUrls: ['./redfin-coupon.component.scss']
})
export class RedfinCouponComponent {
    public listingId: string = '';
    public marketCtrl = new UntypedFormControl();
    public regions: any[] = [];

    constructor(
        public dialogRef: MatDialogRef<RedfinCouponComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.regions = data.regions;
    }

    save() {
        this.dialogRef.close({
            listingId: this.listingId,
            market: this.marketCtrl.value
        });
    }
}
