import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import CryptoJS from 'crypto-js';
import { AuthService } from '../services/auth.service';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-process-anonymous',
    templateUrl: './process-anonymous.component.html',
    styleUrls: ['./process-anonymous.component.scss']
})
export class ProcessAnonymousComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private authService: AuthService,
        private api: ApiService,
        private router: Router
    ) { }

    ngOnInit(): void {
        let dataString = this.route.snapshot.queryParams['data'];
        let email = decodeURIComponent(this.route.snapshot.queryParams['email']);
        if (dataString && dataString !== "[object Object]") {
            let data = JSON.parse(decodeURIComponent(dataString));
            let decryptedData = this.decryptData(data)
            let user = JSON.parse(localStorage.getItem('user') || 'null');
            if (!decryptedData.new && user) {
                if (user.email === email) {
                    this.router.navigate([`projects/${decryptedData.project}/summary`]);
                    return;
                } else if (user.email !== email) {
                    this.authService.logout();
                    this.router.navigate([`login`]);
                    return;
                }
            } else if (decryptedData.new && user) {
                this.authService.logout();
            } else if (!decryptedData.new && !user) {
                this.authService.logout();
                this.router.navigate([`login`]);
                return;
            }
            localStorage.setItem('refreshToken', decryptedData.refresh_token);
            localStorage.setItem('authToken', 'Bearer ' + decryptedData.token);
            this.logIn(decryptedData.project);
        } else {
            console.log("No valid data received.");
        }
    }

    decryptData(dataHashed: any) {
        const secretKey = CryptoJS.enc.Hex.parse("6822210328fbf967f3d2b82c406a48db");
        const iv = CryptoJS.enc.Hex.parse("bb15e14a19625aeb8d20a69050f402fa");
        const encrypted = CryptoJS.AES.decrypt(dataHashed, secretKey, { iv: iv }).toString(CryptoJS.enc.Utf8);
        return JSON.parse(encrypted);
    }

    logIn(guid: string) {
        this.api.updateHeaders();
        this.api.get('user').then((user) => {
            localStorage.setItem('user', JSON.stringify(user));
            this.authService.updateAuthStatus();
            this.router.navigate([`projects/${guid}/summary`]);
        });
    }
}
