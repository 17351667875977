import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { MatMenuModule } from '@angular/material/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule, CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from "@angular/material/select";
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import {
    SocialLoginModule,
    SocialAuthServiceConfig,
} from "@abacritt/angularx-social-login";
import { GoogleModifiedLoginProvider } from "./services/google.service";
import { environment } from "src/app/environments/environment";
import { AppleLoginProvider } from "./services/apple.service";
import { HammerModule } from "../../node_modules/@angular/platform-browser";
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import Hammer from 'hammerjs';

export class CustomHammerConfig extends HammerGestureConfig {
    override buildHammer(element: HTMLElement) {
        let mc = new Hammer(element, {
            touchAction: "pan-y", // Allow vertical scrolling
        });
        return mc;
    }
}


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProjectsComponent } from './projects/projects.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { ProjectCardComponent } from './projects/project-card/project-card.component';
import { NewProjectComponent } from './new-project/new-project.component';
import { ServicesModalComponent } from './new-project/services-modal/services-modal.component';
import { LoginComponent } from './login/login.component';
import { VsInfoComponent } from './new-project/services-modal/service-info/vs-info/vs-info.component';
import { IeInfoComponent } from './new-project/services-modal/service-info/ie-info/ie-info.component';
import { IrInfoComponent } from './new-project/services-modal/service-info/ir-info/ir-info.component';
import { WcInfoComponent } from './new-project/services-modal/service-info/wc-info/wc-info.component';
import { FcInfoComponent } from './new-project/services-modal/service-info/fc-info/fc-info.component';
import { TwilightInfoComponent } from './new-project/services-modal/service-info/twilight-info/twilight-info.component';
import { VrInfoComponent } from './new-project/services-modal/service-info/vr-info/vr-info.component';
import { FpInfoComponent } from './new-project/services-modal/service-info/fp-info/fp-info.component';
import { ChipsComponent } from './new-project/services-modal/service-info/vs-info/chips/chips.component';
import { UploadRefComponent } from './components/upload-ref/upload-ref.component';
import { AnnotationsComponent } from './components/annotations/annotations.component';
import { ItemRemovalModule } from './components/item-removal/item-removal.module';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ImageSliderComponent } from './components/image-slider/image-slider.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DoneComponent } from './delivery/done/done.component';
import { BeforeAfterComponent } from './components/before-after/before-after.component';
import { WatermarkComponent } from './components/watermark/watermark.component';
import { WalletComponent } from './components/wallet/wallet.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { InReviewComponent } from './delivery/in-review/in-review.component';
import { ReferralProgramComponent } from './referral-program/referral-program.component';
import { ValidateComponent } from './validate/validate.component';
import { ClaimComponent } from './claim/claim.component';
import { SettingsComponent } from './settings/settings.component';
import { ProjectListCardComponent } from './projects/project-list-card/project-list-card.component';
import { CanceledComponent } from './delivery/canceled/canceled.component';
import { RedfinCouponComponent } from './new-project/redfin-coupon/redfin-coupon.component';
import { PhoneSummaryComponent } from './new-project/phone-summary/phone-summary.component';
import { ServiceInfoComponent } from './new-project/phone-summary/service-info/service-info.component';
import { EmailTemplateComponent } from './referral-program/email-template/email-template.component';
import { SuccessComponent } from './success/success.component';
import { PixelService } from './services/pixel.service';
import { BingService } from './services/bing.service';
import { ErrorhandlerService } from './services/errorhandler.service';
import { ProcessAnonymousComponent } from './process-anonymous/process-anonymous.component';
import { ContinuationPopupComponent } from './projects/continuation-popup/continuation-popup.component';
import { PublicComponent } from './public/public.component';
import { FooterComponent } from './components/footer/footer.component';
import { CreateAnonymousComponent } from './components/create-anonymous/create-anonymous.component';
import { SortablejsModule } from 'nxt-sortablejs'
import { IntlTelInputDirective } from './directives/intl-tel-input.directive';
import { LatestComponent } from "./components/latest/latest.component";
import { Fp2InfoComponent } from './new-project/services-modal/service-info/fp2-info/fp2-info.component';

@NgModule({
    declarations: [
        AppComponent,
        ProjectsComponent,
        ToolbarComponent,
        MainMenuComponent,
        ProjectCardComponent,
        NewProjectComponent,
        ServicesModalComponent,
        LoginComponent,
        VsInfoComponent,
        IeInfoComponent,
        IrInfoComponent,
        WcInfoComponent,
        FcInfoComponent,
        TwilightInfoComponent,
        VrInfoComponent,
        UploadRefComponent,
        AnnotationsComponent,
        ConfirmDialogComponent,
        ImageSliderComponent,
        DoneComponent,
        BeforeAfterComponent,
        WatermarkComponent,
        WalletComponent,
        DeliveryComponent,
        InReviewComponent,
        ReferralProgramComponent,
        SettingsComponent,
        ProjectListCardComponent,
        CanceledComponent,
        RedfinCouponComponent,
        PhoneSummaryComponent,
        ServiceInfoComponent,
        EmailTemplateComponent,
        SuccessComponent,
        ValidateComponent,
        ClaimComponent,
        FpInfoComponent,
        ProcessAnonymousComponent,
        ContinuationPopupComponent,
        Fp2InfoComponent
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MatIconModule,
        MatMenuModule,
        BrowserAnimationsModule,
        FormsModule,
        MatDialogModule,
        ChipsComponent,
        MatTooltipModule,
        DragDropModule,
        ItemRemovalModule,
        ScrollingModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatBottomSheetModule,
        SocialLoginModule,
        HammerModule,
        PublicComponent,
        FooterComponent,
        CreateAnonymousComponent,
        CdkDrag,
        CdkDropList,
        SortablejsModule.forRoot({ animation: 150 }),
        IntlTelInputDirective,
        LatestComponent
    ], providers: [
        {
            provide: "SocialAuthServiceConfig",
            useValue: {
                autoLogin: true,
                providers: [
                    {
                        id: GoogleModifiedLoginProvider.PROVIDER_ID,
                        provider: new GoogleModifiedLoginProvider(environment.googleClient),
                    },
                    {
                        id: AppleLoginProvider.PROVIDER_ID,
                        provider: new AppleLoginProvider(environment.appleClient),
                    },
                ],
            } as SocialAuthServiceConfig,
        },
        PixelService,
        BingService,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: CustomHammerConfig
        },
        {
            provide: ErrorHandler,
            useClass: ErrorhandlerService
        },
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
export class AppModule { }
