import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ViewChild,
    Renderer2,
} from "@angular/core";

import _ from "lodash";

@Component({
    selector: "item-removal",
    templateUrl: "./item-removal.component.html",
    styleUrls: ["./item-removal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemRemovalComponent {
    public price: number = 0;
    @Input() serviceRequest: any = [];
    @Input() disable = false;
    @Input() imageHeight: string = "";
    @Output() update: EventEmitter<any> = new EventEmitter();

    @Input() itemData: any;
    public imageSize: any;
    @ViewChild("imageElement") imageElement: any;

    constructor(
        private ref: ChangeDetectorRef,
        private renderer: Renderer2
    ) { }

    ngAfterViewInit() {
        const img = this.imageElement.nativeElement;
        if (img.complete) {
            this.processImage(img);
        } else {
            img.onload = () => {
                this.processImage(img);
            };
        }
    }

    processImage(img: HTMLImageElement) {
        const aspectRatio = img.naturalWidth / img.naturalHeight;
        if (aspectRatio < 1) {
            this.renderer.setStyle(this.imageElement.nativeElement, 'max-height', this.imageHeight);
        }
    }

    checkField(row: number, column: number) {
        const copyOfData = [...this.itemData];
        if (copyOfData) {
            return copyOfData.filter((item) =>
                item.row === row.toString() && item.col === column.toString()
            ).length > 0;
        } else {
            return false;
        }
    }

    toggleField(row: number, column: number) {
        if (this.disable) {
            return;
        }

        if (this.checkField(row, column)) {
            this.itemData = this.itemData.filter((item: any) => !(
                item.row == row.toString() && item.col == column.toString()
            ));
        } else {
            this.itemData.push({ row: row.toString(), col: column.toString() });
        }

        this.update.emit(this.itemData);
        this.render();
    }

    render() {
        this.ref.detectChanges();
    }

}
