import { ResolveFn } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { inject } from '@angular/core';

export const landingPageResolver: ResolveFn<boolean> = (route, state) => {
    // get :token from route
    const token = route.params['token'];

    const apiService = inject(ApiService);
    const data = apiService.get(`project/${token}/get-public-floorplan`);

    return data;
};
