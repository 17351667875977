import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    trigger,
    transition,
    style,
    animate,
    AnimationEvent,
    state
} from '@angular/animations';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { BreakpointService } from 'src/app/services/breakpoint.service';

@Component({
    selector: 'app-image-slider',
    templateUrl: './image-slider.component.html',
    styleUrls: ['./image-slider.component.scss'],
    animations: [
        trigger('slide', [
            state('noAnimation', style({ transform: 'translateX(0)' })),
            transition('void => enter, leave => enter, noAnimation => enter', [
                style({ transform: '{{direction}}' }),
                animate('300ms ease-in-out', style({ transform: 'translateX(0)' }))
            ]),
            transition('enter => leave, noAnimation => leave', [
                animate('300ms ease-in-out', style({ transform: '{{direction}}' }))
            ])
        ])
    ]
})

export class ImageSliderComponent {
    public nextImage: any;
    public toggle = true;
    public imageState: any[] = [];
    public initialized: boolean = false;
    private service: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ImageSliderComponent>,
        public breakpointService: BreakpointService,
        private api: ApiService
    ) {
        this.imageState = [
            { image: this.data.images[0], state: 'noAnimation', direction: 'left' }
        ];
        this.service = this.data.service;
    }

    slide(direction: number) {
        let index = this.data.images.indexOf(this.imageState[0]?.image);
        if (index !== -1) {
            if (direction === 1) {
                index = (index + 1) % this.data.images.length;
            } else {
                index = (index - 1 + this.data.images.length) % this.data.images.length;
            }
            const enterDirection = direction === 1 ? 'left' : 'right';
            const leaveDirection = direction === 1 ? 'right' : 'left';

            this.imageState.unshift({ image: this.data.images[index], state: 'enter', direction: enterDirection });
            if (this.imageState.length > 1) {
                this.imageState[1].state = 'leave';
                this.imageState[1].direction = leaveDirection;
            }
        }
    }

    animationDone(event: AnimationEvent) {
        if (event.toState === 'leave') {
            this.imageState.pop();
        }
    }

    close() {
        this.dialogRef.close();
    }

    async addAsRef() {
        const image = this.imageState[0].image;
        const formData = new FormData();
        formData.append("url", image.url);
        formData.append("original_name", image.caption);
        const response = await this.api.post(
            "assets/check_for_url",
            formData
        );
        this.data.addStyleRef(response);
    }

    checkIfAddedAsRef() {
        if (this.service.reference_assets) {
            return this.service.reference_assets.some((image: any) => image.original_name === this.imageState[0].image.caption);
        } else {
            return false;
        }
    }
}
