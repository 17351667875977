import { Component } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/app/environments/environment';
import { BreakpointService } from '../services/breakpoint.service';
import { MatDialog } from '@angular/material/dialog';
import { EmailTemplateComponent } from './email-template/email-template.component';

@Component({
    selector: 'app-referral-program',
    templateUrl: './referral-program.component.html',
    styleUrls: ['./referral-program.component.scss']
})
export class ReferralProgramComponent {
    public currentStep: number = 1;
    public inviteGuid: string = '';
    public linkToShare: string = '';
    public user: any = JSON.parse(localStorage.getItem('user') || 'null');
    public copied: boolean = false;
    public emailInvites: boolean = false;
    public recipientEmail: string = '';
    public recipients: any = [];
    public emailInvalid: boolean = false;
    public mailTxt: string = '';
    public emailsSent: boolean = false;

    constructor(
        private api: ApiService,
        public breakpointService: BreakpointService,
        private dialog: MatDialog,
    ) { }

    async ngOnInit() {
        this.inviteGuid = await this.api.get(`user/${this.user.id}/invite`);
        this.linkToShare = `${environment.app_url}register/${this.inviteGuid}/invite`;
        this.mailTxt = `${this.user.first_name} ${this.user.last_name} used VirtualStaging.com to get more leads and offers from their listings with Virtual Staging and thinks you should try it out, too!`;
    }

    getImgUrl(): string {
        switch (this.currentStep) {
            case 1:
                return 'assets/referral-program/step-1.svg';
            case 2:
                return 'assets/referral-program/step-2.svg';
            case 3:
                return 'assets/referral-program/step-3.svg';
            case 4:
                return 'assets/referral-program/step-4.svg';
            default:
                return '';
        }
    }

    getDescription(): string {
        switch (this.currentStep) {
            case 1:
                return ' When you recommend VirtualStaging.com to friends and colleagues...'
            case 2:
                return '...they get free virtual staging for one image...'
            case 3:
                return '...and if they spend over $100 on Virtual Staging...'
            case 4:
                return '...you get $100 in credits to use for any of VirtualStaging.com\'s services'
            default:
                return '';
        }
    }

    copyLinkToShare() {
        navigator.clipboard.writeText(this.linkToShare);
        this.copied = true;
        setTimeout(() => {
            this.copied = false;
        }, 2000);
    }

    shareFb() {
        let link = ['https://www.facebook.com/sharer/sharer.php?u=', this.linkToShare].join('');
        window.open(link, "_blank");
    }

    shareX() {
        let link = ['https://x.com/intent/tweet?url=', this.linkToShare].join('');
        window.open(link, "_blank");
    }

    addRecipient() {
        // validate email address format (regex)
        let emailRegex = /\S+@\S+\.\S+/;
        if (emailRegex.test(this.recipientEmail)) {
            this.recipients.push(this.recipientEmail);
            this.recipientEmail = '';
        } else {
            this.emailInvalid = true;
            setTimeout(() => {
                this.emailInvalid = false;
            }, 2000);
        }
    }

    async sendInvites() {
        const resp = await this.api.post(`user/${this.user.id}/share`, {
            mailTxt: this.mailTxt,
            emails: this.recipients,
            link: this.linkToShare,
        });
        this.recipients = [];
        this.emailsSent = true;
        setTimeout(() => {
            this.emailsSent = false;
        }, 2000);
    }

    removeRecipient(str: string) {
        this.recipients = this.recipients.filter((email: string) => email !== str);
    }

    previewEmailTemplate() {
        let dialogRef = this.dialog.open(EmailTemplateComponent, {
            data: {
                mailTxt: this.mailTxt,
                user: this.user,
            },
            maxWidth: '50rem',
            maxHeight: '80vh',
            panelClass: 'email-template-dialog',
        });
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                this.mailTxt = result;
            }
        });
    }
}
