import { Component, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/app/environments/environment';
import { loadStripe } from "@stripe/stripe-js";
import { AuthService } from 'src/app/services/auth.service';
import { BreakpointService } from 'src/app/services/breakpoint.service';


@Component({
    selector: 'app-wallet',
    templateUrl: './wallet.component.html',
    styleUrls: ['./wallet.component.scss']
})
export class WalletComponent {
    public wallet: any;
    public loading: boolean = true;
    public amount: number = 0;
    public transactions: any;
    @ViewChild('walletDialog') walletDialog: any;
    public selectedPredefinedAmount: number | null = 0;
    private stripeAPI: any;
    public loadingStripe: boolean = false;
    public user: any = JSON.parse(localStorage.getItem("user") || 'null')
    constructor(
        private api: ApiService,
        private dialog: MatDialog,
        private auth: AuthService,
        public breakpointService: BreakpointService,
    ) { }

    async ngOnInit() {
        this.auth.getAuthStatus().subscribe(isAuthenticated => {
            if (isAuthenticated) {
                Promise.all([
                    this.api.get("wallet/balance"),
                    this.api.get("wallet/transactions")
                ]).then(([balance, transactions]) => {
                    this.wallet = balance;
                    this.transactions = transactions;
                    this.loading = false;
                });
            }
        });
    }

    async ngAfterViewInit() {
        this.stripeAPI = await loadStripe(environment.stripeKey);
    }

    openWallet() {
        this.dialog.open(this.walletDialog, {
            maxWidth: "100vw",
            maxHeight: "100vh",
        })
    }

    getShortDate(date: string): string {
        const newDate = new Date(date);
        const formattedDate = newDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' });
        return formattedDate;
    }

    prettyFormat(description: string) {
        const parts = description.split("for project");
        if (parts[0].indexOf("credits") !== -1) {
            const amount = parseFloat(
                parts[0].replace("Paid", "").replace("credits", "").trim()
            ).toFixed(2);
            return `Paid ${amount} credits for project ${parts[1]}`;
        }
        return description;
    }

    getAmount(transaction: any): string {
        if (transaction.type === "Wallet") {
            if (transaction.amount > 1) {
                return `${transaction.amount} credits`;
            } else if (transaction.amount === 1) {
                return `${transaction.amount} credit`;
            } else {
                return `${transaction.credits} credits`;
            }
        } else {
            return `$${transaction.amount.toFixed(2)}`;
        }
    }

    negative(transaction: any): string {
        if (transaction.type === "Wallet") {
            if (transaction.transaction_type === "Payout" || transaction.transaction_type === "Payment") {
                return "-";
            } else {
                return '+';
            }
        } else {
            return "";
        }
    }

    async addFundsToWallet() {
        localStorage.setItem("redirect_to", window.location.href);
        this.loadingStripe = true;
        const sessionId = await this.api.postDirect("wallet/paymentSession", {
            amount: this.amount,
            success: `${environment.app_url}success`,
            cancel: `${environment.app_url}cancel`,
        });
        this.stripeAPI.redirectToCheckout({
            sessionId: sessionId,
        });
        this.loadingStripe = false;
    }

    closeWallet() {
        this.dialog.closeAll();
    }
}
