import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/app/environments/environment';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder } from '@angular/forms';
import { BaSliderComponent } from '../ba-slider/ba-slider.component';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';


@Component({
    selector: 'app-sharing-is-caring',
    standalone: true,
    imports: [
        MatTabsModule,
        MatIconModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        MatButtonModule,
        BaSliderComponent,
        MatTooltipModule,
        MatAutocompleteModule
    ],
    templateUrl: './sharing-is-caring.component.html',
    styleUrls: ['./sharing-is-caring.component.scss']
})
export class SharingIsCaringComponent {
    public inviteGroup = this.fb.nonNullable.group({
        email: ['', [Validators.required, Validators.email]],
        mode: ['1', Validators.required],
    })

    get email() {
        return this.inviteGroup.get('email');
    }
    get mode() {
        return this.inviteGroup.get('mode');
    }

    public propertyInfoGroup = this.fb.nonNullable.group({
        propertyName: [''],
        propertyNameHide: [true],
        status: [''],
        statusHide: [true],
        bedrooms: ['0'],
        bedroomsHide: [true],
        bathrooms: ['0'],
        bathroomsHide: [true],
        sqft: ['0'],
        sqftHide: [true],
        sqftLot: ['0'],
        sqftLotHide: [true],
        propertyType: [''],
        propertyTypeHide: [true],
        agentEmail: [''],
        agentEmailHide: [true],
    })

    public propertyTypes = [
        'Single-Family',
        'Multi-Family',
        'Condominium',
        'Townhouse',
        'Cooperative',
        'Manufactured/Mobile',
        'Commercial',
        'Industrial',
        'Vacant Land',
        'Mixed-Use',
        'Special Purpose'
    ];

    public errorMessage = '';
    public selectedTab = 0;
    public iframeSnippet = '';
    public selectedEmbedSize: any = {};
    public embedSizes = [
        { name: 'Small', width: 380, height: 279 },
        { name: 'Medium', width: 570, height: 419 },
        { name: 'Large', width: 760, height: 558 }
    ];
    public copied = false;
    public inviteSent = false;
    public iframeUrl = '';
    public project: any;
    public publicToken: string = '';
    public sharedWith: any = [];
    public floorPlanToken: string = '';

    constructor(
        public dialogRef: MatDialogRef<SharingIsCaringComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private api: ApiService,
        private fb: FormBuilder,
        public breakpointService: BreakpointService,
        public dialog: MatDialog
    ) { }

    ngOnInit() {
        let { project, public_token, users } = this.data.data;
        this.project = project;
        this.floorPlanToken = project.floor_plan_token;
        if (this.project.property_info) {
            this.setPropertyInfo(this.project.property_info);
        }
        this.publicToken = public_token;
        this.sharedWith = users;
        this.iframeUrl = `${environment.app_url + "p/embed/" + this.publicToken}`;
        this.selectedEmbedSize = this.embedSizes[1];
    }
    updateErrorMessage() {
        if (this.email?.hasError('required')) {
            this.errorMessage = 'You must enter a value';
        } else if (this.email?.hasError('email')) {
            this.errorMessage = 'Not a valid email';
        } else {
            this.errorMessage = '';
        }
    }

    updateSnippet() {
        this.iframeSnippet = `<iframe width="${this.selectedEmbedSize.width}" height="${this.selectedEmbedSize.height}" src="${this.iframeUrl}" title="Embedded Project" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    }

    copy(value: string): void {
        navigator.clipboard.writeText(value).then(() => {
            this.copied = true;
            setTimeout(() => {
                this.copied = false;
            }, 2000);
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    }

    share() {
        if (this.sharedWith.find((u: any) => u.email === this.inviteGroup.value.email)) {
            this.inviteGroup.reset();
            return;
        }
        this.api.post(`project/${this.project.guid}/share`, {
            email: this.inviteGroup.value.email,
            mode: this.inviteGroup.value.mode === '2' ? 'edit' : 'readonly'
        }).then(() => {
            this.inviteSent = true;
            this.sharedWith.push({
                email: this.inviteGroup.value.email,
                mode: this.inviteGroup.value.mode === '2' ? 'edit' : 'readonly'
            });
            setTimeout(() => {
                this.inviteSent = false;
            }, 2000);
            this.inviteGroup.reset();
        })
    }

    togglePublic() {
        const enable = !this.project.is_public;
        this.api.post(`project/${this.project.guid}/public`, {
            public: enable ? 1 : 0
        }).then((response) => {
            this.project.is_public = enable;
            this.publicToken = response;
            this.iframeUrl = `${environment.app_url + "p/embed/" + this.publicToken}`;
            this.updateSnippet();
        })
    }

    updateMode(user: any, event: any) {
        this.api.patch(`project/${this.project.guid}/patch/share`, {
            email: user.email,
            mode: event === '2' ? 'edit' : 'readonly'
        }).then(() => {
            user.mode = event === '2' ? 'edit' : 'readonly';
        })
    }

    getPublicLink(): string {
        return `${environment.app_url}p/${this.publicToken}`;
    }

    isPhone(): boolean {
        return this.breakpointService.currentBreakpointClass === 'is-phone-portrait';
    }

    removeAccess(user: any) {
        const dialogData = {
            message: `Are you sure you want to remove access for ${user.email}?`,
            confirm: { title: `Remove` },
            reject: { title: `Cancel` }
        }
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.api.post(`project/${this.project.guid}/owner/reject`, {
                    email: user.email
                }).then(() => {
                    this.sharedWith = this.sharedWith.filter((u: any) => u.email !== user.email);
                })
            }
        });
    }

    toggleAffiliate() {
        this.api.post(`project/${this.project.guid}/public/affiliate`, {
            affiliate: this.project.affiliatable ? 0 : 1
        }).then(() => {
            this.project.affiliatable = !this.project.affiliatable;
        })
    }

    updatePropertyInfo() {
        let data: any = {};
        data = this.propertyInfoGroup.value;
        data = JSON.stringify(data);
        let formData = new FormData();
        formData.append('property_info', data);
        this.api.post(`project/${this.project.guid}/property-info`, formData).then(() => {
            this.propertyInfoGroup.markAsPristine();
        });
    }

    getLandingPageLink(): string {
        return `${environment.app_url}p/lp/${this.floorPlanToken}`;
    }

    resetPropertyInfo() {
        this.propertyInfoGroup.reset();
        if (this.project.property_info) {
            this.setPropertyInfo(this.project.property_info);
        } else {
            this.propertyInfoGroup.get('status')?.setValue('For Sale');
            this.propertyInfoGroup.get('propertyType')?.setValue('Single-Family');
            this.propertyInfoGroup.get('agentEmail')?.setValue(this.project.user.email);
            this.propertyInfoGroup.get('propertyName')?.setValue(this.project.name);
        }
    }

    setPropertyInfo(data: any) {
        for (let key in data) {
            const control = this.propertyInfoGroup.get(key);
            if (control) {
                if (key.endsWith('Hide')) {
                    const parentKey = key.slice(0, -4);
                    control.setValue(data[key] !== undefined ? data[key] : !!this.propertyInfoGroup.get(parentKey)?.value);
                } else {
                    control.setValue(data[key]);
                }
            }
        }
    }
}
