<div
    class="wrapper"
    [class]="breakpointService.currentBreakpointClass">
    <div
        class="tabs"
        *ngIf="!isPp">
        <button
            class="required-info"
            (click)="activeTab = 1"
            [ngClass]="activeTab === 1 ? 'active' : ''">
            Required Info
        </button>
        <button
            class="optional-info"
            (click)="activeTab = 2"
            [ngClass]="activeTab === 2 ? 'active' : ''">
            Optional Info
        </button>
    </div>
    <div
        class="golden-style"
        *ngIf="activeTab === 1 || isPp">
        <h4
            class="golden-style-label"
            *ngIf="!isPp"
            [ngClass]="{
                invalid:
                    this.service.valid === false && !service.data.goldenHour
            }">
            Select Golden Hour Style:
        </h4>
        <button
            class="golden-style-label-pp"
            *ngIf="isPp"
            (click)="expanded.goldenHour = !expanded.goldenHour"
            [ngClass]="{
                invalid:
                    this.service.valid === false && !service.data.goldenHour
            }">
            <h3>Select Golden Hour Style <span>(required)</span></h3>
            <mat-icon
                *ngIf="!expanded.goldenHour"
                svgIcon="plus3" />
            <mat-icon
                *ngIf="expanded.goldenHour"
                svgIcon="minus3" />
        </button>
        <div
            *ngIf="expanded.goldenHour || !isPp"
            style="display: content">
            <ng-container
                *ngTemplateOutlet="goldenHourStyleCards"></ng-container>
        </div>
    </div>
    <div
        class="comments"
        *ngIf="activeTab === 2 || isPp">
        <h4 *ngIf="!isPp"><label for="comment-box">Your comments:</label></h4>
        <button
            class="comments-label-pp"
            *ngIf="isPp"
            (click)="expanded.comments = !expanded.comments">
            <h3>Your comments (optional)</h3>
            <mat-icon
                *ngIf="!expanded.comments"
                svgIcon="plus3" />
            <mat-icon
                *ngIf="expanded.comments"
                svgIcon="minus3" />
        </button>
        <textarea
            id="comment-box"
            *ngIf="expanded.comments || !isPp"
            [(ngModel)]="service.data.description"
            (ngModelChange)="checkIfNeedsToSave()"
            placeholder="Click here to leave a note for our designers if you have any specific instructions. This field is optional, and if you don't leave any comments, our designers will use their best judgment when creating your design."
            name="comments"
            rows="9">
        </textarea>
    </div>
    <div
        class="upload-ref"
        *ngIf="activeTab === 2 || isPp">
        <app-upload-ref
            (refAdded)="addReferenceImage($event)"
            (refRemoved)="removeReferenceImage($event)"
            [projectGuid]="projectGuid"
            [serviceRequest]="serviceRequest"
            [service]="service"></app-upload-ref>
    </div>
</div>

<ng-template #goldenHourStyleCards>
    <div class="style-cards">
        <div
            *ngFor="let style of goldenHourStyles"
            class="style-card"
            [class.selected]="service.data.goldenHour === style.id">
            <div class="header">
                <h4 class="name">{{ style.name }}</h4>
                <mat-icon
                    *ngIf="style.id === service.data.goldenHour"
                    svgIcon="greenCheckmark" />
            </div>
            <div
                class="image"
                (click)="setStyle(style.id)">
                <img
                    [src]="getStyleThumbnail(style)"
                    alt="" />
            </div>
        </div>
    </div>
</ng-template>
