import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BreakpointService } from 'src/app/services/breakpoint.service';


@Component({
    selector: 'app-upload-ref',
    templateUrl: './upload-ref.component.html',
    styleUrls: ['./upload-ref.component.scss']
})
export class UploadRefComponent {
    public refsErrorMessage: string = '';
    public totalProgress: number = 0;
    public uploadsProgress: number[] = [];
    private allowedTypes: string[] = [
        '.png',
        '.jpg',
        '.jpeg',
        '.gif',
        '.psd',
        '.webp',
        '.jfif',
        '.tiff',
        '.pdf',
        '.heic',
    ];
    public uploads: any[] = [];
    public references: any[] = [];
    @Input() projectGuid: string = '';
    @Input() service: any;
    @Input() serviceRequest: any;
    @Output() refAdded = new EventEmitter<any>();
    @Output() refRemoved = new EventEmitter<any>();

    constructor(
        private api: ApiService,
        public breakpointService: BreakpointService,
    ) { }

    uploadReferenceAssets(event: Event) {
        const references = this.detectFiles(event);

        const maxFiles = 5;
        if (
            references.length > maxFiles ||
            this.references.length + references.length > maxFiles
        ) {
            this.refsErrorMessage = `You can upload a maximum of ${maxFiles} reference images.`;
            return;
        }
        this.refsErrorMessage = '';
        this.totalProgress = 0;
        this.uploadsProgress = Array(references.length).fill(0);

        for (const file of references) {
            const extension = `.${file.name.split('.').pop()}`.toLowerCase();
            if (this.allowedTypes.indexOf(extension) === -1) {
                this.refsErrorMessage = `File format not supported. Contact Customer Support for assistance.\nValid formats are: ${this.allowedTypes.join(
                    ', '
                )}.`;
                return;
            }
        }

        references.forEach((image, index) => {
            let upload: any = {};
            upload.percentDone = 0;
            this.uploads.push(upload);
            this.uploadImage(image, upload, index).then(
                (image: any) => {
                    if (image) {
                        this.references = this.references.concat(image);
                        this.refAdded.emit(image);
                    }
                    this.uploads.splice(upload, 1);
                }
            );
        });
    }

    uploadImage(file: any, upload: any, index: number) {
        const formData = new FormData();

        const sanitizedFilename = this.sanitizeFilename(file.name);

        formData.append('imageFile', file, sanitizedFilename);

        if (file.valid === 'denied') {
            return Promise.reject('');
        }

        return new Promise((resolve, reject) => {
            this.api
                .upload(
                    `project/serviceRequestRework/reference/`,
                    formData
                )
                .subscribe(
                    (event: any) => {
                        if (event.type === HttpEventType.UploadProgress) {
                            upload.percentDone = Math.round(
                                (100 * event.loaded) / event.total
                            );
                            this.uploadsProgress[index] = upload.percentDone;
                            this.updateTotalProgress();
                        } else if (event instanceof HttpResponse) {
                            resolve(event.body[0]);
                            this.totalProgress = 0;
                        }
                    },
                    (err: any) => {
                        reject(err);
                    }
                );
        });
    }

    sanitizeFilename(filename: string) {
        let extension = filename.split('.').pop();
        extension = extension ? '.' + extension : '';

        let nameWithoutExtension = filename.replace(extension, '');
        const sanitized = nameWithoutExtension
            .replace(/[^a-zA-Z0-9-_.\s]/g, '')
            .replace(/\s+/g, '_')
            .substring(0, 150 - extension.length) + extension;

        return sanitized.toLowerCase();
    }


    updateTotalProgress() {
        this.totalProgress =
            this.uploadsProgress.reduce((sum, progress) => sum + progress, 0) /
            this.uploadsProgress.length;
    }

    removeRef(id: number) {
        this.references = this.references.filter((ref: any) => ref.id !== id);
        this.refRemoved.emit(id);
        if (this.service.status = "Created") {
            this.deleteUpload(this.serviceRequest, this.service, id);
        }
    }

    detectFiles(event: any) {
        const files = event.target.files || event.dataTransfer.files;
        const parsedFiles = [];

        for (const file of files) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            parsedFiles.push(file);
        }
        event.target.value = '';

        return parsedFiles;
    }

    getRefThumbnail(url: string) {
        let updatedUrl = environment.cdn + url;
        return environment.server + "/asset/thumbnail/low/" + updatedUrl.split("/").slice(-1)[0];
    }

    deleteUpload(request: any, task: any, imageId: any) {
        this.api
            .delete(
                `project/${this.projectGuid}/serviceRequest/${request.id}/task/${task.id}/reference/${imageId}`
            )
    }

}
