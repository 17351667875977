import { Component, Input, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/app/environments/environment';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'ba-slider',
    templateUrl: './ba-slider.component.html',
    imports: [
        MatTabsModule,
        MatIconModule,
        CommonModule,
        FormsModule
    ],
    standalone: true,
    styleUrls: ['./ba-slider.component.scss'],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BaSliderComponent {
    public selectedSlide = 0;
    public loading = true;
    @Input() publicToken: any;
    @Input() project: any;
    public isDragging = false;
    public slideCounter = 0;
    private mouseupListener: (() => void) | null = null;


    constructor(
        private route: ActivatedRoute,
        private api: ApiService,
        private renderer: Renderer2,
        private title: Title,
        private meta: Meta
    ) { }

    async ngOnInit() {
        if (!this.project) {
            let token = this.route.snapshot.paramMap.get('token') || this.publicToken;
            const response = await this.api.get(`project/${token}/get-public`);
            this.project = response;
        }
        this.title.setTitle(`${this.project.project_name} Virtual Staging`);
        const projectDescription = `${this.project.project_name} offers a detailed virtual staging showcase, transforming empty spaces into visually appealing interiors. See the before and after images to explore how VirtualStaging.com enhances real estate listings.`;

        const tags = [
            { name: 'description', content: projectDescription },
            { property: 'og:title', content: `${this.project.project_name} Virtual Staging` },
            { property: 'og:description', content: projectDescription },
        ];

        tags.forEach((tag: any) => {
            this.meta.updateTag(tag);
        });
        this.loading = false;
    }

    getBefore(serviceRequest: any) {
        let url = environment.cdn + serviceRequest.asset.url;
        return environment.server + "/asset/thumbnail/" + url.split("/").slice(-1)[0];
    }

    getAfter(serviceRequest: any) {
        let url = environment.cdn + (serviceRequest.final_asset?.url || serviceRequest.asset.url);
        return environment.server + "/asset/thumbnail/" + url.split("/").slice(-1)[0];
    }

    moveSlider(increment: number) {
        this.selectedSlide = (this.selectedSlide + increment + this.project.images.length) % this.project.images.length;
    }

    onSlide() {
        this.slideCounter++;
        if (this.slideCounter > 10 && !this.isDragging) {
            this.isDragging = true;
            if (!this.mouseupListener) {
                this.mouseupListener = this.renderer.listen('document', 'mouseup', () => this.onSlideEnd());
            }
        }
    }

    onSlideEnd() {
        this.isDragging = false;
        this.slideCounter = 0;
        if (this.mouseupListener) {
            this.mouseupListener();
            this.mouseupListener = null;
        }
    }

    ngOnDestroy() {
        if (this.mouseupListener) {
            this.mouseupListener();
        }
    }

}
