<div
    class="wrapper"
    [ngClass]="breakpointService.currentBreakpointClass">
    <button
        class="transparent"
        [matMenuTriggerFor]="newProjectMenu">
        Pricing & All Services
    </button>
    <hr />
    <button
        class="primary"
        (click)="goToProjectPage()">
        Start new project<mat-icon svgIcon="newProject" />
    </button>
</div>

<mat-menu
    #newProjectMenu="matMenu"
    yPosition="below"
    xPosition="before"
    [class]="breakpointService.currentBreakpointClass"
    class="new-project-menu">
    <div
        class="menu-wrapper"
        (click)="$event.stopPropagation()">
        <div class="left">
            <div class="header">
                <h5>STAGING & EDITING</h5>
            </div>
            <p>
                Start a new staging and editing project to order any of the
                services listed below.
            </p>
            <div class="service-cards">
                <div class="service-card">
                    <div class="card-header">
                        <p>Virtual Staging</p>
                        <p>$24.00/<span>image</span></p>
                    </div>
                    <div class="card-footer">
                        <p>ETA: 4-24h</p>
                        <mat-icon
                            matTooltip="Orders placed between 5am-3pm PST, Mon-Fri, with up to 10 images, are delivered within 4 to 8 hours. Orders with more than 10 images, or orders placed over the weekend, can take up to 24 hours."
                            svgIcon="info2" />
                        <a
                            href="https://www.virtualstaging.com/services/virtual-staging"
                            target="_blank"
                            rel="noopener noreferrer"
                            >Learn more</a
                        >
                    </div>
                </div>
                <div class="service-card">
                    <div class="card-header">
                        <p>360° Virtual Staging</p>
                        <p>$32.00/<span>image</span></p>
                    </div>
                    <div class="card-footer">
                        <p>ETA: 8-48h</p>
                        <mat-icon
                            matTooltip="Delivery typically takes 8 to 48 hours, depending on the project's complexity and number of images. For very complex photos, it may take longer. We will reach out via email if extended time is needed."
                            svgIcon="info2" />
                        <a
                            href="https://www.virtualstaging.com/services/360-virtual-staging"
                            target="_blank"
                            rel="noopener noreferrer"
                            >Learn more</a
                        >
                    </div>
                </div>
                <div class="service-card">
                    <div class="card-header">
                        <p>Image Enhancement</p>
                        <p>$1.20/<span>image</span></p>
                    </div>
                    <div class="card-footer">
                        <p>ETA: 8-24h</p>
                        <mat-icon
                            matTooltip="Expect delivery in 8 to 24 hours, with a 24-hour turnaround for orders placed over the weekend. We often deliver much faster."
                            svgIcon="info2" />
                        <a
                            href="https://www.virtualstaging.com/services/image-enhancement"
                            target="_blank"
                            rel="noopener noreferrer"
                            >Learn more</a
                        >
                    </div>
                </div>
                <div class="service-card">
                    <div class="card-header">
                        <p>Furniture Removal</p>
                        <p><span>from </span>$0.50</p>
                    </div>
                    <div class="card-footer">
                        <p>ETA: 8-24h</p>
                        <mat-icon
                            matTooltip="Expect delivery in 8 to 24 hours. For very complex photos, it may take up to 48 hours. We will reach out via email if extended time is needed."
                            svgIcon="info2" />
                        <a
                            href="https://www.virtualstaging.com/services/furniture-removal"
                            target="_blank"
                            rel="noopener noreferrer"
                            >Learn more</a
                        >
                    </div>
                </div>
                <div class="service-card">
                    <div class="card-header">
                        <p>Wall Change</p>
                        <p>$4.80/<span>image</span></p>
                    </div>
                    <div class="card-footer">
                        <p>ETA: 8-24h</p>
                        <mat-icon
                            matTooltip="Expect delivery in 8 to 24 hours, with a 24-hour turnaround on weekend orders."
                            svgIcon="info2" />
                        <a
                            href="https://www.virtualstaging.com/services/wall-change"
                            target="_blank"
                            rel="noopener noreferrer"
                            >Learn more</a
                        >
                    </div>
                </div>
                <div class="service-card">
                    <div class="card-header">
                        <p>Floor Change</p>
                        <p>$4.80/<span>image</span></p>
                    </div>
                    <div class="card-footer">
                        <p>ETA: 8-24h</p>
                        <mat-icon
                            matTooltip="Expect delivery in 8 to 24 hours, with a 24-hour turnaround on weekend orders."
                            svgIcon="info2" />
                        <a
                            href="https://www.virtualstaging.com/services/floor-change"
                            target="_blank"
                            rel="noopener noreferrer"
                            >Learn more</a
                        >
                    </div>
                </div>
                <div class="service-card">
                    <div class="card-header">
                        <p>Twilight</p>
                        <p>$4.80/<span>image</span></p>
                    </div>
                    <div class="card-footer">
                        <p>ETA: 8-24h</p>
                        <mat-icon
                            matTooltip="Expect delivery in 8 to 24 hours, with a 24-hour turnaround on weekend orders."
                            svgIcon="info2" />
                        <a
                            href="https://www.virtualstaging.com/services/twilight"
                            target="_blank"
                            rel="noopener noreferrer"
                            >Learn more</a
                        >
                    </div>
                </div>
                <div class="service-card">
                    <div class="card-header">
                        <p>Virtual Renovation</p>
                        <p>$90.00/<span>image</span></p>
                    </div>
                    <div class="card-footer">
                        <p>ETA: 2-5 days</p>
                        <mat-icon
                            matTooltip="Delivery typically takes 2 to 5 days, depending on the project's complexity and number of images. An exact ETA will be provided after manual review and approval of the project. "
                            svgIcon="info2" />
                        <a
                            href="https://www.virtualstaging.com/services/virtual-renovation"
                            target="_blank"
                            rel="noopener noreferrer"
                            >Learn more</a
                        >
                    </div>
                </div>
                <div class="service-card">
                    <div class="card-header">
                        <p>3D Floor Plan</p>
                        <p>$120.00/<span>image</span></p>
                    </div>
                    <div class="card-footer">
                        <p>ETA: 24-72h</p>
                        <mat-icon
                            matTooltip="Delivery typically takes 2 to 5 days, depending on the project's complexity and number of images. An exact ETA will be provided after manual review and approval of the project. "
                            svgIcon="info2" />
                        <button
                            class="fp2-modal-button"
                            (click)="open3dFloorPlanModal()">
                            Learn more
                        </button>
                    </div>
                </div>
            </div>
            <button
                class="transparent"
                (click)="goToProjectPage()">
                Start Staging & Editing Project
            </button>
        </div>
        <div class="right">
            <div class="header">
                <h5>FLOOR PLAN RENDERS</h5>
            </div>
            <p>
                Start a new rendering project if you wish to order floor plan
                renders.
            </p>
            <div class="service-card">
                <div class="card-header">
                    <p>Floor plan renders</p>
                    <p>from $700.00/<span>image</span></p>
                </div>

                <div class="tiers">
                    <div class="tier">
                        <p>Up to 700 SQ. FT.</p>
                        <p>$700</p>
                    </div>
                    <div class="tier">
                        <p>701-1,250 SQ. FT.</p>
                        <p>$1,250</p>
                    </div>
                    <div class="tier">
                        <p>1,251-1,700 SQ. FT.</p>
                        <p>$1,750</p>
                    </div>
                    <div class="tier">
                        <p>2251-2,750 SQ. FT.</p>
                        <p>$2,500</p>
                    </div>
                    <div class="tier">
                        <p>2751-3,500 SQ. FT.</p>
                        <p>$3,000</p>
                    </div>
                    <div class="tier">
                        <p>3,000+ SQ. FT.</p>
                        <a
                            href="https://www.virtualstaging.com/contact"
                            target="_blank"
                            rel="noopener noreferrer"
                            >Request quote</a
                        >
                    </div>
                    <div class="card-footer">
                        <p>ETA: 5-12 days</p>
                        <mat-icon
                            matTooltip="We'll need anywhere from 5 to 12 days to complete your project. We'll confirm the exact ETA upon submission, or you can inquire beforehand via live chat."
                            svgIcon="info2" />
                        <a
                            href="https://www.virtualstaging.com/services/floor-plan-renders"
                            target="_blank"
                            rel="noopener noreferrer"
                            >Learn more</a
                        >
                    </div>
                </div>
                <button
                    class="transparent"
                    (click)="goToProjectPage('floorplan')">
                    Start Floor Plan Render Project
                </button>
            </div>
        </div>
    </div>
</mat-menu>
