import { Injectable } from '@angular/core';

declare const uetq: any;

@Injectable()
export class BingService {
    constructor() { }

    public trackEvent(name: string, properties: any = {}) {
        uetq.push('event', name, properties);
    }
}