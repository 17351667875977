<div class="wrapper">
    <h4>Enter your full company name before proceeding</h4>
    <div
        class="company-input"
        [class.invalid-format]="companyInvalid">
        <input
            [(ngModel)]="companyName"
            [value]="companyName"
            type="text"
            id="company-input" />
    </div>
    <div class="buttons">
        <button
            mat-button
            (click)="saveCompany()"
            [disabled]="updatingCompany"
            class="primary">
            <p>Save and Checkout</p>
            <img
                src="/assets/rolling.gif"
                *ngIf="updatingCompany" />
        </button>
        <button
            mat-button
            (click)="closeDialog(false)"
            class="transparent">
            <p>Close</p>
        </button>
    </div>
</div>
