<div
    class="wrapper"
    [class]="breakpointService.currentBreakpointClass">
    <div
        class="tabs"
        *ngIf="!isPp">
        <button
            class="required-info"
            [ngClass]="activeTab === 1 ? 'active' : ''">
            Required Info
        </button>
    </div>
    <div
        class="comments"
        id="comment-wrapper">
        <h4
            class="comments-label"
            *ngIf="!isPp"
            [ngClass]="{
                invalid:
                    service.valid === false &&
                    service.data.description.trim() === ''
            }">
            <label for="comment-box">Your comments (required):</label>
        </h4>
        <button
            class="comments-label-pp"
            *ngIf="isPp"
            (click)="expanded.comments = !expanded.comments">
            <h3>Your comments <span>(required)</span></h3>
            <mat-icon
                *ngIf="!expanded.comments"
                svgIcon="plus3" />
            <mat-icon
                *ngIf="expanded.comments"
                svgIcon="minus3" />
        </button>
        <textarea
            id="comment-box"
            *ngIf="expanded.comments || !isPp"
            [(ngModel)]="service.data.description"
            (ngModelChange)="checkIfNeedsToSave()"
            placeholder="Please click here to leave a note for our designers with your specific instructions. Additionally, you can upload any reference photos you have."
            name="comments"
            rows="9">
        </textarea>
    </div>
    <div class="upload-ref">
        <app-upload-ref
            (refAdded)="addReferenceImage($event)"
            (refRemoved)="removeReferenceImage($event)"
            [projectGuid]="projectGuid"
            [serviceRequest]="serviceRequest"
            [service]="service"></app-upload-ref>
    </div>
</div>
