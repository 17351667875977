<form [class.error]="limitError">
    <mat-form-field class="chip-list">
        <mat-chip-grid
            #chipGrid
            aria-label="Room type selection">
            <mat-chip-row
                *ngFor="let selectedType of selectedRoomTypes"
                (removed)="remove(selectedType)">
                {{ selectedType }}
                <button
                    matChipRemove
                    [attr.aria-label]="'remove ' + selectedType">
                    <mat-icon svgIcon="xMark" />
                </button>
            </mat-chip-row>
        </mat-chip-grid>
        <input
            placeholder="Select or enter room type and press enter..."
            #roomTypeInput
            [(ngModel)]="inputValue"
            (blur)="clearInput($event)"
            [formControl]="inputCtrl"
            [matChipInputFor]="chipGrid"
            [matAutocomplete]="auto"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)"
            maxlength="15"
            #autoTrigger="matAutocompleteTrigger" />
        <mat-autocomplete
            #auto="matAutocomplete"
            class="chips-autocomplete"
            (optionSelected)="selected($event)">
            <mat-option
                *ngFor="let roomType of filteredRoomTypes | async"
                [value]="roomType.name">
                {{ roomType.name }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>
