import { Component, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ImageSliderComponent } from 'src/app/components/image-slider/image-slider.component';
import { BreakpointService } from 'src/app/services/breakpoint.service';

@Component({
    selector: 'app-vr-info',
    templateUrl: './vr-info.component.html',
    styleUrls: ['./vr-info.component.scss']
})
export class VrInfoComponent {
    public activeTab: number = 1;
    public roomTypes: any = [];
    public serviceTypes: any = [];
    public serviceRequest: any;
    public loading: boolean = true;
    public allStyles: any = [];
    public styleGroups: any;
    public groupNames: any = [];
    public user: any;
    public cdn: string = environment.cdn;
    public task: any;
    public projectGuid: string = '';
    public service: any;
    public isDirty: boolean = false;
    public changed: (dirty: boolean) => void = () => { };
    public hardCopy: any;
    @ViewChild('disclaimerDialog') disclaimerDialog: any;
    public disclaimerUnderstood: boolean = false;
    public disclaimerAccepted: boolean = false;
    private disclaimerDialogRef: any;
    // pp = phone portrait
    public isPp: boolean = false;
    public expanded: any = {
        vrTypes: false,
        roomTypes: false,
        furnitureStyles: false,
        comments: false
    }

    @Output() serviceInfo = new EventEmitter<any>();

    public triggerServiceInfoEvent(): void {
        this.validateService();
        if (this.service.valid) {
            this.serviceInfo.emit(this.service);
            this.hardCopy = JSON.stringify(this.service);
        }
    }

    constructor(
        public dialog: MatDialog,
        public breakpointService: BreakpointService,
        private cdr: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        this.disclaimerAccepted = localStorage.getItem(`signatureDisclaimer-${this.projectGuid}`) === 'true';
        this.user = JSON.parse(localStorage.getItem('user') || 'null');
        this.setStyleGroupsAndGroupNames();
        this.initiateService();
        if (!this.service.reference_assets) {
            this.service.reference_assets = [];
        }
        let globalStyle = localStorage.getItem(`style-${this.projectGuid}`);
        if (globalStyle && this.service.data.furnitureStyles === '') {
            let style = this.allStyles.find((style: any) => {
                if (globalStyle) {
                    return style.id === parseInt(globalStyle);
                } else {
                    return false;
                }
            });
            this.service.data.furnitureStyles = style?.id;
        }
        this.hardCopy = JSON.stringify(this.service);
    }

    selectVrType(type: string) {
        this.service.extra = type === 'exterior';
        this.checkIfNeedsToSave();
    }

    toCamelCase(str: string) {
        return str
            .split(" ")
            .map((word, index) => {
                if (index === 0) {
                    return word.toLowerCase();
                }
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            })
            .join("");
    }

    getStyleThumbnail(style: any) {
        if (style.asset_collection.length > 0) {
            return style.asset_collection[0].url;
        }
        let url = this.cdn + style.image_collection[0].image_name;
        return environment.server + "/asset/thumbnail/" + url.split("/").slice(-1)[0];
    }



    setStyleGroupsAndGroupNames() {
        this.styleGroups = this.allStyles.reduce((groups: any, style: any) => {
            const group = style.display || "Classic Designs";
            if (!groups[group]) {
                groups[group] = [];
            }
            groups[group].push(style);
            return groups;
        }, {});
        delete this.styleGroups['Signature Designs'];
        let email = this.user.email;
        const specificStyles = this.allStyles
            .filter((style: any) => style.display && style.value) // Filter items with both properties
            .map((style: any) => ({ display: style.display, value: style.value })) // Map to new objects
            .reduce((unique: any, item: any) => {
                if (!unique.some((obj: any) => obj.display === item.display && obj.value === item.value)) {
                    unique.push(item);
                }
                return unique;
            }, []);
        let stylesToRemove = specificStyles.filter((style: any) => {
            return email.split('@')[1] !== style.value.split('_')[1]
        });
        this.groupNames = Object.keys(this.styleGroups);
        this.groupNames.push(this.groupNames.splice(this.groupNames.indexOf('Classic Designs'), 1)[0]);

        // remove styles to remove from groupName
        stylesToRemove.forEach((style: any) => {
            this.groupNames.splice(this.groupNames.indexOf(style.display), 1);
        });
    }

    initiateService() {
        if (this.serviceRequest.service_request_tasks?.length > 0) {
            let service = this.serviceRequest.service_request_tasks.find((service: any) => service.settings_task.id === this.task.id);

            if (service) {
                this.service = service;
                let style: any;
                style = this.allStyles.find((style: any) => style.id === this.service.data.furnitureStyles);

            } else {
                this.setService();
            }
        } else {
            this.setService();
        }
    }

    setService() {
        this.service = {
            name: this.task.name,
            price: this.task.price,
            base_price: this.task.price,
            settings_task: {
                id: this.task.id
            },
            sort: this.task.sort,
            data: {
                imageData: [],
                roomTypes: [],
                furnitureStyles: '',
                description: '',
            },
            reference_assets: [],
            extra: false,
        }
    }

    validateService(): boolean {
        if (this.service.data.roomTypes.length === 0 || this.service.data.furnitureStyles.length === 0 || this.service.data.description?.trim() === '') {
            this.service = {
                ...this.service,
                valid: false,
            }
            this.activeTab = 1;
            this.cdr.detectChanges();
            setTimeout(() => {
                let invalidElement = document.querySelector('.invalid');
                if (invalidElement) {
                    invalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            });
            return false;
        } else {
            this.service = {
                ...this.service,
                valid: true,
            }
            return true;
        }
    }

    isValid(): boolean {
        if (this.service.data.roomTypes?.length === 0 || this.service.data.furnitureStyles?.length === 0 || this.service.data.description?.trim() === '') {
            return false;
        } else {
            return true;
        }
    }

    addReferenceImage(event: any) {
        if (this.service.reference_assets) {
            this.service.reference_assets.push(event);
        } else {
            this.service.reference_assets = [event];
        }
        this.checkIfNeedsToSave();
    }
    removeReferenceImage(id: any) {
        this.service.reference_assets = this.service.reference_assets.filter((image: any) => image.id !== id);
        this.checkIfNeedsToSave();
    }

    showSamples(style: any) {
        let images = [];
        if (style.image_collection.length > 0) {
            for (const [index, image] of style.image_collection.entries()) {
                images.push({
                    url: this.cdn + image.image_name,
                    caption: style.name + ' ' + (index + 1),
                    id: image.id,
                });
            }
        } else {
            for (const [index, asset] of style.asset_collection.entries()) {
                images.push({
                    url: asset.url,
                    caption: style.name + ' ' + (index + 1),
                    id: asset.id,
                });
            }
        }
        let styleDialog = this.dialog.open(ImageSliderComponent, {
            data: {
                images: images,
                addStyleRef: this.addReferenceImage.bind(this),
                service: this.service,
            },
            maxWidth: '100vw',
            panelClass: 'image-slider-dialog',
        });
    }

    checkIfNeedsToSave() {
        if (JSON.stringify(this.service) !== this.hardCopy) {
            this.changed(true);
        } else {
            this.changed(false);
        }
    }

    async selectStyle(style: any) {
        if (style.display === 'Signature Designs' && !this.disclaimerAccepted) {
            try {
                await this.signatureDisclaimer()
                this.service.data.furnitureStyles = style.id;
                this.checkIfNeedsToSave();
            } catch (error) {
                return;
            }
        } else {
            this.service.data.furnitureStyles = style.id;
            this.checkIfNeedsToSave();
        }
    }

    async signatureDisclaimer(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.disclaimerDialogRef = this.dialog.open(this.disclaimerDialog, {
                maxWidth: '100vw',
                panelClass: 'disclaimer-dialog',
            });
            this.disclaimerDialogRef.afterClosed().subscribe((result: any) => {
                if (result) {
                    resolve();
                } else {
                    reject('User did not accept disclaimer');
                }
            });
        });
    }


    acceptDisclaimer() {
        this.disclaimerAccepted = true;
        localStorage.setItem(`signatureDisclaimer-${this.projectGuid}`, 'true');
        if (this.disclaimerDialogRef) {
            this.disclaimerDialogRef.close(true);
        }
    }

}
