<div
    class="wrapper"
    [class.fade-out]="slideOut"
    *ngIf="!loading"
    [class]="breakpointService.currentBreakpointClass">
    <div class="header">
        <div class="home">
            <mat-icon svgIcon="logo" />
        </div>
        <button
            *ngIf="
                breakpointService.currentBreakpointClass !== 'is-phone-portrait'
            "
            class="learn-more"
            (click)="exploreVs()">
            Learn more about VirtualStaging.com →
        </button>
    </div>
    <div class="title">
        <p>
            <mat-icon svgIcon="thumbsUp" />
            These images have been virtually staged by VirtualStaging.com
        </p>
        <h1>
            View the before and after images for<br />
            <span>{{ project.project_name }}</span>
        </h1>
    </div>

    <div
        class="photos"
        [class.no-images]="!project.images || project.images.length === 0">
        <ba-slider [project]="project" />
    </div>
    <div
        class="footer"
        *ngIf="project.inviteLink">
        <p>
            VirtualStaging.com turns your listing's dull rooms into dream
            properties, guaranteed to get more views, attention and leads.
        </p>
        <input
            #fileInput
            type="file"
            multiple
            hidden
            (change)="uploadImages($event)" />
        <button
            class="get-started"
            (click)="fileInput.click()">
            Get started <mat-icon svgIcon="arrowRight" />
        </button>
        <div class="perks">
            <div
                class="perk button"
                (click)="goToTrustpilot()">
                <mat-icon
                    class="checkmark"
                    svgIcon="checkmark" />
                <p>4.9 out of 5</p>
                <mat-icon svgIcon="trustStar" />
                <p>Trustpilot</p>
            </div>
            <div class="perk">
                <mat-icon
                    class="checkmark"
                    svgIcon="checkmark" />
                <p>4 to 8-hour Delivery</p>
            </div>
            <div class="perk">
                <mat-icon
                    class="checkmark"
                    svgIcon="checkmark" />
                <p>100% Money Back Guarantee</p>
            </div>
            <div class="perk">
                <mat-icon
                    class="checkmark"
                    svgIcon="checkmark" />
                <p>Unlimited Revisions</p>
            </div>
        </div>
    </div>
</div>
