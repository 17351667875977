<div
    class="wrapper"
    [class]="breakpointService.currentBreakpointClass">
    <div class="image">
        <img
            *ngFor="let state of imageState"
            [@slide]="{
                value: state.state,
                params: {
                    direction:
                        state.direction === 'left'
                            ? 'translateX(100%)'
                            : 'translateX(-100%)'
                }
            }"
            (@slide.done)="animationDone($event)"
            [src]="state.image.url"
            alt="" />
    </div>
    <button
        (click)="close()"
        class="close">
        <mat-icon svgIcon="xMark" />
    </button>
    <div class="strip">
        <div class="caption">
            <h2
                *ngIf="
                    breakpointService.currentBreakpointClass !==
                    'is-phone-portrait'
                ">
                {{ imageState[0].image.caption }}
            </h2>
            <h2
                *ngIf="
                    breakpointService.currentBreakpointClass ===
                    'is-phone-portrait'
                ">
                {{ imageState[0].image.caption.split(" ").pop() }}
            </h2>
        </div>
        <button
            class="transparent"
            [disabled]="checkIfAddedAsRef()"
            (click)="addAsRef()">
            {{
                !checkIfAddedAsRef() ? "Add as reference" : "Added as reference"
            }}
        </button>
        <div class="navigation">
            <button (click)="slide(-1)">
                <mat-icon svgIcon="previous" />
            </button>
            <button (click)="slide(1)">
                <mat-icon svgIcon="next" />
            </button>
        </div>
    </div>
</div>
