import { Directive, ElementRef, forwardRef, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

declare var intlTelInput: any;
declare var intlTelInputUtils: any;

@Directive({
    selector: '[appIntlTelInput]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IntlTelInputDirective),
            multi: true
        }
    ],
    standalone: true
})
export class IntlTelInputDirective implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() appIntlTelInput!: string;
    @Output() countryCodeChange = new EventEmitter<string>(); // Emit country code change

    private intlTelInputInstance: any;
    private onChange = (_: any) => { };
    private onTouched = () => { };
    private previousCountryCode: string = '';

    constructor(private el: ElementRef) { }

    ngOnInit() {
        setTimeout(() => {
            this.intlTelInputInstance = intlTelInput(this.el.nativeElement, {
                initialCountry: 'auto',
                utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js',
                autoPlaceholder: 'aggressive',
                nationalMode: true,
                separateDialCode: true,
                formatOnDisplay: true
            });

            this.previousCountryCode = this.intlTelInputInstance.getSelectedCountryData().iso2;

            this.el.nativeElement.addEventListener('countrychange', this.handleCountryChange.bind(this));
            this.el.nativeElement.addEventListener('input', this.handleInput.bind(this));
            this.el.nativeElement.addEventListener('blur', this.handleBlur.bind(this));

            if (this.el.nativeElement.value) {
                this.formatNumber();
            }
        });
    }

    handleCountryChange() {
        const currentCountryCode = this.intlTelInputInstance.getSelectedCountryData().iso2;
        if (currentCountryCode !== this.previousCountryCode) {
            this.previousCountryCode = currentCountryCode;
            this.onChange(this.el.nativeElement.value);
            this.formatNumber();
            this.countryCodeChange.emit(currentCountryCode); // Emit the country code change
        }
    }

    handleInput() {
        this.formatNumber();
        this.onChange(this.el.nativeElement.value);
    }

    handleBlur() {
        this.onTouched();
    }

    setValue(value: string) {
        if (this.intlTelInputInstance) {
            this.intlTelInputInstance.setNumber(value);
            this.formatNumber();
        }
    }

    formatNumber() {
        if (this.intlTelInputInstance && typeof intlTelInputUtils !== 'undefined') {
            const currentNumber = this.el.nativeElement.value;
            const formattedNumber = this.intlTelInputInstance.getNumber(intlTelInputUtils.numberFormat.NATIONAL);

            if (formattedNumber) {
                this.el.nativeElement.value = formattedNumber;
            }
        } else {
            console.error('intlTelInputUtils is not loaded.');
        }
    }

    writeValue(obj: any): void {
        if (this.intlTelInputInstance) {
            this.intlTelInputInstance.setNumber(obj);
            this.formatNumber();
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.el.nativeElement.disabled = isDisabled;
    }

    ngOnDestroy() {
        if (this.intlTelInputInstance) {
            this.intlTelInputInstance.destroy();
        }
    }

    isValidNumber(): boolean {
        return this.intlTelInputInstance.isValidNumber();
    }

    getNumber(): string {
        return this.intlTelInputInstance.getNumber(intlTelInputUtils.numberFormat.E164);
    }
}
