<div
    class="wrapper"
    [ngClass]="breakpointService.currentBreakpointClass"
    cdkScrollable>
    <button
        class="close-dialog"
        (click)="closeDialog()">
        <mat-icon svgIcon="xMark" />
    </button>
    <div class="container">
        <div
            class="header"
            *ngIf="!data.projectType">
            <h1>Select the service you need below</h1>
        </div>
        <div class="image-and-services">
            <p *ngIf="!data.projectType">
                You can select one or multiple services for your photo
            </p>
            <div
                class="services"
                *ngIf="!data.projectType"
                id="services-wrapper">
                <ng-container *ngFor="let service of serviceTypes">
                    <div
                        class="service"
                        [class.selected]="service.id === selectedServiceType.id"
                        (click)="checkBeforeSelect(service)"
                        [ngClass]="service.selected ? 'selected' : ''"
                        [class.disabled]="
                            (service.name !== 'Virtual Renovation' &&
                                vrAdded) ||
                            (service.name !== '3D Floor Plan' && fp2Added)
                        "
                        *ngIf="!service.disabled">
                        <mat-icon [svgIcon]="service.icon" />
                        <div class="name-and-price">
                            <p class="name">{{ service.name }}</p>
                            <p
                                class="price"
                                *ngIf="service.added">
                                {{ calculatePrice(service) | currency }}
                            </p>
                        </div>
                        <mat-icon
                            *ngIf="service.added"
                            class="checkmark"
                            svgIcon="greenCheckmark" />
                    </div>
                </ng-container>
            </div>
            <div
                class="image"
                *ngIf="selectedServiceType.name !== 'Item Removal'">
                <app-annotations
                    [serviceRequest]="serviceRequest"
                    [imageHeight]="imageHeight"
                    [annotations]="currentAnnotations"
                    (annotationsChange)="handleAnnotationsChange($event)">
                </app-annotations>
                <div
                    class="overlay"
                    id="annotations-overlay"
                    (click)="hideAnnotationsOverlay()"
                    *ngIf="annotationsOverlayVisible">
                    <div class="focus">
                        <h2>
                            Click here to leave annotations for
                            <br />
                            <span class="service-name">{{
                                selectedServiceType.name
                            }}</span>
                            (optional)
                            <span class="edges-top"></span>
                            <span class="edges-bottom"></span>
                        </h2>
                    </div>
                </div>
            </div>
            <div
                class="image"
                *ngIf="selectedServiceType.name === 'Item Removal'">
                <item-removal
                    [serviceRequest]="serviceRequest"
                    [imageHeight]="imageHeight"
                    [itemData]="irData"
                    (update)="setImageData($event)" />
            </div>
        </div>
        <button
            *ngIf="this.serviceRequest.asset.is360"
            class="toggle-360"
            (click)="markNot360()">
            Is this a 360 photo? <span>Click here if it's not</span>
        </button>
        <div
            class="service-info"
            [ngClass]="selectedServiceType.name">
            <ng-template #serviceInfoAnchor></ng-template>
        </div>
        <div class="actions">
            <button
                class="remove"
                [disabled]="addingService"
                *ngIf="selectedServiceType.added"
                (click)="addRemoveService()">
                Remove
                {{ selectedServiceType.name }}
            </button>
            <button
                *ngIf="
                    selectedServiceType.added &&
                    selectedServiceType.dirty &&
                    !addingService
                "
                class="save"
                (click)="saveService()">
                Save
            </button>
            <div class="right">
                <button
                    class="add"
                    [disabled]="addingService"
                    *ngIf="!selectedServiceType.added && componentRef"
                    (click)="addRemoveService()">
                    Add
                    {{ selectedServiceType.name }}
                </button>
                <button
                    class="summary"
                    *ngIf="data.project.ready"
                    [disabled]="addingService"
                    (click)="closeDialog()">
                    Go to Project
                </button>
                <button
                    class="go-next"
                    *ngIf="
                        !data.project.ready &&
                        this.serviceTypes &&
                        checkIfAnyServiceAdded()
                    "
                    [disabled]="addingService"
                    (click)="goToNextImage()">
                    Go to Next Image
                </button>
            </div>
        </div>
    </div>
</div>
