<div
    class="footer-wrapper"
    [class]="breakpointService.currentBreakpointClass">
    <p>{{ getYear() }} © VS24, Inc</p>
    <ul>
        <li>
            <a
                href="https://www.virtualstaging.com/legal-and-policies/terms-and-conditions"
                >Terms</a
            >
        </li>
        <li>
            <a
                href="https://www.virtualstaging.com/legal-and-policies/privacy-policy"
                >Privacy policy</a
            >
        </li>
        <li>
            <a
                href="https://www.virtualstaging.com/legal-and-policies/disclaimer"
                >Disclaimer</a
            >
        </li>
        <li>
            <a
                href="https://www.virtualstaging.com/legal-and-policies/refund-policy"
                >Refund policy</a
            >
        </li>
        <li>
            <a
                href="https://www.virtualstaging.com/legal-and-policies/revisions-policy"
                >Revisions</a
            >
        </li>
        <li>
            <a
                href="https://www.virtualstaging.com/legal-and-policies/cookie-policy"
                >Cookies</a
            >
        </li>
    </ul>
</div>
