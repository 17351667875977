<div
    class="item-removal--container"
    [ngClass]="{ disabled: this.disable }"
    id="item-removal-grid"
    (window:resize)="imageSize = img.height">
    <img
        #imageElement
        [src]="serviceRequest.asset.url"
        onError="this.src='./assets/images/broken_image.jpg'"
        alt="VirtualStaging.com"
        #img />
    <table class="table table-bordered">
        <tbody>
            <tr *ngFor="let row of [0, 1, 2, 3, 4]">
                <td
                    *ngFor="let column of [0, 1, 2, 3, 4, 5, 6, 7]"
                    [ngClass]="{ active: checkField(row + 1, column + 1) }"
                    (click)="toggleField(row + 1, column + 1)"></td>
            </tr>
        </tbody>
    </table>
</div>
