<div class="grand-container">
    <div class="referral-container">
        <div class="content">
            <h1>Get Two Free Virtual Staging Credits<br />($48 value)</h1>
            <div class="slider">
                <div class="img-container">
                    <img
                        src="/assets/referral-illustrations/illustration_4.png"
                        alt="" />
                </div>
                <div class="slide-txt">
                    You can use the credits to virtually stage two photos.
                </div>
                <br />
                <div class="slide-txt">
                    Credits are only valid for virtual staging. Additional
                    services, if selected, will be charged extra.
                </div>
                <br />
                <div class="slide-txt">
                    Please use your credits within the next 30 days. If unused,
                    your credits will expire.
                </div>
            </div>
            <div class="invite-section">
                <div class="copy-share">
                    <p class="text">
                        Click the button below to claim free virtual staging
                    </p>
                    <button
                        class="email-share"
                        (click)="acquireCredits()">
                        CLAIM FREE STAGING
                    </button>
                    <p class="top-8px">
                        Limited time offer. Please claim and use free credits in
                        the next {{ remainingTime }}.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
