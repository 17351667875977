import { Component, Inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogRef } from '@angular/cdk/dialog';
import { environment } from 'src/app/environments/environment';
import { BreakpointService } from 'src/app/services/breakpoint.service';

@Component({
    selector: 'app-create-anonymous',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatInputModule,
        ReactiveFormsModule,
    ],
    templateUrl: './create-anonymous.component.html',
    styleUrls: ['./create-anonymous.component.scss']
})
export class CreateAnonymousComponent {
    @Input() images: any;
    @Input() inviteLink: string = '';
    public processing: boolean = false;
    public error: boolean = false;
    public errorMessage: string = '';
    public projectForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        projectName: ['', Validators.required],
    });

    get email() {
        return this.projectForm.get('email');
    }

    get projectName() {
        return this.projectForm.get('projectName');
    }

    constructor(
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private router: Router,
        public dialogRef: DialogRef,
        public breakpointService: BreakpointService
    ) { }

    ngOnInit() {
        this.images = this.data.images;
        this.inviteLink = this.data.inviteLink;
    }

    async createAnonProject() {
        this.processing = true;
        let formData = new FormData();
        formData.append('email', this.email?.value || '');
        formData.append('project_name', this.projectName?.value || '');
        formData.append('inviteLink', this.inviteLink || '');
        Array.from(this.images).forEach((image: any, index) => {
            formData.append(`image-${index}`, image.file as Blob);
        });

        try {
            const response = await fetch(`${environment.api}project/anonymous/create`, {
                method: "POST",
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                let serializedData = encodeURIComponent(JSON.stringify(data));
                let email = encodeURIComponent(this.email?.value || '');
                this.processing = false;
                this.router.navigate(['/new-anon-project'], { queryParams: { data: serializedData, email: email } });
                this.dialogRef.close();
            } else {
                this.processing = false;
                this.error = true;
                let error = await response.json();
                this.errorMessage = error.message;
                setTimeout(() => {
                    this.error = false;
                }, 5000)
            }
        } catch (error) {
            this.processing = false;
            this.error = true;
            setTimeout(() => {
                this.error = false;
            }, 3000)
        }
    }
}
