<div
    class="wrapper"
    [class]="breakpointService.currentBreakpointClass"
    cdkScrollable
    [ngClass]="{ dirty: isDirty }">
    <div
        class="tabs"
        *ngIf="!isPp">
        <button
            class="required-info"
            (click)="activeTab = 1"
            [ngClass]="'active'">
            Required Info
        </button>
    </div>
    <div class="vr-types">
        <p
            class="vr-types-label"
            *ngIf="!isPp">
            Virtual Renovation Type (required):
        </p>
        <button
            class="vr-types-label-pp"
            *ngIf="isPp"
            (click)="expanded.vrTypes = !expanded.vrTypes">
            <h3>Virtual Rennovation Type <span>(required)</span></h3>
            <mat-icon
                *ngIf="!expanded.vrTypes"
                svgIcon="plus3" />
            <mat-icon
                *ngIf="expanded.vrTypes"
                svgIcon="minus3" />
        </button>

        <div
            class="types"
            *ngIf="expanded.vrTypes || !isPp">
            <div
                class="type"
                [class.selected]="!service.extra"
                (click)="service.extra = false; checkIfNeedsToSave()">
                <mat-icon svgIcon="virtualRenovation" />
                <div class="name-and-price">
                    <p class="name">Interior Renovation</p>
                    <p class="price">${{ task.price.toFixed(2) }}</p>
                </div>
                <mat-icon
                    *ngIf="!service.extra"
                    class="checkmark"
                    svgIcon="greenCheckmark" />
            </div>
            <div
                class="type"
                [class.selected]="service.extra"
                (click)="service.extra = true; checkIfNeedsToSave()">
                <mat-icon svgIcon="exteriorRenovation" />
                <div class="name-and-price">
                    <p class="name">Exterior Renovation</p>
                    <p class="price">
                        ${{ (task.price + task.extra_price).toFixed(2) }}
                    </p>
                </div>
                <mat-icon
                    *ngIf="service.extra"
                    class="checkmark"
                    svgIcon="greenCheckmark" />
            </div>
        </div>
    </div>
    <div class="room-types">
        <p
            class="room-types-label"
            *ngIf="!isPp"
            [class.invalid]="
                this.service.valid === false &&
                service.data.roomTypes.length === 0
            ">
            Room Type (required):
        </p>
        <button
            class="room-types-label-pp"
            *ngIf="isPp"
            (click)="expanded.roomTypes = !expanded.roomTypes"
            [ngClass]="{
                invalid:
                    this.service.valid === false &&
                    service.data.roomTypes.length === 0
            }">
            <h3>Room Type <span>(required)</span></h3>
            <mat-icon
                *ngIf="!expanded.roomTypes"
                svgIcon="plus3" />
            <mat-icon
                *ngIf="expanded.roomTypes"
                svgIcon="minus3" />
        </button>

        <chips
            *ngIf="expanded.roomTypes || !isPp"
            [roomTypes]="roomTypes"
            [selectedRoomTypes]="service.data.roomTypes"
            (changed)="checkIfNeedsToSave()" />
    </div>
    <div class="furniture-styles">
        <p
            class="furniture-styles-label"
            *ngIf="!isPp"
            [ngClass]="{
                invalid:
                    this.service.valid === false &&
                    service.data.furnitureStyles.length === 0
            }">
            Furniture Style (required):
        </p>
        <button
            class="furniture-styles-label-pp"
            *ngIf="isPp"
            (click)="expanded.furnitureStyles = !expanded.furnitureStyles"
            [ngClass]="{
                invalid:
                    this.service.valid === false &&
                    service.data.furnitureStyles.length === 0
            }">
            <h3>Furniture Style <span>(required)</span></h3>
            <mat-icon
                *ngIf="!expanded.furnitureStyles"
                svgIcon="plus3" />
            <mat-icon
                *ngIf="expanded.furnitureStyles"
                svgIcon="minus3" />
        </button>
        <div
            class="groups"
            *ngIf="expanded.furnitureStyles || !isPp">
            <div
                *ngFor="let group of groupNames"
                class="group">
                <h5>
                    {{ group }}
                </h5>
                <ng-template
                    [ngTemplateOutlet]="styleCards"
                    [ngTemplateOutletContext]="{
                        $implicit: group
                    }"></ng-template>
            </div>
        </div>
    </div>
    <div class="comments">
        <h4
            *ngIf="!isPp"
            [ngClass]="{
                invalid:
                    service.valid === false &&
                    service.data.description.trim() === ''
            }">
            <label for="comment-box">Your comments (required):</label>
        </h4>
        <button
            class="comments-label-pp"
            *ngIf="isPp"
            (click)="expanded.comments = !expanded.comments">
            <h3>Your comments (required)</h3>
            <mat-icon
                *ngIf="!expanded.comments"
                svgIcon="plus3" />
            <mat-icon
                *ngIf="expanded.comments"
                svgIcon="minus3" />
        </button>
        <textarea
            id="comment-box"
            *ngIf="expanded.comments || !isPp"
            [(ngModel)]="service.data.description"
            (ngModelChange)="checkIfNeedsToSave()"
            placeholder="Click here to provide instructions for our designer. Please provide detailed specifications on what you would like our designers to do. Please indicate any items that should be removed or retained, any desired alterations to the flooring, walls, furniture, or any structural modifications. Additionally, feel free to upload reference photos for the flooring, walls, furniture, and other relevant elements. Thank you!"
            name="comments"
            rows="9">
        </textarea>
    </div>
    <div class="upload-ref">
        <app-upload-ref
            (refAdded)="addReferenceImage($event)"
            (refRemoved)="removeReferenceImage($event)"
            [projectGuid]="projectGuid"
            [serviceRequest]="serviceRequest"
            [service]="service"></app-upload-ref>
    </div>
</div>

<ng-template
    #styleCards
    let-group>
    <div class="style-cards">
        <div
            *ngFor="let style of styleGroups[group]"
            class="style-card"
            (click)="selectStyle(style)"
            [class.selected]="
                service.data.furnitureStyles
                    ? service.data.furnitureStyles === style.id
                    : null
            ">
            <div class="header">
                <h4 class="name">{{ style.name }}</h4>
                <mat-icon
                    *ngIf="
                        service.data.furnitureStyles
                            ? service.data.furnitureStyles === style.id
                            : null
                    "
                    svgIcon="greenCheckmark" />
            </div>
            <div class="image">
                <img
                    [src]="getStyleThumbnail(style)"
                    alt="" />
            </div>
            <button (click)="showSamples(style)">
                <mat-icon svgIcon="expand" />Examples
            </button>
        </div>
    </div>
</ng-template>

<ng-template #disclaimerDialog>
    <div
        class="disclaimer-dialog"
        [class]="breakpointService.currentBreakpointClass">
        <img
            src="assets/signature-disclaimer.png"
            alt="" />
        <div class="content">
            <h1>Discover our Signature Designs</h1>
            <div class="text">
                <p>
                    Dive into a blend of style and simplicity with our carefully
                    curated pieces, perfect for any space size.
                </p>
                <p>
                    They're ideal when you prefer not to provide design
                    instructions, specifics, or reference photos. A few things
                    to note about our Signature Designs:
                </p>
                <p>
                    <mat-icon svgIcon="grayCheckmark" /><span
                        >What You See is What You Get:</span
                    >
                    The furniture displayed in each sample is exactly what
                    you'll receive.
                </p>
                <p>
                    <mat-icon svgIcon="grayCheckmark" /><span
                        >Adaptable Designs:</span
                    >
                    For larger rooms, we may add pieces that match the sample
                    style. For smaller spaces, we might reduce items to keep the
                    design open and inviting.
                </p>
                <p>
                    <mat-icon svgIcon="grayCheckmark" /><span
                        >All Room Types Welcome:</span
                    >
                    If you don't see your room type in our samples, no worries.
                    Our designers will craft a design for any room based on the
                    style you choose, even if it's not directly showcased.
                </p>
            </div>
            <div class="sign">
                <input
                    type="checkbox"
                    name="signature-disclaimer-sign"
                    [(ngModel)]="disclaimerUnderstood"
                    hidden
                    id="signature-disclaimer-sign" />
                <label for="signature-disclaimer-sign"
                    ><span class="checkmark"
                        ><mat-icon
                            svgIcon="blueCheckmark2"
                            *ngIf="disclaimerUnderstood"
                    /></span>
                    By selecting a Signature Designs style, I understand that I
                    will receive the exact furniture pieces as in the
                    examples.</label
                >
            </div>
            <button
                class="primary"
                [disabled]="!disclaimerUnderstood"
                (click)="acceptDisclaimer()">
                Accept
            </button>
        </div>
    </div></ng-template
>
