import { Component } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { BreakpointService } from './services/breakpoint.service';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './services/auth.service';
import { EtaSettingsService } from './services/eta-settings.service';
import { ApiService } from './services/api.service';
import { environment } from './environments/environment';
import { merge } from 'lodash';
import Cohere from "cohere-js";
import { UserService } from './services/user.service';

declare global {
	interface Window {
		Intercom: any;
	}
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	private customMatIcons: any;
	public loading: boolean = true;
	public billingUrl: string = "";
	public user = JSON.parse(localStorage.getItem('user') || '{}');
	public balance: any;
	constructor(
		private iconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		public breakpointService: BreakpointService,
		private router: Router,
		private auth: AuthService,
		private etaSettingsService: EtaSettingsService,
		private api: ApiService,
		private userService: UserService,
	) {
		this.customMatIcons = {
			logo: '../assets/icons/logo.svg',
			newProject: '../assets/icons/new-project.svg',
			menu: '../assets/icons/menu.svg',
			photo: '../assets/icons/photo.svg',
			options: '../assets/icons/options.svg',
			logoSmall: '../assets/icons/logo-small.svg',
			uploadImage: '../assets/icons/upload-image.svg',
			addImage: '../assets/icons/add-image.svg',
			addServices: '../assets/icons/add-services.svg',
			google: '../assets/icons/google.svg',
			apple: '../assets/icons/apple.svg',
			virtualStaging: '../assets/icons/service-icons/virtual-staging.svg',
			imageEnhancement: '../assets/icons/service-icons/image-enhancement.svg',
			itemRemoval: '../assets/icons/service-icons/item-removal.svg',
			twilight: '../assets/icons/service-icons/twilight.svg',
			wallChange: '../assets/icons/service-icons/wall-change.svg',
			floorChange: '../assets/icons/service-icons/floor-change.svg',
			virtualRenovation: '../assets/icons/service-icons/virtual-renovation.svg',
			exteriorRenovation: '../assets/icons/service-icons/exterior-renovation.svg',
			greenCheckmark: '../assets/icons/green-checkmark.svg',
			xMark: '../assets/icons/x-mark.svg',
			radio: '../assets/icons/radio.svg',
			radioSelected: '../assets/icons/radio-selected.svg',
			expand: '../assets/icons/expand.svg',
			discount: '../assets/icons/discount.svg',
			clock: '../assets/icons/clock.svg',
			plus: '../assets/icons/plus.svg',
			delete: '../assets/icons/delete.svg',
			'360vsPricing': '../assets/icons/pricing-icons/360vs-pricing.svg',
			enterprise: '../assets/icons/pricing-icons/enterprise.svg',
			fcPricing: '../assets/icons/pricing-icons/fc-pricing.svg',
			irPricing: '../assets/icons/pricing-icons/ir-pricing.svg',
			iePricing: '../assets/icons/pricing-icons/ie-pricing.svg',
			twPricing: '../assets/icons/pricing-icons/tw-pricing.svg',
			wcPricing: '../assets/icons/pricing-icons/wc-pricing.svg',
			vsPricing: '../assets/icons/pricing-icons/vs-pricing.svg',
			vrPricing: '../assets/icons/pricing-icons/vr-pricing.svg',
			blueCheckmark: '../assets/icons/blue-checkmark.svg',
			previous: '../assets/icons/previous.svg',
			next: '../assets/icons/next.svg',
			chatBubble: '../assets/icons/chat-bubble.svg',
			download: '../assets/icons/download.svg',
			customizeOption: '../assets/icons/customize-option.svg',
			xMark2: '../assets/icons/x-mark-2.svg',
			grayCheckmark: '../assets/icons/gray-checkmark.svg',
			blueCheckmark2: '../assets/icons/blue-checkmark-2.svg',
			wallet: '../assets/icons/wallet.svg',
			balance: '../assets/icons/balance.svg',
			eye: '../assets/icons/eye.svg',
			minus: '../assets/icons/minus.svg',
			plus2: '../assets/icons/plus-2.svg',
			fb: '../assets/icons/fb.svg',
			x: '../assets/icons/x.svg',
			gridView: '../assets/icons/grid-view.svg',
			listView: '../assets/icons/list-view.svg',
			clock2: '../assets/icons/clock-2.svg',
			warning: '../assets/icons/warning.svg',
			vsMobile: '../assets/icons/mobile-icons/vs-mobile.svg',
			ieMobile: '../assets/icons/mobile-icons/ie-mobile.svg',
			irMobile: '../assets/icons/mobile-icons/ir-mobile.svg',
			twMobile: '../assets/icons/mobile-icons/tw-mobile.svg',
			wcMobile: '../assets/icons/mobile-icons/wc-mobile.svg',
			fcMobile: '../assets/icons/mobile-icons/fc-mobile.svg',
			vrMobile: '../assets/icons/mobile-icons/vr-mobile.svg',
			fpMobile: '../assets/icons/mobile-icons/fp-mobile.svg',
			vsMobileAdded: '../assets/icons/mobile-icons/vs-mobile-added.svg',
			ieMobileAdded: '../assets/icons/mobile-icons/ie-mobile-added.svg',
			irMobileAdded: '../assets/icons/mobile-icons/ir-mobile-added.svg',
			twMobileAdded: '../assets/icons/mobile-icons/tw-mobile-added.svg',
			wcMobileAdded: '../assets/icons/mobile-icons/wc-mobile-added.svg',
			fcMobileAdded: '../assets/icons/mobile-icons/fc-mobile-added.svg',
			vrMobileAdded: '../assets/icons/mobile-icons/vr-mobile-added.svg',
			fpMobileAdded: '../assets/icons/mobile-icons/fp-mobile-added.svg',
			plus3: '../assets/icons/plus-3.svg',
			minus3: '../assets/icons/minus-3.svg',
			photo2: '../assets/icons/photo-2.svg',
			info: '../assets/icons/info.svg',
			info2: '../assets/icons/info-2.svg',
			floorPlan: '../assets/icons/service-icons/floor-plan.svg',
			logout: '../assets/icons/logout.svg',
			specialOffer: '../assets/icons/special-offer.svg',
			inviteOthers: '../assets/icons/invite-others.svg',
			sharePublicly: '../assets/icons/share-publicly.svg',
			embed: '../assets/icons/embed.svg',
			global: '../assets/icons/global.svg',
			share: '../assets/icons/share.svg',
			thumbsUp: '../assets/icons/thumbs-up.svg',
			arrowRight: '../assets/icons/arrow-right.svg',
			trustStar: '../assets/icons/trust-star.svg',
			checkmark: '../assets/icons/checkmark.svg',
			affiliate: '../assets/icons/affiliate.svg',
			edit: '../assets/icons/edit.svg',
			remove: '../assets/icons/remove.svg',
			camera: '../assets/icons/camera.svg',
			'3dView': '../assets/icons/3d-view.svg',
			fpView: '../assets/icons/fp-view.svg',
			walkthroughView: '../assets/icons/walkthrough-view.svg',
			propertyType: '../assets/icons/property-type.svg',
			yearBuilt: '../assets/icons/year-built.svg',
			orderFp: '../assets/icons/order-fp.svg',
			liveChat: '../assets/icons/live-chat.svg',
			fullScreen: '../assets/icons/full-screen.svg',
			info3: '../assets/icons/info-3.svg',
			binoculars: '../assets/icons/binoculars.svg',
			leftArrow: '../assets/icons/left-arrow.svg',
			rightArrow: '../assets/icons/right-arrow.svg',
			floorplanRender: '../assets/icons/floorplan-render.svg',
			"3dFloorPlan": '../assets/icons/service-icons/3d-floor-plan.svg',
			search: '../assets/icons/search.svg',
		};
		for (let icon in this.customMatIcons) {
			this.iconRegistry.addSvgIcon(
				icon,
				this.domSanitizer.bypassSecurityTrustResourceUrl(this.customMatIcons[icon])
			);
		}
	}

	ngOnInit() {
		this.auth.getAuthStatus().subscribe(isAuthenticated => {
			if (isAuthenticated) {
				this.userService.loadUserFromDB().then(user => {
					this.userService.updateUser(user);
					this.user = user;
				});
				Promise.all([
					this.api.get("estimate_settings"),
					this.api.get("settings", { type: "limit-price" }),
					this.api.get("settings", { type: "eta" }),
					this.api.get("settings", { type: "rush" }),
					this.api.get("user/stripe/portal", { redirect: window.location.href }),
					this.api.get("wallet/balance"),
				]).then(([etaSettings, limitPrice, alwaysSameEta, rushSettings, billing, balance]) => {
					this.etaSettingsService.setEtaSettings(etaSettings);
					this.etaSettingsService.setLimitPrice(limitPrice);
					this.etaSettingsService.setAlwaysSameEta(alwaysSameEta);
					this.etaSettingsService.setRushSettings(rushSettings);
					this.billingUrl = billing.url;
					this.balance = balance;
					this.loading = false;
				}).catch((error) => {
					console.error('Error:', error);
				});
			} else {
				this.loading = false;
			}
		});
		this.router.events.subscribe((event: any) => {
			if (event instanceof NavigationEnd) {
				this.handleIntercomVisibility(event.url);
			}
		});
	}

	async ngAfterViewInit() {
		this.auth.getAuthStatus().subscribe(async (isAuthenticated) => {
			if (isAuthenticated) {
				const intercom_hash = await this.api.get("user/intercom");
				const userInfo = this.user
					? {
						name: this.user.first_name + " " + this.user.last_name,
						email: this.user.email,
						phone: this.user.phone_number,
						created_at: this.user.created,
						user_hash: intercom_hash,
					}
					: {};
				let data = merge(
					{
						app_id: environment.intercomKey,
						widget: {
							activator: ".live-chat",
						}
					},
					userInfo
				)
				window.Intercom('boot', data);
				this.handleIntercomVisibility(this.router.url);
				Cohere.init(environment.cohereKey);
				Cohere.identify(this.user.id,
					{
						displayName: this.user.first_name + ' ' + this.user.last_name,
						email: this.user.email,
					}
				);
			}
		});
	}

	getCookie(name: string): string {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) {
			return parts.pop()?.split(';').shift() || '';
		}
		return '';
	}


	showScrollbar(): boolean {
		const projectDeliveryRegex = /^\/projects\/.+\/delivery$/;
		return projectDeliveryRegex.test(this.router.url) && !this.breakpointService.isTouchDevice;
	}

	handleIntercomVisibility(url: string) {
		const projectUrlPattern = /\/projects\/[A-Za-z0-9-]+\/summary/;
		const shouldHideLauncher = (url.includes('new-project') || projectUrlPattern.test(url)) && this.breakpointService.isTouchDevice;
		const projectEmbed = /^\/projects\/.+\/embed$/;
		const publicProjectRegex = /^\/p\/.+/;
		const blankPageRegex = /^\/blank-page$/;
		if (shouldHideLauncher || projectEmbed.test(url) || publicProjectRegex.test(url) || blankPageRegex.test(url)) {
			window.Intercom('update', { hide_default_launcher: true });
		} else {
			window.Intercom('update', { hide_default_launcher: false });
		}
	}


	getLogo(): string {
		if (this.breakpointService.currentBreakpointClass === "is-phone-portrait" || this.breakpointService.currentBreakpointClass === "is-phone-landscape" || this.breakpointService.currentBreakpointClass === "is-tablet-portrait" || this.breakpointService.currentBreakpointClass === "is-tablet-landscape") {
			return "logoSmall";
		} else {
			return "logo";
		}
	}

	goHome() {
		this.router.navigate(["/projects"]);
	}

	hideHeader() {
		const projectSummaryRegex = /^\/projects\/.+\/summary$/;
		const projectFloorplanRegex = /^\/projects\/.+\/floorplan$/;
		const newProjectWithQueryParamsRegex = /^\/new-project\?/;
		const confirmPageRegex = /^\/confirm\/.+\/.+$/;
		const loginWithQueryParamsRegex = /^\/login\?/;
		const startsWithRegister = /^\/register/;
		const newAnonProjectRegex = /^\/new-anon-project\/?/;
		const projectEmbed = /^\/p\/embed\/[a-zA-Z0-9\-_]+$/;
		const publicProjectRegex = /^\/p\/.+/;
		const blankPageRegex = /^\/blank-page$/;
		const landingPageRegex = /^\/p\/lp\/.+/;
		return this.router.url === "/new-project" ||
			this.router.url === "/login" ||
			confirmPageRegex.test(this.router.url) ||
			projectSummaryRegex.test(this.router.url) || newProjectWithQueryParamsRegex.test(this.router.url) || this.router.url === '/new-floorplan' || projectFloorplanRegex.test(this.router.url) || loginWithQueryParamsRegex.test(this.router.url) || startsWithRegister.test(this.router.url) || newAnonProjectRegex.test(this.router.url) || projectEmbed.test(this.router.url) || publicProjectRegex.test(this.router.url) || blankPageRegex.test(this.router.url) || landingPageRegex.test(this.router.url);
	}

	hideFooter() {
		const projectSummaryRegex = /^\/projects\/.+\/summary$/;
		const projectFloorplanRegex = /^\/projects\/.+\/floorplan$/;
		const newProjectWithQueryParamsRegex = /^\/new-project\?/;
		const confirmPageRegex = /^\/confirm\/.+\/.+$/;
		const loginWithQueryParamsRegex = /^\/login\?/;
		const startsWithRegister = /^\/register/;
		const newAnonProjectRegex = /^\/new-anon-project\/?/;
		const projectEmbed = /^\/p\/embed\/[a-zA-Z0-9\-_]+$/;
		const blankPageRegex = /^\/blank-page$/;
		const landingPageRegex = /^\/p\/lp\/.+/;
		return this.router.url === "/new-project" ||
			this.router.url === "/login" ||
			confirmPageRegex.test(this.router.url) ||
			projectSummaryRegex.test(this.router.url) || newProjectWithQueryParamsRegex.test(this.router.url) || this.router.url === '/new-floorplan' || projectFloorplanRegex.test(this.router.url) || loginWithQueryParamsRegex.test(this.router.url) || startsWithRegister.test(this.router.url) || newAnonProjectRegex.test(this.router.url) || projectEmbed.test(this.router.url) || blankPageRegex.test(this.router.url) || landingPageRegex.test(this.router.url);
	}
}
