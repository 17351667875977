import { Component, Output, EventEmitter, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BreakpointService } from 'src/app/services/breakpoint.service';


@Component({
    selector: 'app-ir-info',
    templateUrl: './ir-info.component.html',
    styleUrls: ['./ir-info.component.scss']
})
export class IrInfoComponent {
    public activeTab: number = 1;
    public comments: string = '';
    public serviceRequest: any;
    public service: any;
    public task: any;
    public projectGuid: string = '';
    public changed: (dirty: boolean) => void = () => { };
    public hardCopy: any;
    public isPp: boolean = false;
    public expanded: any = {
        comments: false,
    }


    @Output() serviceInfo = new EventEmitter<any>();

    constructor(
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        public breakpointService: BreakpointService
    ) { }


    public triggerServiceInfoEvent(): void {
        this.validateService();
        if (this.service.valid) {
            this.service.price = 0.5 * this.service.data.imageData.length;
            this.serviceInfo.emit(this.service);
            this.hardCopy = JSON.stringify(this.service);
        } else {
            this.scrollToGrid();
        }
    }

    ngOnInit() {
        this.initiateService();
        this.hardCopy = JSON.stringify(this.service);
    }

    initiateService() {
        if (this.serviceRequest.service_request_tasks?.length > 0) {
            let service = this.serviceRequest.service_request_tasks.find((service: any) => service.settings_task.id === this.task.id);
            if (service) {
                this.service = service;
            } else {
                this.setService();
            }
        } else {
            this.setService();
        }
    }

    setService() {
        this.service = {
            name: this.task.name,
            price: this.task.price,
            settings_task: {
                id: this.task.id
            },
            sort: this.task.sort,
            data: {
                imageData: [],
                description: '',
            },
            reference_assets: [],

        }
    }

    addReferenceImage(event: any) {
        if (this.service.reference_assets) {
            this.service.reference_assets.push(event);
        } else {
            this.service.reference_assets = [event];
        }
        this.checkIfNeedsToSave();
    }
    removeReferenceImage(id: any) {
        this.service.reference_assets = this.service.reference_assets.filter((image: any) => image.id !== id);
        this.checkIfNeedsToSave();
    }


    validateService(): boolean {
        if (this.service.data.imageData.length === 0) {
            this.service = {
                ...this.service,
                valid: false,
            }
            this.scrollToGrid();
            return false;
        } else {
            this.service = {
                ...this.service,
                valid: true,
            }
            return true;
        }
    }

    isValid(): boolean {
        if (this.service.data.imageData.length === 0) {
            return false;
        } else {
            return true;
        }
    }

    scrollToGrid() {
        this.activeTab = 1;
        const grid = this.document.getElementById('item-removal-grid');
        grid?.scrollIntoView({ behavior: 'smooth', block: 'center' });

        let count = 0;
        const interval = setInterval(() => {
            if (count < 3) {
                this.renderer.addClass(grid, 'border-red');
                setTimeout(() => {
                    this.renderer.removeClass(grid, 'border-red');
                    count++;
                }, 200);
            } else {
                clearInterval(interval);
            }
        }, 400);
    }

    checkIfNeedsToSave() {
        if (JSON.stringify(this.service) !== this.hardCopy) {
            this.changed(true);
        } else {
            this.changed(false);
        }
    }
}
