<div
    class="wrapper"
    [class]="breakpointService.currentBreakpointClass"
    [class.processing]="processing">
    <div class="modal-content">
        <div class="files-wrapper">
            <div
                class="item"
                *ngFor="let image of images">
                <img [src]="image.url" />
                <p>{{ image.name }}</p>
            </div>
        </div>
        <form
            (ngSubmit)="createAnonProject()"
            [formGroup]="projectForm">
            <div class="project-name">
                <input
                    placeholder="Enter the name of your project"
                    formControlName="projectName"
                    type="text"
                    name="projectName"
                    required="true" />
                <mat-error
                    *ngIf="
                        projectForm.controls.projectName.invalid &&
                        projectForm.controls.projectName.touched
                    ">
                    Please enter a project name
                </mat-error>
            </div>
            <div class="email">
                <input
                    placeholder="Enter your email address"
                    formControlName="email"
                    type="email"
                    name="email"
                    required="true" />
                <mat-error
                    *ngIf="
                        projectForm.controls.email.invalid &&
                        projectForm.controls.email.touched
                    ">
                    Please enter a valid email address
                </mat-error>
            </div>
            <button
                class="get-started-button"
                [disabled]="!projectForm.valid">
                Get started
            </button>
        </form>
    </div>
    <div class="modal-processing">
        <h3>Hang tight!</h3>
        <h4>
            We're currently uploading your photos and preparing your project.
        </h4>
        <img
            src="assets/cog.gif"
            loading="lazy"
            class="cog" />
        <p class="small-para-modal">
            This process might take some time. <br />If it feels like the wait
            is longer than expected, please don't hesitate to reach out to us
            through our live chat for assistance.<br />
        </p>
    </div>
    <div
        class="modal-error"
        *ngIf="error">
        <h3>⚠️Oops!</h3>
        <h4>
            @if (errorMessage) {
            {{ errorMessage }}
            } @else { The system encountered an error, please try again or
            contact our team }
        </h4>
    </div>
</div>
