import { BaseLoginProvider, SocialUser } from "@abacritt/angularx-social-login";
import { environment } from "src/app/environments/environment";

declare let AppleID: any;

export class AppleLoginProvider extends BaseLoginProvider {
    private webHook: string = environment.webHook;
    public static readonly PROVIDER_ID: string = "APPLE";

    protected auth2: any;

    constructor(
        private clientId: string,
        private _initOptions: any = {
            scope: "email name",
        }
    ) {
        super();
    }

    public makeid(length: number) {
        var result = "";
        var characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }
        return result;
    }

    public initialize(): Promise<void> {
        return new Promise((resolve, _reject) => {
            try {
                this.loadScript(
                    AppleLoginProvider.PROVIDER_ID,
                    "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js",
                    () => {
                        AppleID.auth.init({
                            clientId: this.clientId,
                            scope: this._initOptions.scope,
                            redirectURI: `${environment.app_url}login`,
                            state: "Initial user authentication request",
                            usePopup: true,
                            nonce: this.makeid(5),
                        });
                        resolve();
                    }
                );
            } catch (e) {
                _reject(e);
            }
        });
    }

    public getLoginStatus(): Promise<SocialUser> {
        return new Promise((resolve, reject) => {
            // @ts-ignore
            resolve(null);
        });
    }

    public async signIn(signInOptions?: any): Promise<SocialUser> {
        try {
            const data = await AppleID.auth.signIn();
            return data;
        } catch (er) {
            // @ts-ignore
            return er;
        }
    }

    public async signOut(revoke?: boolean): Promise<any> {
        return new Promise((resolve, reject) => {
            resolve(true);
        });
    }

    report(error: any) {
        const message = {
            channel: "#app-logs-angular",
            text: "Login error",
            attachments: [
                {
                    author_name: "Google login",
                    color: "danger",
                    title: "Trace",
                    text: error,
                },
            ],
        };
        fetch(this.webHook, {
            method: "POST",
            headers: {
                "Content-type": "application/x-www-form-urlencoded",
                Accept: "application/json, text/plain, */*",
            },
            body: JSON.stringify(message),
        });
    }
}
