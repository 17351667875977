import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { AES, enc } from "crypto-js";
import { UntypedFormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { countries } from "./country-list";
import { ApiService } from '../services/api.service';
import { BreakpointService } from '../services/breakpoint.service';
import { GoogleModifiedLoginProvider } from "../services/google.service";
import { AppleLoginProvider } from "../services/apple.service";
import { SocialAuthService } from "@abacritt/angularx-social-login";
import $ from 'jquery';
import { PixelService } from '../services/pixel.service';
import { BingService } from '../services/bing.service';
import { Location } from '@angular/common';
import { UserService } from '../services/user.service';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    public email: string = "";
    public password: string = "";
    public newPassword: string = "";
    public currentPage: string = "login";
    private secret = "6zLJ5JGCCd";
    public firstName: string = "";
    public lastName: string = "";
    countryCtrl = new UntypedFormControl();
    filteredCountry!: Observable<any[]>;
    countries: any[] = [];
    public resetRequested: boolean = false;
    public googleLogin: boolean = false;
    public appleLogin: boolean = false;
    public invalidCredentials: boolean = false;
    public emailExists: boolean = false;
    public registering: boolean = false;
    public invalidPassword: boolean = false;
    public confirmPassword: string = "";
    public jv: string = "";
    public socialUser: any;

    constructor(
        private router: Router,
        private auth: AuthService,
        private route: ActivatedRoute,
        private api: ApiService,
        public breakpointService: BreakpointService,
        private socialAuthService: SocialAuthService,
        private pixel: PixelService,
        private bing: BingService,
        private location: Location,
        private userService: UserService
    ) {
        this.filteredCountry = this.countryCtrl.valueChanges.pipe(
            startWith(""),
            map((country) =>
                country ? this.filterCountry(country) : this.countries.slice()
            )
        );
        countries.sort(this.dynamicSort("Priority"));
        this.countries = countries;
        this.api.postDirect("ping/code").then((code) => {
            for (const country of countries) {
                if (code === country.code) {
                    this.countryCtrl.setValue(country.name);
                }
            }
            if (this.countryCtrl.value === "") {
                this.countryCtrl.setValue(countries[0].name);
            }
        });
    }

    ngOnInit() {
        this.jv = this.route.snapshot.queryParams['jv']
        if (this.jv) {
            localStorage.setItem('jv', this.jv);
        } else {
            this.jv = localStorage.getItem('jv') || '';
        }

        if (this.route.snapshot.url[0].path === "confirm" || this.route.snapshot.url[0].path === "register") {
            this.currentPage = this.route.snapshot.data['title'];
            if (this.currentPage = "forgot") {
                this.auth.logout();
            }
        }
        if (this.auth.checkLogin()) {
            this.router.navigate(["projects"]);
        }
        if (this.route.snapshot.url[0].path === "register") {
            this.currentPage = "register";
        }
        this.socialAuthService.authState.subscribe(async (user: any) => {
            let email = undefined,
                firstName = null,
                lastName = null;

            if (this.appleLogin) {
                if (user.authorization && user.authorization.id_token) {
                    const decodedToken = JSON.parse(atob(user.authorization.id_token.split(".")[1]));
                    email = decodedToken.email;

                    // Check for given_name and family_name fields
                    firstName = decodedToken.given_name || "User";
                    lastName = decodedToken.family_name || "Apple";
                }
            } else if (this.googleLogin) {
                email = user.email;
                firstName = user.firstName;
                lastName = user.lastName;
            }

            if (email !== undefined) {
                const countryCode = await this.ipLookUp();
                const country = this.countries.filter(
                    (country) => country.code.toLowerCase() === countryCode.toLowerCase()
                );
                const formData = new FormData();
                formData.append("email", email);
                formData.append(
                    "firstName",
                    firstName ? firstName : email.split("@")[0]
                );
                formData.append("lastName", lastName ? lastName : "_");
                formData.append("plainPassword", "plainPassword");
                formData.append("provider", user.provider);
                formData.append("country", country[0].name);
                formData.append("jv_partner", this.jv);

                const affiliateLink = this.route.snapshot.paramMap.get("link");
                const inviteLink = localStorage.getItem("invite");
                const credits = localStorage.getItem("credits");

                if (affiliateLink) {
                    formData.append("affiliateLink", affiliateLink);
                }

                if (inviteLink) {
                    formData.append("inviteLink", inviteLink);
                }

                if (credits) {
                    const original = credits
                        .replace(/xMl3Jk/g, "+")
                        .replace(/Por21Ld/g, "/")
                        .replace(/Ml32/g, "=");
                    const formValues = JSON.parse(
                        AES.decrypt(original, this.secret).toString(enc.Utf8)
                    );
                    formData.append("credits", JSON.stringify(formValues));
                }

                const res = await this.auth.loginSocialUser(
                    formData,
                    this.pixel,
                    this.bing,
                );
                let localUser: any = localStorage.getItem("user");
                if (localUser && (!localUser.avatar || !localUser.avatar.url) && !this.appleLogin && this.socialUser?.photoUrl) {
                    this.updateAvatar(this.socialUser.photoUrl);
                }
                let emailParam: string;
                if (res) {
                    emailParam = res.split("=")[1];
                } else {
                    emailParam = "";
                }
                if (emailParam) {
                    this.router.navigate(["projects"], {
                        queryParams: { email: emailParam },
                    });
                } else {
                    this.router.navigate(["projects"]);
                }
            }

        });

        const creditsParam = this.route.snapshot.paramMap.get("credits");
        if (creditsParam) {
            const currentCredits = localStorage.getItem("credits");

            if (!currentCredits || currentCredits !== creditsParam) {
                localStorage.setItem("credits", creditsParam);
            }
        }
        const inviteParam = this.route.snapshot.paramMap.get("invite");
        if (inviteParam) {
            const currentInvite = localStorage.getItem("invite");

            if (!currentInvite || currentInvite !== inviteParam) {
                localStorage.setItem("invite", inviteParam);
            }
        }
        this.location.replaceState(this.router.url.split("?")[0]);
    }

    async resetPassword() {
        let form: any = {
            value: {},
        };
        form.value.email = this.route.snapshot.paramMap.get("email");
        form.value.token = this.route.snapshot.paramMap.get("token");
        form.value.password = this.newPassword;
        form.value.confirmPassword = this.confirmPassword;

        this.api.patch("user/resetPassword", form.value).then(
            () => {
                this.currentPage = "login";
            },
        );
    }

    validateNewPass(): boolean {
        return this.newPassword.length > 6 && this.newPassword === this.confirmPassword;
    }

    async ipLookUp() {
        try {
            const response = await $.ajax("http://ip-api.com/json");
            return response.countryCode;
        } catch {
            return "US";
        }
    }

    private filterCountry(value: string): any[] {
        const filterValue = value.toLowerCase();

        return this.countries.filter(
            (country) => country.name.toLowerCase().indexOf(filterValue) === 0
        );
    }

    dynamicSort = (property: string) => {
        let sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return (a: any, b: any) => {
            let result =
                a[property] < b[property] || b[property] === undefined
                    ? -1
                    : a[property] > b[property]
                        ? 1
                        : 0;
            return result * sortOrder;
        };
    };

    async signIn() {
        let form = {
            value: {
                username: this.email,
                password: this.password
            }
        }
        if (this.currentPage === 'login') {
            await this.auth.login(form.value).then(
                async (res) => {
                    let email: string;
                    if (res) {
                        email = res.split("=")[1];
                    } else {
                        email = "";
                    }
                    if (email) {
                        this.router.navigate(["projects"], {
                            queryParams: { email: email },
                        });
                    } else {
                        this.router.navigate(["projects"]);
                    }
                },
                (res) => {
                    if (res.error) {
                        this.invalidCredentials = true;
                        this.password = "";
                        setTimeout(() => {
                            this.invalidCredentials = false;
                        }, 3000);
                    }
                }
            );
        } else {
            this.register();
        }

    }

    loginWithGoogle(): void {
        this.googleLogin = true;
        this.appleLogin = false;
        this.socialAuthService
            .signIn(GoogleModifiedLoginProvider.PROVIDER_ID)
            .then((socialUser: any) => {
                this.socialUser = socialUser;
                this.googleLogin = false;
            })
            .catch(() => {
                this.googleLogin = false;
            });
    }

    updateAvatar(avatarUrl: string) {
        const formData = new FormData();
        formData.append('avatar_url', avatarUrl);
        this.api.post('user/set-avatar', formData).then((res) => {
            localStorage.setItem('user', JSON.stringify(res));
            this.userService.updateUser(res);
        });
    }

    loginWithApple(): void {
        this.appleLogin = true;
        this.googleLogin = false;
        this.socialAuthService
            .signIn(AppleLoginProvider.PROVIDER_ID)
            .then(() => {
                this.appleLogin = false;
            })
            .catch(() => {
                this.appleLogin = false;
            });
    }

    toggleRegister() {
        if (this.currentPage === "login") {
            this.currentPage = "register";
        } else {
            this.currentPage = "login";
        }
    }

    togglePassReset() {
        this.currentPage = "reset";
    }

    register() {
        if (this.newPassword.length < 6) {
            this.invalidPassword = true;
            setTimeout(() => {
                this.invalidPassword = false;
            }, 3000);
            return;
        }

        this.registering = true;
        const affiliateLink = this.route.snapshot.paramMap.get("link");
        const inviteLink = localStorage.getItem("invite");
        const credits = localStorage.getItem("credits");

        let form: any = {
            value: {},
        };

        form.value.email = this.email;
        form.value.plainPassword = this.newPassword;
        form.value.firstName = this.firstName;
        form.value.lastName = this.lastName;
        form.value.country = this.countryCtrl.value;
        form.value.news = true;
        form.value.jv_partner = this.jv;

        if (affiliateLink) {
            form.value.affiliateLink = affiliateLink;
        }

        if (inviteLink) {
            form.value.inviteLink = inviteLink;
        }

        if (credits) {
            const original = credits
                .replace(/xMl3Jk/g, "+")
                .replace(/Por21Ld/g, "/")
                .replace(/Ml32/g, "=");
            const formValues = JSON.parse(
                AES.decrypt(original, this.secret).toString(enc.Utf8)
            );
            form.value.credits = formValues;
        }

        this.auth.register(form.value)
            .then(() => {
                this.pixel.trackEvent("CompleteRegistration");
                this.bing.trackEvent("subscribe");

                this.auth.login({
                    username: form.value.email,
                    password: form.value.plainPassword,
                }).then((route) => {
                    window.location.href = route || "projects";
                })
            }).catch((err) => {
                if (err.error.message === "Email address already exists") {
                    this.emailExists = true;
                    this.registering = false;
                    setTimeout(() => {
                        this.emailExists = false;
                    }, 3000);
                }
            });

    }

    validateEmail() {
        const re = /\S+@\S+\.\S+/;
        return re.test(this.email);
    }

    registerDisabled() {
        return (
            !this.validateEmail() ||
            !this.newPassword ||
            !this.firstName ||
            !this.lastName ||
            !this.countryCtrl.value ||
            this.registering
        );
    }

    loginDisabled() {
        return !this.validateEmail() || !this.password;
    }

    async requestReset() {
        this.api.patch("user/forgotPassword", {
            email: this.email
        }).then(
            () => {
                this.resetRequested = true;
            },
            (err) => {
            }
        );
    }

    getYear(): number {
        return new Date().getFullYear();
    }
}
