const hookInputSetter = (target, key) => {
    const orig = Object.getOwnPropertyDescriptor(target, key);
    Object.defineProperty(target, key, {
        set(value) {
            this.dispatchEvent(new Event("coherevalueupdate"));
            if (orig && orig.set) {
                orig.set.call(this, value);
            }
        },
    });
};

let hooked = false;
const hookSegment = () => {
    if (window.analytics && !hooked) {
        hooked = true;
        const origIdentify = window.analytics.identify.bind(window.analytics);
        window.analytics.identify = (...args) => {
            const userId = args[0];
            // Ignore anonymous users
            if (typeof userId === "string") {
                const traits = args[1] && typeof args[1] === "object"
                    ? args[1]
                    : {};
                // Rename name to displayName
                const { name: displayName, ...rest } = traits;
                window.Cohere.identify(userId, { displayName, ...rest });
            }
            origIdentify(...args);
        };
    }
};

const disableLoad = typeof window === "undefined" ||
    !window.document ||
    window.document.documentMode;
if (!disableLoad) {
    hookInputSetter(HTMLInputElement.prototype, "value");
    hookInputSetter(HTMLInputElement.prototype, "checked");
    hookInputSetter(HTMLTextAreaElement.prototype, "value");
    hookInputSetter(HTMLSelectElement.prototype, "value");
    hookInputSetter(HTMLSelectElement.prototype, "selectedIndex");
}
const bridgedMethods = [
    "init",
    "identify",
    "logout",
    "stop",
    "showCode",
    "getSessionUrl",
    "makeCall",
    "addCallStatusListener",
    "removeCallStatusListener",
    "widget",
    "openChatWindow",
    "addSessionUrlListener",
    "removeSessionUrlListener",
    "triggerOverlayWorkflow",
    "startChat",
    "endChat",
    "closeChatWindow",
];
const noop = () => { };
const noopModule = {
    init: noop,
    identify: noop,
    logout: noop,
    stop: noop,
    showCode: noop,
    getSessionUrl: noop,
    makeCall: noop,
    addCallStatusListener: noop,
    removeCallStatusListener: noop,
    widget: noop,
    openChatWindow: noop,
    addSessionUrlListener: noop,
    removeSessionUrlListener: noop,
    triggerOverlayWorkflow: noop,
    startChat: noop,
    endChat: noop,
    closeChatWindow: noop,
};
// Create cohere or pass in previous args to init/initialize
//  if script is not created
let Cohere = disableLoad
    ? noopModule
    : (window.Cohere = []);
if (!disableLoad) {
    Cohere.invoked = true;
    Cohere.snippet = "0.6";
    Cohere.valhook = true;
    Cohere.methods = bridgedMethods;
    Cohere.hookSegment = hookSegment;
    Cohere.methods.forEach((method) => {
        Cohere[method] = (...args) => {
            if (method === "init") {
                const options = args[1];
                if (options === null || options === void 0 ? void 0 : options.segmentIntegration) {
                    hookSegment();
                }
            }
            args.unshift(method);
            Cohere.push(args);
        };
    });
    // Create an async script element based on your key
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = "https://static.cohere.so/main.js";
    // Insert our script before the first script element
    const first = document.getElementsByTagName("script")[0];
    if (first && first.parentNode) {
        first.parentNode.insertBefore(script, first);
    }
}
const exportedModule = Cohere;

export default exportedModule;
