import { Component, Inject, ComponentRef, ViewChild, ViewContainerRef, EventEmitter, Output } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { ApiService } from 'src/app/services/api.service';
import { firstValueFrom } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import tiers from '../../services-modal/service-info/fp-info/tiers';
import { Fp2GuidelinesComponent } from '../../fp2-guidelines/fp2-guidelines.component';


@Component({
    selector: 'app-service-info',
    templateUrl: './service-info.component.html',
    styleUrls: ['./service-info.component.scss']
})
export class ServiceInfoComponent {
    public currentComponent: any;
    public selectedServiceType: any;
    public serviceRequest: any;
    public currentAnnotations: any;
    public furnitureStyles: any;
    public roomTypes: any;
    public handleAnnotationsChange: (annotations: any) => void = () => { };
    @ViewChild('serviceInfoAnchor', { read: ViewContainerRef }) serviceInfoAnchor: ViewContainerRef | undefined;
    public componentRef: ComponentRef<any> | undefined;
    public addingService: any;
    public project: any;
    public annotationsOverlayVisible: boolean = true;
    public irData: any = [];
    public savingService: boolean = false;
    public serviceTypes: any = [];
    public imageHeight: string = '45rem';
    public missingInfo: boolean = false;
    public serviceInvalid: boolean = false;
    @Output() updatedRequest: EventEmitter<any> = new EventEmitter<any>();
    @Output() vrAdded: EventEmitter<any> = new EventEmitter<any>();
    @Output() vrRemoved: EventEmitter<any> = new EventEmitter<any>();
    @Output() fp2Added: EventEmitter<any> = new EventEmitter<any>();
    @Output() fp2Removed: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        public bottomSheetRef: MatBottomSheetRef<ServiceInfoComponent>,
        public breakpointService: BreakpointService,
        public api: ApiService,
        public dialog: MatDialog,
    ) {
        this.selectedServiceType = data.selectedServiceType;
        this.currentComponent = data.currentComponent;
        this.serviceRequest = data.serviceRequest;
        this.currentAnnotations = data.annotations;
        this.serviceInfoAnchor = data.serviceInfoAnchor;
        this.furnitureStyles = data.furnitureStyles;
        this.roomTypes = data.roomTypes;
        this.handleAnnotationsChange = data.handleAnnotationsChange;
        this.project = data.project;
        this.serviceTypes = data.serviceTypes;
        if (this.selectedServiceType.name === "Item Removal") {
            let imageData = this.serviceRequest.service_request_tasks.find((task: any) => task.settings_task?.id === this.selectedServiceType.id)?.data.imageData;
            if (imageData?.length > 0) {
                this.irData = imageData;
            }
        }
    }
    ngAfterViewInit() {
        this.initiateService(this.currentComponent, this.selectedServiceType);
        this.bottomSheetRef.backdropClick().subscribe(async () => {
            await this.dismissUnsaved();
        });
    }

    initiateService(component: any, service: any) {
        if (component !== undefined && this.serviceInfoAnchor !== undefined) {
            this.componentRef = this.serviceInfoAnchor.createComponent(component);
            if (service.name === "Virtual Staging" || service.name === "360 Virtual Staging" || service.name === "Virtual Renovation" || service.name === "Floor Plan" || service.name === "3D Floor Plan") {
                this.componentRef.instance.allStyles = this.furnitureStyles;
                this.componentRef.instance.roomTypes = this.roomTypes;
            }
            this.componentRef.instance.serviceInfo.subscribe(async (serviceInfo: any) => {
                await this.addService(serviceInfo);
            });
            this.componentRef.instance.serviceRequest = this.serviceRequest;
            this.componentRef.instance.task = this.selectedServiceType;
            this.componentRef.instance.projectGuid = this.data.projectGuid;
            this.componentRef.instance.annotations = this.currentAnnotations;
            this.componentRef.instance.annotationsChange?.subscribe((annotations: any) => {
                this.handleAnnotationsChange(annotations);
            });
            this.componentRef.instance.changed = (dirty: boolean) => {
                this.selectedServiceType.dirty = dirty;
            }
            this.componentRef.instance.isPp = true;
        }
    }

    async dismissUnsaved() {
        if (this.selectedServiceType.dirty && this.componentRef?.instance.isValid()) {
            await this.saveOrAdd();
            this.bottomSheetRef.dismiss();
            this.serviceInvalid = false;
        } else if (this.selectedServiceType.dirty && !this.componentRef?.instance.isValid()) {
            await this.completeOrCancel();
        } else {
            this.bottomSheetRef.dismiss();
            this.serviceInvalid = false;
        }
    }

    async completeOrCancel() {
        const dialogData = {
            icon: 'warning',
            message: `Do you want to complete this service?`,
            moreInfo: `We've noticed you've entered some information for this service, but haven't completed all the required fields. To include this service in your project, please make sure to complete all required fields.`,
            confirm: { title: `I want to complete it` },
            reject: { title: `No, I don't want to complete it` }
        };

        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: dialogData,
            disableClose: true,
            maxWidth: 'unset',
            panelClass: 'confirm-dialog'
        });

        await firstValueFrom(confirmDialogRef.afterClosed()).then(async (result: any) => {
            if (result) {
                this.serviceInvalid = true;
            } else {
                this.serviceInvalid = false;
                this.bottomSheetRef.dismiss();
            }
        });
    }

    async saveOrAdd(): Promise<void> {
        const dialogData = {
            icon: 'warning',
            message: `Do you want to save your work first?`,
            moreInfo: `We noticed you've entered some information for this service but haven't saved it yet. To include this service in your project, please make sure to save your changes.`,
            confirm: { title: `Yes, save my work` },
            reject: { title: `No, don't save my work` }
        };

        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: dialogData,
            disableClose: true,
            maxWidth: 'unset',
            panelClass: 'save-dialog'
        });

        await firstValueFrom(confirmDialogRef.afterClosed()).then(async (result: any) => {
            if (result) {
                if (this.selectedServiceType.added) {
                    await this.saveService();
                } else {
                    if (this.componentRef && this.componentRef.instance) {
                        if (this.componentRef.instance.validateService()) {
                            this.getServiceInfo();
                        } else {
                            this.missingInfo = true;
                        }
                    }
                }
            } else {
                if (this.selectedServiceType.added && this.componentRef) {
                    this.componentRef.instance.service = JSON.parse(this.componentRef.instance.hardCopy);
                    this.selectedServiceType.dirty = false;
                    // update service request
                    this.serviceRequest.service_request_tasks = this.serviceRequest.service_request_tasks.map((service: any) => {
                        if (service.settings_task.id === this.selectedServiceType.id) {
                            service.data = this.componentRef?.instance.service.data;
                            service.reference_assets = this.componentRef?.instance.service.reference_assets;
                        }
                        return service;
                    });
                } else {
                    this.selectedServiceType.dirty = false;
                }
            }
            this.updatedRequest.emit(this.serviceRequest);
        });

    }


    async addRemoveService() {
        if (this.selectedServiceType.name === "Virtual Renovation" && this.serviceRequest.service_request_tasks.length > 0 && !this.selectedServiceType.added) {
            let proceed = await this.virtualRenovationCheck();
            if (!proceed) {
                return;
            }
            this.removeAllServices();

        }
        if (this.selectedServiceType.name === "3D Floor Plan" && this.serviceRequest.service_request_tasks.length > 0 && !this.selectedServiceType.added) {
            let proceed = await this.fp2Check();
            if (!proceed) {
                return;
            }
            this.removeAllServices();
        }
        if (this.selectedServiceType.name === "3D Floor Plan" && this.serviceRequest.service_request_tasks.length === 0 && !this.selectedServiceType.added && this.componentRef?.instance.validateService()) {
            let proceed = await this.fpGuidelinesCheck();
            if (!proceed) {
                return;
            }
        }
        if (this.selectedServiceType.added) {
            this.removeService();
        } else {
            this.getServiceInfo();
        }
    }

    async fpGuidelinesCheck(): Promise<boolean> {
        let fpDialog = this.dialog.open(Fp2GuidelinesComponent, {
            maxWidth: '100vw',
            panelClass: 'fp-guidelines-dialog',
            data: {
                price: this.selectedServiceType.price
            }
        });

        const result = await firstValueFrom(fpDialog.afterClosed());

        return !!result;
    }

    async virtualRenovationCheck(): Promise<boolean> {
        const dialogData = {
            icon: 'warning',
            message: `If you add Virtual Renovation, all other services will be removed and your input will be lost. Do you want to continue?`,
            confirm: { title: `Yes, continue` },
            reject: { title: `No, cancel` }
        };

        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: dialogData,
        });

        const confirmed = await firstValueFrom(confirmDialogRef.afterClosed());

        if (confirmed) {
            return true;
        } else {
            return false;
        }
    }

    async fp2Check(): Promise<boolean> {
        const dialogData = {
            icon: 'warning',
            message: `If you add 3D Floor Plan, all other services will be removed and your input will be lost. Do you want to continue?`,
            confirm: { title: `Yes, continue` },
            reject: { title: `No, cancel` }
        };

        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: dialogData,
        });

        const confirmed = await firstValueFrom(confirmDialogRef.afterClosed());

        if (confirmed) {
            return true;
        } else {
            return false;
        }
    }

    removeAllServices() {
        for (let task of this.serviceRequest.service_request_tasks) {
            this.api.delete(
                `project/${this.project.guid}/serviceRequest/${this.serviceRequest.id}/task/${task.id}`
            ).then((res) => {
                console.log(res)
            }).catch((err) => {
                console.log(err)
            })
        }
        this.serviceTypes = this.serviceTypes.map((service: any) => {
            service.added = false;
            return service;
        });
        this.serviceRequest.service_request_tasks = [];
    }

    removeService() {
        let task = this.serviceRequest.service_request_tasks.find((task: any) => task.settings_task?.id === this.selectedServiceType.id);

        if (task) {
            this.serviceRequest.service_request_tasks = this.serviceRequest.service_request_tasks.filter((service: any) => service.id !== task.id);
            this.api.delete(
                `project/${this.project.guid}/serviceRequest/${this.serviceRequest.id}/task/${task.id}`
            )

            if (task.name === "3D Floor Plan") {
                this.serviceRequest.is_floor_plan = false;
            }
            if (this.componentRef && this.componentRef.instance) {
                this.componentRef.instance.serviceRequest = this.serviceRequest;
                this.componentRef.instance.setService();
            }
            this.selectedServiceType.added = false;
            this.selectedServiceType.dirty = false;
            this.currentAnnotations = [];
            this.annotationsOverlayVisible = true;
            if (this.selectedServiceType.name === "Item Removal") {
                this.irData = [];
            }
            this.updatedRequest.emit(this.serviceRequest);
            if (this.selectedServiceType.name === "Virtual Renovation") {
                this.vrRemoved.emit();
            }
            if (this.selectedServiceType.name === "3D Floor Plan") {
                this.fp2Removed.emit();
            }
        }

    }

    getServiceInfo() {
        if (this.componentRef && this.componentRef.instance) {
            this.componentRef.instance.triggerServiceInfoEvent();
        }
    }

    async saveService() {
        this.savingService = true;
        const serviceToSave = this.serviceRequest.service_request_tasks.find((task: any) => task.settings_task?.id === this.selectedServiceType.id);
        if (serviceToSave.name !== "Item Removal") {
            serviceToSave.data.imageData = this.currentAnnotations;
        } else {
            serviceToSave.data.imageData = this.irData;
        }
        if (serviceToSave.name === "Floor Plan") {
            let tier = tiers.find((tier: any) => tier.name === serviceToSave.data.tier);
            serviceToSave.price = tier.price;
        }
        if (serviceToSave.name === "Virtual Renovation") {
            if (serviceToSave.extra) {
                serviceToSave.price = this.selectedServiceType.price + this.selectedServiceType.extra_price;
            } else {
                serviceToSave.price = this.selectedServiceType.price;
            }
        }
        await this.api.put(
            `project/${this.project.guid}/serviceRequest/${this.serviceRequest.id}/task/${serviceToSave.id}`,
            {
                name: serviceToSave.name,
                price: serviceToSave.price,
                settingsTask: serviceToSave.settings_task.id,
                sort: serviceToSave.sort,
                data: JSON.stringify(serviceToSave.data),
                isSpecial: serviceToSave.is_special,
                assets: serviceToSave.reference_assets,
                extra: serviceToSave.extra,
            }).then(() => {
                this.selectedServiceType.dirty = false;
                this.savingService = false;
                this.updatedRequest.emit(this.serviceRequest);
                if (this.componentRef) {
                    this.componentRef.instance.hardCopy = JSON.stringify(serviceToSave);
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    async addService(serviceInfo: any): Promise<void> {
        this.addingService = true;
        if (serviceInfo) {
            let updatedTypes = this.serviceTypes.map((service: any) => {
                if (service.id === serviceInfo.settings_task.id) {
                    service.added = true;
                }
                return service;
            });
            this.serviceTypes = [...updatedTypes];
            if (serviceInfo.name === "Virtual Staging" || serviceInfo.name === "360 Virtual Staging" || serviceInfo.name === "Virtual Renovation") {
                localStorage.setItem(`style-${this.project.guid}`, `${serviceInfo.data.furnitureStyles}`);
            }
        }
        if (serviceInfo.name === "Virtual Renovation") {
            if (serviceInfo.extra) {
                serviceInfo.price = this.selectedServiceType.price + this.selectedServiceType.extra_price;
            } else {
                serviceInfo.price = this.selectedServiceType.price;
            }
        }
        await this.api.post(
            `project/${this.project.guid}/serviceRequest/${this.serviceRequest.id}/task`,
            {
                name: serviceInfo.name,
                price: serviceInfo.price,
                settingsTask: serviceInfo.settings_task.id,
                sort: serviceInfo.sort,
                data: JSON.stringify(serviceInfo.data),
                isSpecial: serviceInfo.is_special,
                assets: serviceInfo.reference_assets,
                extra: serviceInfo.extra,
            }).then((res) => {
                res.data = JSON.parse(res.data);
                this.serviceRequest.service_request_tasks.push(res);
                if (serviceInfo.name === "3D Floor Plan") {
                    this.serviceRequest.is_floor_plan = true;
                }
                this.addingService = false;
                this.selectedServiceType.dirty = false;
                if (this.componentRef) {
                    this.componentRef.instance.hardCopy = JSON.stringify(res);
                    this.componentRef.instance.service = res;
                }
                this.updatedRequest.emit(this.serviceRequest);
                if (serviceInfo.name === "Virtual Renovation") {
                    this.vrAdded.emit();
                }
                if (serviceInfo.name === "3D Floor Plan") {
                    this.fp2Added.emit();
                }
                this.bottomSheetRef.dismiss();
            }).catch((err) => {
                console.log(err)
                this.addingService = false;
            })
        this.serviceInvalid = false;
    }

    setImageData(imageData: any) {
        this.irData = imageData;
        if (this.irData.length === 0) {
            this.removeService();
        }
        if (this.componentRef && this.componentRef.instance) {
            this.componentRef.instance.service.data.imageData = this.irData;
            this.componentRef.instance.checkIfNeedsToSave();
        }
    }
}
