<div class="wrapper">
    <h4>Enter your phone number</h4>
    <div
        class="phone-input"
        [class.invalid-format]="phoneInvalid">
        <input
            (input)="updatePhone($event)"
            [value]="phoneNumber"
            appIntlTelInput
            [appIntlTelInput]="phoneNumber"
            type="tel"
            id="phone-number" />
    </div>
    <div class="buttons">
        <button
            mat-button
            (click)="savePhone()"
            [disabled]="updatingPhone"
            class="primary">
            <p>Save number</p>
            <img
                src="/assets/rolling.gif"
                *ngIf="updatingPhone" />
        </button>
        <button
            mat-button
            (click)="closeDialog()"
            class="transparent">
            <p>Close</p>
        </button>
    </div>
</div>
