<div
    class="wrapper"
    [class]="breakpointService.currentBreakpointClass">
    <div class="title">
        <img
            src="assets/canceled.svg"
            alt="" />
        <h1>Your project has been canceled.</h1>
        <p>Sorry, we had to cancel your project.</p>
    </div>
    <div class="more-info">
        <div class="canceled">
            <mat-icon svgIcon="warning" />
            <h4>Cancellation reason:</h4>
            <p *ngIf="!project.message">Not specified</p>
        </div>
        <div
            *ngIf="project.message"
            class="message">
            <p>{{ project.message }}</p>
        </div>
    </div>
</div>
