import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EtaSettingsService {
    private etaSettings = new BehaviorSubject<any>(null);
    private limitPrice = new BehaviorSubject<any>(null);
    private alwaysSameEta = new BehaviorSubject<any>(null);
    private rushSettings = new BehaviorSubject<any>(null);

    getEtaSettings = this.etaSettings.asObservable();
    getLimitPrice = this.limitPrice.asObservable();
    getAlwaysSameEta = this.alwaysSameEta.asObservable();
    getRushSettings = this.rushSettings.asObservable();

    setEtaSettings(data: any) {
        this.etaSettings.next(data);
    }

    setLimitPrice(data: any) {
        this.limitPrice.next(data);
    }

    setAlwaysSameEta(data: any) {
        this.alwaysSameEta.next(data);
    }

    setRushSettings(data: any) {
        this.rushSettings.next(data);
    }
}
