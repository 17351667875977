import { Component } from '@angular/core';
import { BreakpointService } from '../services/breakpoint.service';

@Component({
    selector: 'app-overdue',
    standalone: true,
    imports: [

    ],
    templateUrl: './overdue.component.html',
    styleUrl: './overdue.component.scss'
})
export class OverdueComponent {
    constructor(public breakpointService: BreakpointService) { }

}
