<div
    class="wrapper"
    [class]="breakpointService.currentBreakpointClass">
    <div class="overdue">
        <div class="text">
            <div class="text-wrapper">
                <h1>Settle Your Overdue Invoices</h1>
                <p>
                    We are currently unable to process any new projects for you
                    due to overdue invoices. Our customer support team has
                    previously reached out to you regarding this matter. Please
                    check your email for our communication and confirm your
                    commitment to settling the outstanding balance.
                </p>
                <p>
                    If you have not received our emails, please contact us at
                    <b>hello&#64;virtualstaging.com</b> as soon as possible.
                    <br /><br />
                    Thank you for your understanding and cooperation.
                </p>
            </div>
        </div>
        <div class="image">
            <img
                src="assets/overdue.png"
                alt="Payments overdue" />
        </div>
    </div>
</div>
