import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-fp2-guidelines',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatIconModule
    ],
    templateUrl: './fp2-guidelines.component.html',
    styleUrl: './fp2-guidelines.component.scss'
})
export class Fp2GuidelinesComponent {
    public disclaimerUnderstood = false;

    constructor(
        private dialogRef: MatDialogRef<Fp2GuidelinesComponent>,
        public breakpointService: BreakpointService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    confirm() {
        this.dialogRef.close(true);
    }

    reject() {
        this.dialogRef.close(false);
    }
}
