import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ItemRemovalComponent } from "./item-removal.component";

@NgModule({
    imports: [CommonModule],
    declarations: [ItemRemovalComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [ItemRemovalComponent],
})
export class ItemRemovalModule { }
