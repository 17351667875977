<div
    class="wrapper"
    [class]="breakpointService.currentBreakpointClass">
    <div class="left">
        <div class="logo">
            <button><mat-icon svgIcon="logo" /></button>
        </div>
        <div class="trustpilot">
            <div class="testimonial-container">
                <div
                    class="trustpilot-widget"
                    data-locale="en-US"
                    data-template-id="539ad0ffdec7e10e686debd7"
                    data-businessunit-id="5e2712ad7569d3000121d432"
                    data-style-height="350px"
                    data-style-width="100%"
                    data-theme="light"
                    data-stars="1,2,3,4,5"
                    data-review-languages="en">
                    <a
                        href="https://www.trustpilot.com/review/vs24.com"
                        target="_blank"
                        rel="noopener"
                        >Trustpilot</a
                    >
                </div>
            </div>
        </div>
        <p>{{ getYear() }} © VS24, Inc</p>
    </div>
    <div class="right">
        <div class="form">
            <h1 *ngIf="currentPage !== 'reset' && currentPage !== 'forgot'">
                {{
                    currentPage === "login"
                        ? "Sign in to your account"
                        : "Register your account"
                }}
            </h1>
            <button
                class="google social-sign-in"
                (click)="loginWithGoogle()"
                *ngIf="currentPage !== 'reset' && currentPage !== 'forgot'">
                <mat-icon svgIcon="google" />{{
                    currentPage === "login" ? "Sign in" : "Sign up"
                }}
                with Google
            </button>
            <button
                class="apple social-sign-in"
                (click)="loginWithApple()"
                *ngIf="currentPage !== 'reset' && currentPage !== 'forgot'">
                <mat-icon svgIcon="apple" />{{
                    currentPage === "login" ? "Sign in" : "Sign up"
                }}
                with Apple
            </button>
            <p *ngIf="currentPage !== 'reset' && currentPage !== 'forgot'">
                Or, {{ currentPage === "login" ? "sign in" : "sign up" }} with
                your email
            </p>
            <h1 *ngIf="currentPage === 'reset' || currentPage === 'forgot'">
                Reset your password
            </h1>
            <p *ngIf="currentPage === 'reset'">
                Enter your email address and we'll send you a link to reset your
                password
            </p>
            <form [class.invalid]="invalidCredentials">
                <div class="name">
                    <input
                        *ngIf="currentPage === 'register'"
                        type="text"
                        placeholder="First name*"
                        [(ngModel)]="firstName"
                        name="first-name"
                        autocomplete="first-name" />
                    <input
                        *ngIf="currentPage === 'register'"
                        type="text"
                        placeholder="Last name*"
                        [(ngModel)]="lastName"
                        name="last-name"
                        autocomplete="last-name" />
                </div>
                <input
                    *ngIf="currentPage !== 'forgot'"
                    type="text"
                    placeholder="Email*"
                    [class.red-outline]="emailExists"
                    [(ngModel)]="email"
                    name="email"
                    autocomplete="email" />
                <input
                    *ngIf="currentPage === 'login'"
                    type="password"
                    placeholder="Password*"
                    [(ngModel)]="password"
                    name="password"
                    autocomplete="current-password" />
                <input
                    *ngIf="
                        currentPage === 'register' || currentPage === 'forgot'
                    "
                    type="password"
                    [class.red-outline]="invalidPassword"
                    placeholder="Password*"
                    [(ngModel)]="newPassword"
                    pattern=".{6,}"
                    name="new-password"
                    autocomplete="new-password" />
                <input
                    *ngIf="currentPage === 'forgot'"
                    type="password"
                    placeholder="Confirm password*"
                    [(ngModel)]="confirmPassword"
                    pattern=".{6,}"
                    name="confirm-password"
                    autocomplete="confirm-password" />
                <mat-form-field
                    class="country-selector"
                    *ngIf="currentPage === 'register'">
                    <input
                        matInput
                        name="country"
                        [matAutocomplete]="auto"
                        [formControl]="countryCtrl"
                        required
                        placeholder="Country*" />
                    <mat-autocomplete
                        #auto="matAutocomplete"
                        class="country-selector-autocomplete"
                        autoActiveFirstOption>
                        <mat-option
                            *ngFor="let country of filteredCountry | async"
                            [value]="country.name">
                            {{ country.name }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <button
                    class="sign-in"
                    [class.exists]="emailExists"
                    [class.invalid-pass]="invalidPassword"
                    *ngIf="currentPage !== 'reset' && currentPage !== 'forgot'"
                    [disabled]="
                        currentPage === 'register'
                            ? registerDisabled()
                            : loginDisabled()
                    "
                    (click)="signIn()">
                    {{ currentPage === "login" ? "Sign in" : "Sign up" }}
                    <img
                        src="/assets/rolling.gif"
                        alt="loading"
                        *ngIf="registering" />
                </button>
                <button
                    class="sign-in"
                    *ngIf="currentPage === 'reset'"
                    [disabled]="!validateEmail() || resetRequested"
                    (click)="requestReset()">
                    {{ resetRequested ? "Email sent!" : "Request" }}
                </button>
            </form>
            <button
                class="forgot-password"
                *ngIf="currentPage === 'login'"
                (click)="togglePassReset()">
                Forgot password?
            </button>
            <button
                class="reset-password"
                *ngIf="currentPage === 'forgot'"
                [disabled]="!validateNewPass()"
                (click)="resetPassword()">
                Reset password
            </button>
            <p class="register">
                {{
                    currentPage === "login"
                        ? "Don't have an account?"
                        : "Already registered?"
                }}<button
                    (click)="toggleRegister()"
                    href="#">
                    {{ currentPage === "login" ? "Register" : "Sign in" }}
                </button>
            </p>
        </div>
    </div>
</div>
