import { Component, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import { environment } from 'src/app/environments/environment';
import { BreakpointService } from 'src/app/services/breakpoint.service';

@Component({
    selector: 'app-twilight-info',
    templateUrl: './twilight-info.component.html',
    styleUrls: ['./twilight-info.component.scss'],
})
export class TwilightInfoComponent {
    public activeTab: number = 1;
    public comments: string = '';
    public serviceRequest: any;
    public service: any;
    public projectGuid: string = '';
    public task: any;
    public goldenHourStyles: any;
    public changed: (dirty: boolean) => void = () => { };
    public hardCopy: any;
    public isPp: boolean = false;
    public expanded: any = {
        comments: false,
        goldenHour: false,
    };

    @Output() serviceInfo = new EventEmitter<any>();

    public triggerServiceInfoEvent(): void {
        this.validateService();
        if (this.service.valid) {
            this.serviceInfo.emit(this.service);
            this.hardCopy = JSON.stringify(this.service);
        }
    }

    constructor(
        private api: ApiService,
        public breakpointService: BreakpointService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.initiateService();
        this.api
            .get('user_settings', { type: 'goldenHour', active: '1' })
            .then((res) => {
                this.goldenHourStyles = res;
            });
        this.hardCopy = JSON.stringify(this.service);
    }

    initiateService() {
        if (this.serviceRequest.service_request_tasks?.length > 0) {
            let service = this.serviceRequest.service_request_tasks.find(
                (service: any) => service.settings_task.id === this.task.id
            );
            if (service) {
                this.service = service;
            } else {
                this.setService();
            }
        } else {
            this.setService();
        }
    }

    setService() {
        this.service = {
            name: this.task.name,
            price: this.task.price,
            settings_task: {
                id: this.task.id,
            },
            sort: this.task.sort,
            data: {
                imageData: [],
                description: '',
                goldenHour: '',
            },
            reference_assets: [],
        };
    }

    addReferenceImage(event: any) {
        if (this.service.reference_assets) {
            this.service.reference_assets.push(event);
        } else {
            this.service.reference_assets = [event];
        }
        this.checkIfNeedsToSave();
    }
    removeReferenceImage(id: any) {
        this.service.reference_assets = this.service.reference_assets.filter(
            (image: any) => image.id !== id
        );
        this.checkIfNeedsToSave();
    }

    getStyleThumbnail(style: any) {
        let src = environment.cdn + style.image_collection[0].image_name;
        return src;
    }

    setStyle(styleId: number) {
        this.service.data.goldenHour = styleId;
        this.checkIfNeedsToSave();
    }

    validateService(): boolean {
        if (this.service.data.goldenHour !== '') {
            this.service = {
                ...this.service,
                valid: true,
            };
            return true;
        } else {
            this.service = {
                ...this.service,
                valid: false,
            };
            this.activeTab = 1;
            this.cdr.detectChanges();
            setTimeout(() => {
                let invalidElement = document.querySelector('.invalid');
                if (invalidElement) {
                    invalidElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }
            });
            return false;
        }
    }

    isValid(): boolean {
        if (this.service.data.goldenHour !== '') {
            return true;
        } else {
            return false;
        }
    }

    checkIfNeedsToSave(): void {
        if (JSON.stringify(this.service) !== this.hardCopy) {
            this.changed(true);
        } else {
            this.changed(false);
        }
    }
}
