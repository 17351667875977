import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, inject, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { NgFor, AsyncPipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
    selector: 'chips',
    templateUrl: 'chips.component.html',
    styleUrls: ['chips.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        MatFormFieldModule,
        MatChipsModule,
        NgFor,
        MatIconModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        AsyncPipe
    ],
})
export class ChipsComponent {
    separatorKeysCodes: number[] = [ENTER, COMMA];
    inputCtrl = new FormControl('');
    filteredRoomTypes: Observable<any[]>;
    @Input() selectedRoomTypes: any[] = [];
    @Input() roomTypes: any;
    @Output() changed = new EventEmitter<any>();

    @ViewChild('roomTypeInput') roomTypeInput: ElementRef<HTMLInputElement> | undefined;
    public limitError: boolean = false;
    public inputValue: string = '';

    announcer = inject(LiveAnnouncer);

    constructor() {
        this.filteredRoomTypes = this.inputCtrl.valueChanges.pipe(
            startWith(null),
            map((roomType: string | null) => {
                if (roomType === null || roomType.trim() === '') {
                    return [];
                }

                const filterValue = roomType.toLowerCase();
                const filterResults = this.roomTypes.filter((rt: any) =>
                    rt.name.toLowerCase().includes(filterValue)
                );

                if (!filterResults.length) {
                    return [{ name: roomType }];
                }

                return filterResults;
            })
        );

        this.inputCtrl.valueChanges.subscribe(() => {
            this.changed.emit();
        });
    }

    totalLength(): number {
        return this.selectedRoomTypes.join('').length;
    }

    clearInput(event: any) {
        setTimeout(() => {
            this.inputValue = '';
            event.target.value = '';
        }, 200);
    }

    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();

        if (value && this.selectedRoomTypes.length < 3) {
            this.selectedRoomTypes.push(value);
        } else {
            this.limitError = true;
            setTimeout(() => {
                this.limitError = false;
            }, 3000);
        }
        // Clear the input value
        event.chipInput!.clear();

        this.inputCtrl.setValue(null);
    }

    remove(type: string): void {
        const index = this.selectedRoomTypes.indexOf(type);

        if (index >= 0) {
            this.selectedRoomTypes.splice(index, 1);

            this.announcer.announce(`Removed ${type}`);
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if (this.roomTypeInput) {
            if (this.selectedRoomTypes.length < 3) {
                this.selectedRoomTypes.push(event.option.viewValue);
            } else {
                this.limitError = true;
                setTimeout(() => {
                    this.limitError = false;
                }, 3000);
            }
            this.roomTypeInput.nativeElement.value = '';
            this.inputCtrl.setValue(null);
        }
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.roomTypes.filter((roomType: any) => roomType.name.toLowerCase().includes(filterValue));
    }
}
