<div
    class="wrapper"
    [class]="breakpointService.currentBreakpointClass">
    <label
        for="upload-ref"
        class="upload-ref"
        *ngIf="
            breakpointService.currentBreakpointClass !== 'is-phone-portrait'
        ">
        Need to upload a reference image?
        <input
            type="file"
            multiple
            accept="image/x-png, image/gif, image/jpeg, image/png, image/jpg, image/webp, image/tiff, image/heic, application/pdf"
            hidden
            id="upload-ref"
            (change)="uploadReferenceAssets($event)" />
    </label>
    <label
        for="upload-ref-pp"
        class="upload-ref-pp"
        *ngIf="
            breakpointService.currentBreakpointClass === 'is-phone-portrait'
        ">
        <h3>Reference image</h3>
        <span>
            <p>Upload</p>
            <mat-icon svgIcon="photo2" />
        </span>
        <input
            type="file"
            multiple
            accept="image/x-png, image/gif, image/jpeg, image/png, image/jpg, image/webp, image/tiff"
            hidden
            id="upload-ref-pp"
            (change)="uploadReferenceAssets($event)" />
    </label>
    <hr [style]="{ width: totalProgress + '%' }" />
    <div class="reference-photos">
        <div
            class="reference-photo"
            *ngFor="let photo of service.reference_assets">
            <img
                *ngIf="photo.url.split('.').pop() !== 'pdf'"
                [src]="photo.url"
                alt="" />
            <img
                *ngIf="photo.url.split('.').pop() === 'pdf'"
                src="assets/pdf-icon.png"
                alt="" />
            <button
                class="delete"
                (click)="removeRef(photo.id)">
                <mat-icon svgIcon="xMark" />
            </button>
        </div>
    </div>
</div>
