import { Component, Input } from '@angular/core';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-main-menu',
	templateUrl: './main-menu.component.html',
	styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent {
	public isMenuOpen: boolean = false;
	public user: any = JSON.parse(localStorage.getItem('user') || '{}');
	@Input() public balance: any;

	constructor(
		public breakpointService: BreakpointService,
		private auth: AuthService,
		private router: Router,
		private api: ApiService,
		private userService: UserService
	) {
		this.userService.user$.subscribe(user => {
			this.user = user;
		});
	}

	logOut() {
		this.auth.logout();
		this.router.navigate(["/login"]);
	}

	goTo(path: string) {
		this.router.navigate([path]);
	}

	getRouteLink() {
		this.api
			.get('user/stripe/portal', { redirect: window.location.href })
			.then((res: string) => {
				window.location.href = res
			});
	}

	openIntercom() {
		if (window.Intercom) {
			window.Intercom('show');
		}
	}

	goToProjectPage(param?: string) {
		this.router.navigate(['new-project'], { queryParams: { type: param } });
	}
}
