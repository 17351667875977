import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectsComponent } from './projects/projects.component';
import { NewProjectComponent } from './new-project/new-project.component';
import { LoginComponent } from './login/login.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { ReferralProgramComponent } from './referral-program/referral-program.component';
import { SettingsComponent } from './settings/settings.component';
import { SuccessComponent } from './success/success.component';
import { ValidateComponent } from './validate/validate.component';
import { authGuard } from './services/auth-guard.service';
import { ClaimComponent } from './claim/claim.component';
import { ProcessAnonymousComponent } from './process-anonymous/process-anonymous.component';
import { BaSliderComponent } from './components/ba-slider/ba-slider.component';
import { PublicComponent } from './public/public.component';
import { BlankPageComponent } from './blank-page/blank-page.component';
import { OverdueComponent } from './overdue/overdue.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { newProjectResolver } from './new-project/resolvers/new-project.resolver';
import { landingPageResolver } from './landing-page/resolvers/landing-page.resolver';


const routes: Routes = [
	{ path: 'login', component: LoginComponent },
	{ path: 'register', component: LoginComponent },
	{ path: '', redirectTo: '/projects', pathMatch: 'full' },
	{ path: 'projects', component: ProjectsComponent, canActivate: [authGuard] },
	{
		path: 'new-project',
		component: NewProjectComponent,
		canActivate: [authGuard],
		resolve: { data: newProjectResolver }
	},
	{
		path: 'projects/:guid/summary',
		component: NewProjectComponent,
		canActivate: [authGuard],
		resolve: { data: newProjectResolver }
	},
	{ path: 'projects/:guid/delivery', component: DeliveryComponent, canActivate: [authGuard] },
	{ path: 'referral-program', component: ReferralProgramComponent, canActivate: [authGuard] },
	{ path: 'my-profile', component: SettingsComponent, canActivate: [authGuard] },
	{ path: 'p/embed/:token', component: BaSliderComponent },
	{ path: 'p/:token', component: PublicComponent },
	{
		path: 'p/lp/:token',
		component: LandingPageComponent,
		resolve: { data: landingPageResolver }
	},
	{
		path: 'success',
		component: SuccessComponent,
		canActivate: [authGuard],
		data: { title: 'Successful Payment' }
	},
	{
		path: 'validate/:email/:token',
		component: ValidateComponent,
		canActivate: [authGuard],
		data: { title: 'Validate email' }
	},
	{
		path: 'confirm/:email/:token',
		component: LoginComponent,
		data: { title: 'forgot' }
	},
	{
		path: 'claim-free-credits',
		component: ClaimComponent,
		canActivate: [authGuard],
		data: { title: 'Claim free credits' }
	},
	{
		path: 'register/:credits/credits',
		component: LoginComponent,
		data: { title: 'register' }
	},
	{
		path: 'register/:invite/invite',
		component: LoginComponent,
		data: { title: 'register' }
	},
	{
		path: 'blank-page',
		component: BlankPageComponent
	},
	{
		path: 'overdue',
		component: OverdueComponent,
	},
	{
		path: 'new-anon-project',
		component: ProcessAnonymousComponent
	},
	{ path: '**', redirectTo: '/login' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
