import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-email-template',
    templateUrl: './email-template.component.html',
    styleUrls: ['./email-template.component.scss']
})
export class EmailTemplateComponent {
    public user: any;
    public mailTxt: string = '';

    constructor(
        public dialogRef: MatDialogRef<EmailTemplateComponent>,
        public breakpointService: BreakpointService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.user = this.data.user;
        this.mailTxt = this.data.mailTxt;
    }

    close() {
        this.dialogRef.close();
    }

    saveEmailChange() {
        this.dialogRef.close(this.mailTxt);
    }

}
