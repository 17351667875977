<div
    #wrapper
    class="wrapper"
    [ngClass]="breakpointService.currentBreakpointClass"
    [class.touch]="breakpointService.isTouchDevice">
    <div class="strip">
        <div class="search">
            <input
                [disabled]="!allProjects.length"
                type="text"
                placeholder="Search projects"
                [(ngModel)]="searchTerm"
                (ngModelChange)="filterProjects()" />
            <mat-icon svgIcon="search" />
        </div>
        <div class="switch-view">
            <p>Change view:</p>
            <input
                hidden
                type="radio"
                name="view"
                value="grid-view"
                id="grid-view"
                [(ngModel)]="view"
                (ngModelChange)="saveViewSettingToLocal()" />
            <label for="grid-view"><mat-icon svgIcon="gridView" /></label>

            <input
                hidden
                type="radio"
                name="view"
                value="list-view"
                id="list-view"
                [(ngModel)]="view"
                (ngModelChange)="saveViewSettingToLocal()" />
            <label for="list-view"><mat-icon svgIcon="listView" /></label>
        </div>
    </div>
    <div
        #mainGrid
        class="grid"
        *ngIf="view === 'grid-view'">
        <div
            class="grid-project"
            *ngFor="let project of visibleProjects">
            <app-project-card
                [project]="project"
                (onProjectDeleted)="deleteProject($event)" />
        </div>
    </div>
    <div
        class="list"
        *ngIf="view === 'list-view'">
        <div class="header">
            <h5 class="project-name">Project Name</h5>
            <h5 class="project-status">Status</h5>
            <h5
                class="project-images"
                *ngIf="!gutTable()">
                Images
            </h5>
            <h5
                class="project-eta"
                *ngIf="!gutTable()">
                ETA
            </h5>
            <h5
                class="project-sharing"
                *ngIf="
                    breakpointService.currentBreakpointClass !==
                    'is-phone-portrait'
                ">
                Sharing
            </h5>
            <h5 class="project-actions">Actions</h5>
        </div>
        <div
            class="list-project"
            *ngFor="let project of visibleProjects">
            <app-project-list-card
                [project]="project"
                (onProjectDeleted)="deleteProject($event)" />
        </div>
    </div>
    <div
        class="load-more"
        *ngIf="showLoadMoreButton()">
        <button
            (click)="loadMoreProjects()"
            class="primary">
            <p>Load more</p>
        </button>
    </div>
</div>
