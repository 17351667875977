<div class="wrapper">
    <h2>Additional fields required</h2>
    <div class="listing-id">
        <label for="listing-id">Listing ID</label>
        <input
            type="text"
            id="listing-id"
            [(ngModel)]="listingId"
            name="listing-id"
            placeholder="Enter the listing ID"
            required />
    </div>
    <div class="market">
        <label for="market">Business Area/Market</label>

        <mat-form-field class="market-selector">
            <mat-select
                [formControl]="marketCtrl"
                placeholder="Enter the business area/market"
                required>
                <mat-optgroup
                    *ngFor="let region of regions"
                    [label]="region.name">
                    <mat-option
                        *ngFor="let market of region.markets"
                        [value]="market">
                        {{ market }}
                    </mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>
    </div>
    <button
        (click)="save()"
        class="save"
        [disabled]="!listingId || !marketCtrl.value">
        Save
    </button>
</div>
