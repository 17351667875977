<div
    [class]="breakpointService.currentBreakpointClass"
    class="wrapper"
    #wrapper>
    <div class="header">
        <div class="left">
            <h1>Latest at <span>VirtualStaging.com</span></h1>
            <p>
                Discover <span><mat-icon svgIcon="binoculars" /></span> what's
                going on with VirtualStaging.com right here.
            </p>
        </div>
        <div
            class="right"
            *ngIf="!breakpointService.isTouchDevice">
            <div class="slider-arrows">
                <button (click)="slide(-1)">
                    <mat-icon svgIcon="leftArrow" />
                </button>
                <button (click)="slide(1)">
                    <mat-icon svgIcon="rightArrow" />
                </button>
            </div>
        </div>
    </div>
    <div
        class="articles-wrapper"
        #articlesWrapper>
        <div
            class="articles"
            #scrollWrapper>
            @for(article of articles.items; track article; let first = $first) {
            <div class="article">
                <div class="image">
                    <img
                        [src]="article.photo"
                        alt="article.title" />
                    <a [href]="article.link"> </a>
                    <p>{{ article.description }}</p>
                </div>
                <div class="content">
                    <p>{{ article.title }}</p>
                </div>
                <div
                    *ngIf="
                        first &&
                        breakpointService.currentBreakpointClass ===
                            'is-phone-portrait' &&
                        showSwipe
                    "
                    class="swipe"
                    (click)="learnedSwipe()"
                    (swipeLeft)="learnedSwipe()">
                    >
                    <p>Swipe to see more</p>
                    <img
                        src="assets/swipe.gif"
                        alt="" />
                </div>
            </div>
            }
        </div>
    </div>
</div>
