import { Component } from '@angular/core';
import { BreakpointService } from 'src/app/services/breakpoint.service';

@Component({
    selector: 'app-canceled',
    templateUrl: './canceled.component.html',
    styleUrls: ['./canceled.component.scss']
})
export class CanceledComponent {
    public project: any;

    constructor(
        public breakpointService: BreakpointService
    ) { }

}
