import { Component, ViewChild, ElementRef, Signal, signal, computed, HostListener, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { FooterComponent } from '../components/footer/footer.component';
import { BreakpointService } from '../services/breakpoint.service';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { indexOf } from 'lodash';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';



@Component({
    selector: 'app-landing-page',
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        FooterComponent,
        MatDialogModule,
        FormsModule,
        MatTooltipModule
    ],
    templateUrl: './landing-page.component.html',
    styleUrl: './landing-page.component.scss'
})
export class LandingPageComponent {
    public spots: any = [];
    public currentFloorplanSpots: Signal<any> = computed(() => {
        return this.spots.filter((image: any) => image.floorplan === this.currentFloorplan()?.iframe_url).sort((a: any, b: any) => a.key - b.key);
    });
    public currentIndex = 0;
    public currentView = 'fpView'
    public currentFloorplan = signal({
        annotations: [
            {
                cordinates: {
                    left: '',
                    bottom: ''
                },
                index: 0,
                url: ''
            }
        ],
        final_render_url: '',
        iframe_url: '',
        label: '',
        ortho_render_url: '',
    })
    public propertyInfo: any | null;
    public floorplans: any[] = [];
    @ViewChild('sliderContainer', { static: false }) sliderContainer!: ElementRef;
    @ViewChild('renderContainer', { static: false }) renderContainer!: ElementRef;
    public sliderFullScreen = false;
    public renderFullScreen = false;
    public projectName: string = '';
    @ViewChild('annotationsContainer', { static: false }) annotationsContainer!: ElementRef;
    @ViewChild('floorplanImage', { static: false }) floorplanImage!: ElementRef;
    public selectedAnnotation: any = null;
    public selectedSpot: any = null;
    public copiedToClipboard = false;
    public showInstruction = true;
    @ViewChild('iFrame', { static: false }) iFrame!: ElementRef;
    public iFrameUrlSafe: Signal<SafeResourceUrl> = computed(() => this.sanitizer.bypassSecurityTrustResourceUrl(this.currentFloorplan()?.iframe_url));
    @ViewChild('iFrameContainer', { static: false }) iFrameContainer!: ElementRef;
    public iFrameFullScreen = false;
    public loadingIframe = false;


    constructor(
        public breakpointService: BreakpointService,
        private route: ActivatedRoute,
        private cd: ChangeDetectorRef,
        private sanitizer: DomSanitizer,

    ) { }

    ngOnInit() {
        this.route.data.subscribe((data) => {
            this.floorplans = data['data'].floor_plans;
            this.spots = data['data'].spots;
            this.currentFloorplan.set(this.floorplans[0]);
            this.propertyInfo = Object.keys(data['data'].property_info).length > 0 ? data['data'].property_info : null;
            this.projectName = data['data'].project_name;
            this.selectedSpot = this.currentFloorplanSpots()[0];
            this.currentFloorplan().annotations = this.currentFloorplan().annotations.sort((a: any, b: any) => a.index - b.index);
            this.selectedAnnotation = this.currentFloorplan()?.annotations[0];
        });

        document.addEventListener('fullscreenchange', () => {
            if (!document.fullscreenElement) {
                this.sliderFullScreen = false;
                this.renderFullScreen = false;
            }
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.setAnnotationsContainerSize();
    }

    slide(direction: number) {
        if (direction === -1) {
            if (this.currentIndex > 0) {
                this.currentIndex--;
            } else {
                this.currentIndex = this.currentFloorplanSpots().length - 1;
            }
        } else {
            if (this.currentIndex < this.currentFloorplanSpots().length - 1) {
                this.currentIndex++;
            } else {
                this.currentIndex = 0;
            }
        }
        this.setAnnotationAndSpot(this.currentIndex);
    }

    setAnnotationAndSpot(index: number) {
        this.selectedAnnotation = this.currentFloorplan().annotations[index];
        this.selectedSpot = this.currentFloorplanSpots()[index];
        this.currentIndex = index;
        this.showInstruction = false;
    }

    changeView(view: string): void {
        this.currentView = view;
        this.showInstruction = true;
    }

    emailAgent(email: string) {
        window.location.href = `mailto:${email}`;
    }

    shareProperty() {
        if (navigator.share) {
            navigator.share({
                title: 'Virtual Staging Floor Plan',
                text: 'Check out the floor plan for this property',
                url: window.location.href
            }).catch((error) => {
                console.error('Error sharing floor plan', error);
            });
        } else {
            // Fallback: Copy to clipboard
            const url = window.location.href;
            this.copyToClipboard(url);
        }
    }

    copyToClipboard(text: string) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    this.copiedToClipboard = true;
                    setTimeout(() => {
                        this.copiedToClipboard = false;
                    }, 2000);
                })
                .catch((error) => {
                    console.error('Error copying URL to clipboard', error);
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                document.execCommand('copy');
                this.copiedToClipboard = true;
                setTimeout(() => {
                    this.copiedToClipboard = false;
                }, 2000);
            } catch (error) {
                console.error('Error copying URL to clipboard', error);
            }
            document.body.removeChild(textArea);
        }
    }

    sliderFullScreenToggle() {
        this.sliderFullScreen = !this.sliderFullScreen;
        if (this.sliderFullScreen) {
            this.sliderContainer.nativeElement.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
    }

    floorplanFullScreenToggle() {
        this.renderFullScreen = !this.renderFullScreen;
        if (this.renderFullScreen) {
            this.renderContainer.nativeElement.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
    }

    iFrameFullScreenToggle() {
        this.iFrameFullScreen = !this.iFrameFullScreen;
        if (this.iFrameFullScreen) {
            this.iFrameContainer.nativeElement.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
    }

    setAnnotationsContainerSize() {
        if (this.annotationsContainer) {
            this.annotationsContainer.nativeElement.style.width = `${this.floorplanImage.nativeElement.width}px`;
            this.annotationsContainer.nativeElement.style.height = `${this.floorplanImage.nativeElement.height}px`;
        }
    }

    indexOf(array: any[], obj: any) {
        return indexOf(array, obj);
    }

    toggleInstruction() {
        this.showInstruction = !this.showInstruction;
    }

    forceChangeDetection() {
        this.cd.detectChanges();
    }




}
