<div
    class="wrapper"
    [ngClass]="breakpointService.currentBreakpointClass"
    *ngIf="!loading">
    <header *ngIf="!hideHeader()">
        <div class="header-wrapper">
            <button (click)="goHome()">
                <mat-icon [svgIcon]="getLogo()" />
            </button>
            <app-toolbar />
            <div class="right">
                <app-wallet
                    *ngIf="
                        !breakpointService.isTouchDevice &&
                        balance &&
                        (balance['vs credits'] > 0 || balance.credits > 0)
                    " />
                <app-main-menu
                    [billingUrl]="billingUrl"
                    [balance]="balance" />
            </div>
        </div>
    </header>
    <hr *ngIf="!hideHeader()" />
    <main
        [ngClass]="{ 'new-page': hideHeader() }"
        [class.show-scrollbar]="showScrollbar()">
        <div class="main-wrapper">
            <router-outlet></router-outlet>
            <hr *ngIf="!hideFooter()" />
            <footer *ngIf="!hideFooter()">
                <app-footer />
            </footer>
        </div>
    </main>
</div>
