<div
    class="wrapper"
    [class]="breakpointService.currentBreakpointClass">
    <div class="header">
        <div class="home">
            <mat-icon svgIcon="logo" />
        </div>
    </div>
    <div class="content">
        <div
            class="viewer"
            *ngIf="currentFloorplanSpots().length > 0">
            <div
                class="slider-container"
                #sliderContainer
                [class.full-screen]="sliderFullScreen"
                *ngIf="currentView === 'fpView'">
                <div class="slider-image">
                    <img
                        [src]="
                            sliderFullScreen
                                ? currentFloorplanSpots()[currentIndex].url
                                : currentFloorplanSpots()[currentIndex]
                                      .compressed_url
                        "
                        [alt]="currentFloorplanSpots()[currentIndex].label"
                        (load)="forceChangeDetection()" />
                    <div class="counter-title">
                        <div class="slider-count">
                            <mat-icon svgIcon="camera"></mat-icon>
                            {{ currentIndex + 1 }}/{{
                                currentFloorplanSpots().length
                            }}
                        </div>
                        <div class="slider-title">
                            {{
                                currentFloorplanSpots()[currentIndex].label +
                                    " • " +
                                    currentFloorplan().label
                            }}
                        </div>
                    </div>
                    <button
                        class="full-screen-button"
                        (click)="sliderFullScreenToggle()">
                        <mat-icon svgIcon="fullScreen"></mat-icon>
                    </button>
                    <div class="slider-arrows">
                        <button (click)="slide(-1)">&#10094;</button>
                        <button (click)="slide(1)">&#10095;</button>
                    </div>
                </div>
            </div>
            <div
                *ngIf="currentView === 'floorplanRender'"
                class="render-container"
                #renderContainer>
                <div class="render">
                    <img
                        [src]="currentFloorplan().final_render_url"
                        alt="" />
                </div>
                <div class="actions">
                    <button
                        class="full-screen-button"
                        (click)="floorplanFullScreenToggle()">
                        <mat-icon svgIcon="fullScreen"></mat-icon>
                    </button>
                </div>
            </div>
            <div
                #iFrameContainer
                *ngIf="!breakpointService.isTouchDevice"
                class="iframe-container"
                [hidden]="currentView === 'fpView'"
                [ngClass]="{
                    visible:
                        currentView === '3dView' ||
                        currentView === 'walkthroughView'
                }">
                <div class="actions">
                    <div class="instructions">
                        <p
                            [class.show]="showInstruction"
                            *ngIf="currentView === '3dView'">
                            Hold left-click to rotate. Hold right-click to move.
                            Mouse wheel to zoom.
                        </p>
                        <p
                            [class.show]="showInstruction"
                            *ngIf="currentView === 'walkthroughView'">
                            Click the markers around the property to navigate
                            between locations.
                        </p>
                        <button
                            (click)="toggleInstruction()"
                            [class.collapse-radius]="showInstruction">
                            <mat-icon svgIcon="info3" />
                        </button>
                    </div>
                    <button
                        class="full-screen-button"
                        (click)="iFrameFullScreenToggle()">
                        <mat-icon svgIcon="fullScreen"></mat-icon>
                    </button>
                </div>
                <div
                    class="loading"
                    *ngIf="loadingIframe">
                    The 3D view is loading. This could take a couple of minutes.
                    <img
                        src="assets/rolling.gif"
                        alt="" />
                </div>

                <iframe
                    #iFrame
                    width="100%"
                    height="100%"
                    [src]="iFrameUrlSafe()"
                    frameborder="0"
                    allowfullscreen>
                </iframe>
            </div>

            <div
                class="floorplan-container"
                *ngIf="currentView === 'fpView'">
                <div class="instructions">
                    <p [class.show]="showInstruction">
                        Click a marker to view the photo from that spot.
                    </p>
                    <button
                        (click)="toggleInstruction()"
                        [class.collapse-radius]="showInstruction">
                        <mat-icon svgIcon="info3" />
                    </button>
                </div>
                <div class="image">
                    <img
                        #floorplanImage
                        (load)="setAnnotationsContainerSize()"
                        [src]="currentFloorplan().ortho_render_url"
                        [alt]="currentFloorplan().label" />
                    <div
                        #annotationsContainer
                        class="annotations">
                        @for (annotation of currentFloorplan().annotations;
                        track annotation; let i = $index) {
                        <button
                            class="annotation"
                            (click)="
                                setAnnotationAndSpot(
                                    indexOf(
                                        currentFloorplan().annotations,
                                        annotation
                                    )
                                )
                            "
                            [class.selected]="
                                selectedAnnotation.index === annotation.index
                            "
                            [style.left.%]="annotation.cordinates.left"
                            [style.bottom.%]="annotation.cordinates.bottom">
                            {{ i + 1 }}
                        </button>
                        }
                    </div>
                </div>
            </div>
        </div>

        <div
            class="viewer-controls"
            *ngIf="spots.length > 0">
            <div class="change-iframe">
                @for(floorplan of floorplans; track floorplan ) {
                <input
                    [(ngModel)]="currentFloorplan"
                    (ngModelChange)="setAnnotationAndSpot(0)"
                    [value]="floorplan"
                    type="radio"
                    name="changeIframe"
                    hidden
                    [id]="floorplan.iframe_url + '-id'" />
                <label [for]="floorplan.iframe_url + '-id'">{{
                    floorplan.label
                }}</label>
                }
            </div>
            <div class="change-view">
                <input
                    [(ngModel)]="currentView"
                    (ngModelChange)="changeView($event)"
                    value="fpView"
                    type="radio"
                    name="viewerControls"
                    hidden
                    id="fpView" />
                <label for="fpView"
                    ><mat-icon svgIcon="fpView" />Floorplan View</label
                >
                <input
                    [(ngModel)]="currentView"
                    (ngModelChange)="changeView($event)"
                    value="floorplanRender"
                    type="radio"
                    name="viewerControls"
                    hidden
                    id="floorplanRender" />
                <label for="floorplanRender"
                    ><mat-icon svgIcon="floorplanRender" />Floorplan
                    Render</label
                >
                <input
                    *ngIf="!breakpointService.isTouchDevice"
                    [(ngModel)]="currentView"
                    (ngModelChange)="changeView($event)"
                    value="3dView"
                    type="radio"
                    name="viewerControls"
                    hidden
                    id="3dView" />

                <label
                    for="3dView"
                    *ngIf="!breakpointService.isTouchDevice"
                    ><mat-icon svgIcon="3dView" />3D View</label
                >
            </div>
        </div>
        <div
            class="placeholder"
            *ngIf="!currentFloorplan || !spots || spots?.length === 0">
            Viewer not available
        </div>
        <div class="property-info">
            <div class="property-info-inner">
                <div
                    class="project-name"
                    *ngIf="projectName && !propertyInfo?.propertyName">
                    <h2>{{ projectName }}</h2>
                </div>
                <div
                    class="pi-wrapper"
                    *ngIf="propertyInfo">
                    <div class="info">
                        <p
                            class="status"
                            *ngIf="
                                propertyInfo.statusHide !== undefined
                                    ? !propertyInfo.statusHide
                                    : false
                            ">
                            {{ propertyInfo.status }}
                        </p>
                        <h2>{{ propertyInfo.propertyName }}</h2>
                        <div class="bullets">
                            <p
                                *ngIf="
                                    propertyInfo.bedroomsHide !== undefined
                                        ? !propertyInfo.bedroomsHide
                                        : false
                                ">
                                <span>{{ propertyInfo.bedrooms }}</span>
                                Bedrooms
                            </p>
                            <p
                                *ngIf="
                                    propertyInfo.bathroomsHide !== undefined
                                        ? !propertyInfo.bathroomsHide
                                        : false
                                ">
                                <span>{{ propertyInfo.bathrooms }}</span> Baths
                            </p>
                            <p
                                *ngIf="
                                    propertyInfo.sqftHide !== undefined
                                        ? !propertyInfo.sqftHide
                                        : false
                                ">
                                <span>{{ propertyInfo.sqft }}</span> Sqft
                            </p>
                            <p
                                *ngIf="
                                    propertyInfo.sqftLotHide !== undefined
                                        ? !propertyInfo.sqftLotHide
                                        : false
                                ">
                                <span>{{ propertyInfo.sqftLot }}</span> Sqft Lot
                            </p>
                        </div>
                        <div class="characteristics">
                            <div
                                class="property-type"
                                *ngIf="
                                    propertyInfo.propertyTypeHide !== undefined
                                        ? !propertyInfo.propertyTypeHide
                                        : false
                                ">
                                <mat-icon svgIcon="propertyType"></mat-icon>
                                <div>
                                    <p>Property type</p>
                                    <p>{{ propertyInfo.propertyType }}</p>
                                </div>
                            </div>
                            <div
                                class="year-built"
                                *ngIf="
                                    propertyInfo.yearBuiltHide !== undefined
                                        ? !propertyInfo.yearBuiltHide
                                        : false
                                ">
                                <mat-icon svgIcon="yearBuilt"></mat-icon>
                                <div>
                                    <p>Year built</p>
                                    <p>{{ propertyInfo.yearBuilt }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="actions">
                        <button
                            class="email-agent"
                            *ngIf="
                                propertyInfo.agentEmailHide !== undefined
                                    ? !propertyInfo.agentEmailHide
                                    : false
                            "
                            (click)="emailAgent(propertyInfo.agentEmail)">
                            Email agent
                        </button>
                        <button
                            [class.copied]="copiedToClipboard"
                            class="share"
                            (click)="shareProperty()">
                            Share this home
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>
