import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private userSubject = new BehaviorSubject<any>(null);
    public user$ = this.userSubject.asObservable();

    constructor(private api: ApiService) { }

    async loadUserFromDB() {
        let user = await this.api.get('user');
        return user;
    }

    updateUser(user: any) {
        this.userSubject.next(user);
        localStorage.setItem('user', JSON.stringify(user));
    }
}
