<div class="wrapper">
    <div
        class="icon"
        *ngIf="data.icon">
        <mat-icon [svgIcon]="data.icon" />
    </div>
    <div class="header">
        <h4>{{ data.message }}</h4>
    </div>
    <div
        class="more-info"
        *ngIf="data.moreInfo">
        <p>
            {{ data.moreInfo }}
        </p>
    </div>
    <div
        class="option"
        *ngIf="optionTitle">
        <input
            type="checkbox"
            name="option"
            [(ngModel)]="optionValue"
            hidden
            id="option" />
        <label for="option"
            ><span class="checkmark"
                ><mat-icon
                    svgIcon="blueCheckmark2"
                    *ngIf="optionValue"
            /></span>
            {{ optionTitle }}
        </label>
    </div>
    <div class="buttons">
        <button
            mat-button
            (click)="confirm()"
            class="primary">
            <p>{{ data.confirm.title }}</p>
        </button>
        <button
            mat-button
            (click)="reject()"
            [class]="sameColorButtons ? 'primary' : 'transparent'">
            <p>{{ data.reject.title }}</p>
        </button>
    </div>
</div>
