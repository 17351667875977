<div
    class="wrapper"
    [class]="breakpointService.currentBreakpointClass">
    <div class="title">
        <h1>
            <span>{{ project.name }} project is done!</span> Check it out...
        </h1>
        <p>
            Please review the images below and let us know if you need any
            changes at all. <br />We're happy to work with you to get everything
            just right.
        </p>
    </div>
    <div class="notice">
        <div
            class="eta"
            *ngIf="
                !needsApproval() ||
                (needsApproval() &&
                    (project.status_code === 'done' ||
                        project.status_code === 'rework') &&
                    project.floor_plan_approved &&
                    project.floorplan_status === 'COMPLETED') ||
                (needsApproval() &&
                    (project.status_code === 'done' ||
                        project.status_code === 'rework' ||
                        project.status_code === 'in-progress') &&
                    !project.floor_plan_approved)
            ">
            <div class="left">
                <mat-icon svgIcon="blueCheckmark" />
                <h4>Delivered in:</h4>
                <p class="hours">{{ duration.hours }}</p>
                <p>Hrs</p>
                <p class="minutes">{{ duration.minutes }}</p>
                <p>Mins</p>
            </div>
            <hr *ngIf="mode === 'edit'" />
            <div
                class="feedback"
                *ngIf="!showTrustpilot && mode === 'edit'">
                <h4>How did we do?</h4>
                <div class="emojis">
                    <button
                        *ngFor="
                            let button of ['😠', '😕', '😐', '😃', '🤩'];
                            let i = index
                        "
                        [matMenuTriggerFor]="leaveFeedbackModal"
                        [ngClass]="{ selected: project.rated === i + 1 }"
                        [class.applied]="project.rating?.rating === i + 1"
                        [class.not-applied]="
                            project.rating && project.rating.rating !== i + 1
                        "
                        (click)="project.rated = i + 1">
                        {{ button }}
                    </button>
                </div>
            </div>
            <div
                *ngIf="showTrustpilot && mode === 'edit'"
                class="trustpilot">
                <a
                    href="https://www.trustpilot.com/review/vs24.com"
                    target="_blank"
                    ><img src="/assets/trustpilot.png"
                /></a>
            </div>
        </div>
        <div
            class="fp-eta"
            *ngIf="
                needsApproval() &&
                project.status_code === 'in-progress' &&
                project.floor_plan_approved &&
                project.floorplan_status !== 'COMPLETED'
            ">
            <mat-icon svgIcon="blueCheckmark" />
            <h4>Remaining time:</h4>
            <p class="hours">{{ remainingTime.split(":")[0] }}</p>
            <p>Hrs</p>
            <p class="minutes">{{ remainingTime.split(":")[1] }}</p>
            <p>Mins</p>
            <hr />
        </div>
        <hr
            *ngIf="
                needsApproval() &&
                project.status_code !== 'rework' &&
                project.status_code !== 'in-progress'
            " />
        <div
            class="fp-done"
            *ngIf="
                needsApproval() &&
                project.floor_plan_approved &&
                project.floorplan_status === 'COMPLETED' &&
                project.floor_plan_token
            ">
            <div class="text">
                <h2>Your <span>3D floor plan</span> is ready!</h2>
                <p>
                    You can now view your floor plan page, or click Share to
                    access multiple sharing options. If you have any questions
                    about the 3D floor plan, please reach out to us via our
                    <button (click)="openIntercom()">
                        <mat-icon svgIcon="liveChat" /> live chat</button
                    >.
                </p>
            </div>
            <div class="actions">
                <button
                    class="primary"
                    (click)="viewFloorPlan()">
                    View
                </button>
                <button
                    [class.copied]="copiedToClipboard"
                    class="transparent"
                    (click)="shareFloorPlan()">
                    Share
                </button>
            </div>
        </div>
        <div
            class="fp-done"
            *ngIf="
                needsApproval() &&
                project.floor_plan_approved &&
                project.floorplan_status === 'COMPLETED' &&
                !project.floor_plan_token
            ">
            <div class="text">
                <h2>Your <span>3D floor plan</span> is ready!</h2>
                <p>
                    You can now view your and download your floor plan renders
                    on this page. If you have any questions about the 3D floor
                    plan, please reach out to us via our
                    <button (click)="openIntercom()">
                        <mat-icon svgIcon="liveChat" /> live chat</button
                    >.
                </p>
            </div>
        </div>
        <div
            class="fp-approve"
            *ngIf="
                needsApproval() &&
                !project.floor_plan_approved &&
                project.status_code === 'done'
            ">
            <div class="text">
                <h2>Approve images to finalize 3D floor plan.</h2>
                <p>
                    This project contains a 3D floor plan order. Once you don't
                    need any more revisions for your virtual staging, please
                    approve the photos so we can finalize the floor plan.
                </p>
            </div>
            <div class="actions">
                <button
                    class="primary"
                    (click)="approveFloorPlan()">
                    Approve
                </button>
            </div>
        </div>
        <div
            class="fp-approved"
            *ngIf="
                needsApproval() &&
                project.floor_plan_approved &&
                project.floorplan_status === 'IN_PROGRESS'
            ">
            <div class="text">
                <h2>Our team is now working on your floor plan</h2>
                <p>
                    If you have any questions about the 3D floor plan, please
                    reach out to us via our
                    <button (click)="openIntercom()">
                        <mat-icon svgIcon="liveChat" /> live chat</button
                    >.
                </p>
            </div>
        </div>
    </div>
    <h1>Review your project below</h1>

    <div class="photos">
        <div
            class="photo"
            *ngFor="let serviceRequest of displayRequests; let i = index">
            <h3>
                {{
                    !serviceRequest.is_floor_plan
                        ? serviceRequest.name
                        : serviceRequest.label
                        ? serviceRequest.label
                        : "Floor Plan"
                }}
            </h3>
            <div class="before-after">
                <before-after
                    *ngIf="!serviceRequest.is_floor_plan"
                    [before]="getThumbnailFromUrl(getImageUrl(serviceRequest))"
                    [after]="
                        getThumbnailFromUrl(getFinalImageUrl(serviceRequest))
                    "></before-after>
                @if(serviceRequest.is_floor_plan && !serviceRequest.is_ortho) {
                <div class="floor-plan">
                    <img
                        [src]="getFinalImageUrl(serviceRequest)"
                        alt="Floor plan" />
                </div>
                } @else if(serviceRequest.is_floor_plan &&
                serviceRequest.is_ortho) {
                <div class="floor-plan">
                    <img
                        [src]="getOrthoImage(serviceRequest)"
                        alt="Ortho floor plan" />
                </div>
                }
            </div>
            <div
                class="actions"
                *ngIf="!reworkSubmitted(serviceRequest)">
                <div
                    class="score"
                    *ngIf="mode === 'edit'">
                    <div class="stars">
                        <button
                            *ngFor="let star of [1, 2, 3, 4, 5]"
                            [ngClass]="{
                                selected: serviceRequest.rate >= star
                            }"
                            [class.hovered]="
                                starsHovered !== null
                                    ? starsHovered >= star
                                    : ''
                            "
                            (mouseenter)="starsHovered = star"
                            (mouseleave)="starsHovered = null"
                            (click)="rateImage(star, serviceRequest)">
                            ⭐
                        </button>
                    </div>
                </div>
                <div class="buttons">
                    @if(!serviceRequest.is_ortho) {
                    <button
                        *ngIf="serviceRequest.final_image"
                        class="primary"
                        (click)="
                            downloadFile(
                                project,
                                serviceRequest.final_image.image_name,
                                i
                            );
                            $event.preventDefault()
                        ">
                        Download
                    </button>
                    <button
                        *ngIf="serviceRequest.final_asset"
                        class="primary"
                        (click)="
                            downloadAsset(
                                serviceRequest.final_asset.url,
                                project.name,
                                serviceRequest.sort,
                                serviceRequest.final_asset.original_name,
                                serviceRequest.asset?.original_name ||
                                    serviceRequest.request_image?.image_name
                            )
                        ">
                        Download
                    </button>
                    } @else if (serviceRequest.is_ortho) {
                    <button
                        class="primary"
                        (click)="
                            downloadOrthoImage(serviceRequest);
                            $event.preventDefault()
                        ">
                        Download
                    </button>
                    }
                    <button
                        *ngIf="mode === 'edit' && !serviceRequest.is_floor_plan"
                        [class.fp-approved]="project.floor_plan_approved"
                        [matTooltip]="
                            project.floor_plan_approved
                                ? 'Revisions are disabled after images are approved, please reach out to our team if you need any changes.'
                                : ''
                        "
                        class="transparent"
                        (click)="requestRevision(serviceRequest)">
                        Request Revision
                    </button>
                </div>
            </div>
            <div
                class="submitted"
                *ngIf="reworkSubmitted(serviceRequest)">
                <p>
                    Rework request for {{ serviceRequest.name }} submitted
                    successfully
                </p>
            </div>
        </div>
    </div>
    <h1>More options</h1>
    <div class="options">
        <div class="option">
            <div class="option-text">
                <h2>
                    <mat-icon svgIcon="customizeOption" />Add watermark and
                    download all images
                </h2>
                <p>
                    Add your watermark text and download all images in a .zip
                    file. Optionally, compress the images to reduce the file
                    size.
                </p>
            </div>
            <div class="button-wrapper">
                <button
                    class="transparent"
                    (click)="addWatermark(watermarkModal)">
                    Customize
                </button>
            </div>
        </div>
        <div class="option">
            <div class="option-text">
                <h2>
                    <mat-icon svgIcon="download" />Download all images without
                    watermark
                </h2>
                <p>
                    Download all images in a .zip file. Optionally, compress the
                    images to reduce the file size.
                </p>
            </div>
            <div class="button-wrapper">
                <button
                    class="transparent"
                    [disabled]="generatingZip"
                    (click)="downloadAll()">
                    {{
                        generatingZip
                            ? "Generating..."
                            : emailRequested
                            ? "Request submitted"
                            : "Download all"
                    }}
                    <img
                        src="/assets/rolling.gif"
                        *ngIf="generatingZip" />
                </button>
            </div>
        </div>
        <div class="option">
            <div class="option-text">
                <h2>
                    <mat-icon svgIcon="chatBubble" />Contact us via live chat
                </h2>
                <p>
                    Need changes and would like to discuss this with our
                    Customer Support team? Click below to contact us via live
                    chat and we'll help you communicate the changes to our
                    designers.
                </p>
            </div>

            <div class="button-wrapper">
                <button
                    class="transparent"
                    (click)="startLiveChat()">
                    Live chat help
                </button>
            </div>
        </div>
    </div>
    <div class="articles">
        <app-latest [articles]="articles"></app-latest>
    </div>
</div>

<ng-template
    #requestRevisionModal
    let-serviceRequest>
    <div
        class="request-revision-wrapper"
        [class]="breakpointService.currentBreakpointClass">
        <button
            class="close"
            (click)="closeDialog()">
            <mat-icon svgIcon="xMark" />
        </button>
        <div class="image">
            <app-annotations
                [imageHeight]="'35rem'"
                [serviceRequest]="serviceRequest"
                (annotationsChange)="
                    handleAnnotationsChange($event, serviceRequest)
                ">
            </app-annotations>
            <div
                class="overlay"
                id="annotations-overlay"
                (click)="hideAnnotationsOverlay()"
                *ngIf="annotationsOverlayVisible">
                <div class="focus">
                    <h2>
                        Click here to leave a comment for
                        <br />
                        <span class="service-name">{{
                            serviceRequest.name
                        }}</span>
                        (optional)
                        <span class="edges-top"></span>
                        <span class="edges-bottom"></span>
                    </h2>
                </div>
            </div>
        </div>
        <div class="actions">
            <div class="comments">
                <h4><label for="comment-box">Your comments:</label></h4>
                <textarea
                    id="comment-box"
                    [(ngModel)]="serviceRequest.request_rework.description"
                    placeholder="Let us know what changes you need"
                    name="comments"
                    [rows]="this.breakpointService.isTouchDevice ? 3 : 9">
                </textarea>
            </div>
            <div class="upload-ref">
                <app-upload-ref
                    [service]="serviceRequest"
                    (refAdded)="addReferenceImage($event, serviceRequest)"
                    (refRemoved)="
                        removeReferenceImage($event, serviceRequest)
                    " />
            </div>
            <div
                class="buttons"
                [class.error]="reworkRequestError">
                <button
                    class="primary"
                    [disabled]="requestingRework"
                    (click)="requestRework(serviceRequest)">
                    Request revision
                </button>
            </div>
        </div>
    </div>
</ng-template>

<mat-menu
    (closed)="project.rated = null"
    #leaveFeedbackModal="matMenu"
    xPosition="before"
    yPosition="below">
    <div class="leave-feedback-modal">
        <textarea
            (click)="$event.stopPropagation()"
            rows="5"
            type="text"
            autofocus
            #feedbackField></textarea>
        <div class="buttons">
            <button
                mat-button
                (click)="leaveFeedback(feedbackField.value)"
                class="primary">
                <p>Leave feedback</p>
            </button>
            <button
                mat-button
                (click)="closeDialog()"
                class="transparent">
                <p>Close</p>
            </button>
        </div>
    </div>
</mat-menu>

<ng-template #watermarkModal>
    <watermark
        [project]="project"
        (onClick)="closeWatermarkModal()"></watermark>
</ng-template>
