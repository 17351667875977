import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from 'src/app/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ErrorhandlerService implements ErrorHandler {
	private webHook: string = environment.webHook;
	private lastMsg: string = '';
	private user_details: any;

	private options = {
		headers: new HttpHeaders(
			{ 'Content-Type': 'application/x-www-form-urlencoded' }
		)
	};

	constructor(
		private http: HttpClient
	) {
		let user = localStorage.getItem('user');
		this.user_details = user && user !== "undefined" ? JSON.parse(user).email : 'Anonymus';
	}

	sendError(error: any) {
		const message = {
			channel: '#app-logs-angular',
			text: "Login error",
			attachments: [
				{
					author_name: `User ${this.user_details} ON location ${window.location.href}`,
					color: 'danger',
					title: 'Trace',
					text: error
				}
			]
		}
		this.http.post(this.webHook, message, this.options)
			.toPromise()
			.then(response => response)
			.catch((err) => console.log(err));
	}

	handleError(error: any) {
		if (!environment.production) {
			console.error(error);
			return;
		}
		let string_message = JSON.stringify(error.message);
		if (this.webHook !== '' || string_message !== this.lastMsg) {
			this.lastMsg = string_message;
			if (string_message.indexOf('401') !== -1 || string_message.search('401') !== -1) {
				return;
			}
			if (string_message.indexOf('403') !== -1 || string_message.search('403') !== -1) {
				return;
			}
			if (string_message.indexOf('409') !== -1 || string_message.search('409') !== -1) {
				return;
			}
			const message = {
				channel: '#app-logs-angular',
				text: error.message,
				attachments: [
					{
						author_name: `User ${this.user_details} ON location ${window.location.href}`,
						color: 'danger',
						title: 'Trace',
						text: error.stack
					}
				]
			}
			this.http.post(this.webHook, message, this.options)
				.toPromise()
				.then(response => response)
				.catch((err) => console.log(err));
		} else {
			console.error(error.stack);
		}
	}
}
