import { Component } from '@angular/core';
import { BreakpointService } from 'src/app/services/breakpoint.service';
@Component({
    selector: 'app-footer',
    standalone: true,
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    constructor(public breakpointService: BreakpointService) { }
    getYear(): number {
        return new Date().getFullYear();
    }
}
