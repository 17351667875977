import { Component, ViewChild } from '@angular/core';
import { IntlTelInputDirective } from 'src/app/directives/intl-tel-input.directive';
import { ApiService } from 'src/app/services/api.service';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-update-phone',
    standalone: true,
    imports: [
        CommonModule,
        IntlTelInputDirective
    ],
    templateUrl: './update-phone.component.html',
    styleUrl: './update-phone.component.scss'
})
export class UpdatePhoneComponent {
    public phoneNumber: string = '+1';
    public updatingPhone: boolean = false;
    @ViewChild(IntlTelInputDirective, { static: false }) intlTelInputDirective!: IntlTelInputDirective;
    public phoneInvalid: boolean = false;
    public user: any;

    constructor(
        private api: ApiService,
        public dialogRef: MatDialogRef<UpdatePhoneComponent>,
    ) {
        this.user = JSON.parse(localStorage.getItem('user') || '{}');
        if (this.user.phone_number) {
            this.phoneNumber = this.user.phone_number;
        } else {
            this.phoneNumber = '+1';
        }
    }

    updatePhone(e: any) {
        this.phoneNumber = e.target.value;
    }
    phoneDirty() {
        if (this.user.phone_number) {
            return this.phoneNumber !== this.user.phone_number;
        } else {
            return this.phoneNumber !== '+1';
        }
    }

    savePhone() {
        this.updatingPhone = true;

        if (!this.intlTelInputDirective.isValidNumber()) {
            if (this.phoneDirty() && this.phoneNumber !== '+1' && this.phoneNumber !== '') {
                this.phoneInvalid = true;
                setTimeout(() => {
                    this.phoneInvalid = false;
                }, 4000);
                this.updatingPhone = false;
                return;
            } else {
                this.phoneNumber = '';
            }
        }

        this.api
            .put(`user/${this.user.id}`, {
                firstName: this.user.first_name,
                lastName: this.user.last_name,
                smsNotification: this.user.sms_notification,
                downloadOriginal: this.user.download_original,
                country: this.user.country,
                email: this.user.email,
                phoneNumber: this.phoneNumber,
                realEstateGroup: this.user.real_estate_group || '',
            })
            .then(
                (res) => {
                    localStorage.setItem("user", JSON.stringify(res));
                    this.user = res;
                    this.updatingPhone = false;
                    this.closeDialog();
                },
            );
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
