<div
    class="wrapper"
    [ngClass]="breakpointService.currentBreakpointClass"
    *ngIf="!loading">
    <div class="left">
        <h2>Profile settings</h2>
        <div class="section">
            <div class="avatar">
                <img
                    [src]="avatar || 'assets/profile.png'"
                    alt="Avatar" />
                <input
                    hidden
                    type="file"
                    (change)="uploadAvatar($event)"
                    accept="image/*"
                    id="avatar" />
                <div class="actions">
                    <label
                        class="change-avatar"
                        matTooltip="Upload new avatar"
                        matTooltipPosition="above"
                        for="avatar">
                        <mat-icon svgIcon="edit" />
                    </label>
                    <button
                        class="delete-avatar"
                        matTooltip="Delete avatar"
                        (click)="deleteAvatar()">
                        <mat-icon svgIcon="remove" />
                    </button>
                </div>
            </div>
        </div>
        <div class="section">
            <div
                class="first-name label-input"
                [ngClass]="{ invalid: firstName.length === 0 }">
                <label for="first-name"><h4>First name</h4></label>
                <input
                    [(ngModel)]="firstName"
                    type="text"
                    id="first-name" />
            </div>
            <div
                class="last-name label-input"
                [ngClass]="{ invalid: lastName.length === 0 }">
                <label for="last-name"><h4>Last name</h4></label>
                <input
                    [(ngModel)]="lastName"
                    type="text"
                    id="last-name" />
            </div>
        </div>
        <div class="section">
            <div class="checkbox">
                <input
                    hidden
                    [(ngModel)]="enableSms"
                    type="checkbox"
                    name="text-message"
                    id="text-message" />
                <label for="text-message"
                    ><span class="checkmark"
                        ><mat-icon
                            svgIcon="blueCheckmark2"
                            *ngIf="enableSms" /></span
                    >Enable text message notifications for project
                    completion.</label
                >
            </div>
        </div>
        <div class="section">
            <div
                class="phone-number label-input"
                [ngClass]="{
                    invalid: phone && phone.length === 0 && enableSms
                }"
                [class.invalid-format]="phoneInvalid">
                <label for="phone-number"><h4>Phone number</h4></label>
                <input
                    (input)="updatePhone($event)"
                    [value]="phone"
                    appIntlTelInput
                    [appIntlTelInput]="phone"
                    (countryCodeChange)="onCountryCodeChange($event)"
                    pattern="^[0-9+]*$"
                    type="tel"
                    id="phone-number" />
            </div>
            <div
                class="email label-input"
                [ngClass]="{ invalid: !validateEmail() }">
                <label for="email"><h4>Email</h4></label>
                <input
                    [(ngModel)]="email"
                    type="text"
                    id="email" />
            </div>
        </div>
        <div class="section">
            <div class="company-name label-input">
                <label for="company-name"><h4>Company name</h4></label>
                <input
                    [(ngModel)]="company"
                    type="text"
                    id="company-name" />
            </div>
        </div>
        <div class="email-cc">
            <h4>Keep Your Team Informed</h4>
            <p>
                You can add two email addresses as CC on your projects to
                receive all notification emails, but they won't have access to
                your account, only updates.
            </p>
            <div class="inputs">
                <div class="email1">
                    <label for="cc-email1"><h4>Email 1</h4></label>
                    <input
                        [(ngModel)]="ccEmail1"
                        type="text"
                        id="cc-email1" />
                </div>
                <div class="email2">
                    <label for="cc-email2"><h4>Email 2</h4></label>
                    <input
                        [(ngModel)]="ccEmail2"
                        type="text"
                        id="cc-email2" />
                </div>
            </div>
        </div>
        <div>
            <button
                [disabled]="updating || !dirtyProfile() || invalidProfile()"
                [ngClass]="{ updated: updatedProfile }"
                (click)="updateProfile()">
                {{ updatedProfile ? "Updated" : "Update" }}
            </button>
        </div>
    </div>
    <div class="right">
        <h2>Login settings</h2>
        <div class="section password-change">
            <div
                class="old-password label-input"
                [ngClass]="{
                    invalid: !oldPassword && invalidLogin,
                    incorrect: invalidLogin && incorrectPassword && !oldPassword
                }">
                <label for="old-password"><h4>Old password</h4></label>
                <input
                    [(ngModel)]="oldPassword"
                    type="password"
                    autocomplete="password"
                    id="old-password" />
            </div>
            <div
                class="new-password label-input"
                [ngClass]="{ invalid: !newPassword && invalidLogin }">
                <label for="new-password"><h4>New password</h4></label>
                <input
                    [(ngModel)]="newPassword"
                    type="password"
                    autocomplete="new-password"
                    id="new-password" />
            </div>
            <div
                class="confirm-password label-input"
                [ngClass]="{
                    invalid: !confirmPassword && invalidLogin,
                    nomatch: newPassword !== confirmPassword && confirmPassword
                }">
                <label for="confirm-password"><h4>Confirm password</h4></label>
                <input
                    [(ngModel)]="confirmPassword"
                    type="password"
                    autocomplete="new-password"
                    id="confirm-password" />
            </div>
        </div>
        <div class="section">
            <button
                [disabled]="updating || invalidPassword()"
                [ngClass]="{ updated: updatedPassword }"
                (click)="updatePassword()">
                {{ updatedPassword ? "Updated" : "Update" }}
            </button>
        </div>
    </div>
</div>
