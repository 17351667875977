import { Component, ViewContainerRef, ViewChild, ComponentRef } from '@angular/core';
import { DoneComponent } from './done/done.component';
import { InReviewComponent } from './in-review/in-review.component';
import { CanceledComponent } from './canceled/canceled.component';
import { ApiService } from '../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { PixelService } from '../services/pixel.service';
import { BingService } from '../services/bing.service';

@Component({
    selector: 'app-delivery',
    templateUrl: './delivery.component.html',
    styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent {
    @ViewChild('pageAnchor', { read: ViewContainerRef }) pageAnchor!: ViewContainerRef;
    private componentRef: ComponentRef<any> | undefined;
    public project: any;
    public projectGuid: any;
    public user: any;
    public mode: any;
    public users: any;
    public articles: any;

    constructor(
        private api: ApiService,
        private route: ActivatedRoute,
        private titleService: Title,
        private pixel: PixelService,
        private bing: BingService
    ) {
        this.user = JSON.parse(localStorage.getItem('user') || '{}');
        this.projectGuid = this.route.snapshot.paramMap.get('guid');
        Promise.all([
            this.api.get(`project/${this.projectGuid}`),
            this.api.get('blogs?active=true')
        ]).then(async ([projectData, articles]) => {
            let { project, mode, users } = projectData;
            this.articles = articles;
            this.project = project;
            this.mode = mode;
            this.users = users;
            const credits = localStorage.getItem("credits") || 0;
            const card_payment = localStorage.getItem("card_payment") === "1";
            const paid = await this.api.get(`project/${this.projectGuid}/check_if_paid`);
            const fp2Requests = await this.api.get(`project/${this.projectGuid}/floorplans`);
            let fp2Status = null;
            if (this.project.floor_plan_token) {
                fp2Status = await this.api.get(`project/${this.projectGuid}/status/floorplan`);
            }
            this.project.floorplan_status = fp2Status;
            this.project.floorplan_requests = fp2Requests;
            this.titleService.setTitle(`${this.project.name} - Delivery`);
            if (paid || card_payment) {
                localStorage.removeItem("card_payment");
                this.sendProjectSalesData(credits);
            }
            this.selectComponent();
            localStorage.removeItem(`fpBumpGuidelines-${this.projectGuid || this.project.guid}`);
            console.log(this.project);
        });
    }

    sendProjectSalesData(credits: any) {
        const total = this.calculateTotal(this.project) - 24 * credits;
        this.pixel.trackEvent("Purchase", {
            value: total,
            currency: "USD",
        });
        this.bing.trackEvent("purchase", {
            revenue_value: total > 0 ? total : 0,
            currency: "USD",
        });
        if (credits > 0) {
            this.pixel.trackEvent("creditsUsed", {
                value: credits,
                currency: "USD",
            });
            this.bing.trackEvent("purchase", {
                event_label: "credits",
                revenue_value: credits > 0 ? credits : 0,
                currency: "USD",
            });
        }
    }

    calculateTotal(project: any) {
        let price = 0;
        project.service_requests.forEach((request: any) => (price += request.price));
        return price;
    }

    selectComponent() {
        let component: any;
        switch (this.project.status_code) {
            case 'done':
                component = DoneComponent;
                break;
            case 'rework':
                component = DoneComponent;
                break;
            case 'in-review':
                component = InReviewComponent;
                break;
            case 'in-progress':
                if (this.project.is_rework || this.project.floorplan_status === 'IN_PROGRESS' && this.project.floor_plan_approved) {
                    component = DoneComponent;
                } else {
                    component = InReviewComponent;
                }
                break;
            case 'canceled':
                component = CanceledComponent;
                break;
        }

        if (this.pageAnchor) {
            this.componentRef = this.pageAnchor.createComponent(component);
            this.componentRef.instance.project = this.project;
            this.componentRef.instance.user = this.user;
            this.componentRef.instance.mode = this.mode;
            this.componentRef.instance.articles = this.articles;
        }
    }
}
