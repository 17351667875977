import { BaseLoginProvider, SocialUser } from "@abacritt/angularx-social-login";
import { environment } from "src/app/environments/environment";
import { BehaviorSubject } from "rxjs";

declare let google: any;

export class GoogleModifiedLoginProvider extends BaseLoginProvider {
    private webHook: string = environment.webHook;
    public static readonly PROVIDER_ID: string = "GOOGLE";

    private client: any;
    private auth2: any;
    private readonly _socialUser = new BehaviorSubject<SocialUser | null>(null);

    constructor(private clientId: string) {
        super();
    }

    initialize(autoLogin?: boolean): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                this.loadScript(
                    GoogleModifiedLoginProvider.PROVIDER_ID,
                    "https://accounts.google.com/gsi/client",
                    () => {
                        this.client = google.accounts.id.initialize({
                            client_id: this.clientId,
                            auto_select: false,
                            callback: "",
                            onerror: "",
                            itp_support: true,
                        });
                        this.auth2 = google.accounts.oauth2.initTokenClient({
                            client_id: this.clientId,
                            scope: "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
                            prompt: "select_account",
                            callback: "",
                            onerror: "",
                        });
                        resolve();
                    }
                );
            } catch (err) {
                reject(err);
            }
        });
    }

    private createSocialUser(payload: any) {
        const user = new SocialUser();
        user.id = payload.sub;
        user.name = payload.name;
        user.email = payload.email;
        user.firstName = payload.given_name || payload.name.split(" ")[0];
        user.lastName = payload.family_name || payload.name.split(" ")[1];
        user.photoUrl = payload.picture;
        return user;
    }



    public getLoginStatus(): Promise<SocialUser> {
        return new Promise((resolve, reject) => {
            if (this._socialUser.value) {
                resolve(this._socialUser.value);
            } else {
                reject(
                    "No user is currently logged in with " +
                    GoogleModifiedLoginProvider.PROVIDER_ID
                );
            }
        });
    }

    public signIn(): Promise<SocialUser> {
        this.auth2.requestAccessToken();
        return new Promise((resolve, reject) => {
            this.auth2.callback = ({ access_token }: any) => {
                fetch(
                    `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${access_token}`
                )
                    .then((response) => response.json())
                    .then((data) => {
                        const socialUser = this.createSocialUser(data);
                        this._socialUser.next(socialUser);
                        resolve(socialUser);
                    });
            };
            this.auth2.onerror = (err: any) => reject(err);
        });
    }

    async signOut(): Promise<void> {
        google.accounts.id.disableAutoSelect();
        this._socialUser.next(null);
    }

    report(error: any) {
        const message = {
            channel: "#app-logs-angular",
            text: "Login error",
            attachments: [
                {
                    author_name: "Google login",
                    color: "danger",
                    title: "Trace",
                    text: error,
                },
            ],
        };
        fetch(this.webHook, {
            method: "POST",
            headers: {
                "Content-type": "application/x-www-form-urlencoded",
                Accept: "application/json, text/plain, */*",
            },
            body: JSON.stringify(message),
        });
    }
}
