import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { forkJoin } from 'rxjs';

export const newProjectResolver: ResolveFn<any> = (route, state) => {
    const apiService = inject(ApiService);

    const settingsTasks$ = apiService.get("settings/tasks", {});
    const userRoomTypeSettings$ = apiService.get("user_settings", { type: "roomType", active: "1" });
    const userFurnitureStyleSettings$ = apiService.get("user_settings", { type: "furnitureStyle", active: "1" });
    const wallet$ = apiService.get("wallet/balance");
    return forkJoin([settingsTasks$, userRoomTypeSettings$, userFurnitureStyleSettings$, wallet$]);
};
