import { Injectable } from '@angular/core';
import { intersection } from 'lodash';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public redirectUrl: string = 'projects';
    private user: any;
    private authStatus = new BehaviorSubject<boolean>(this.checkLogin());

    constructor(
        private api: ApiService,
        private userService: UserService
    ) { }

    updateAuthStatus() {
        this.authStatus.next(this.checkLogin());
    }

    getAuthStatus() {
        return this.authStatus.asObservable();
    }

    async login(data: any) {
        try {
            const login = await this.api.post('login_check', data);

            localStorage.setItem('refreshToken', login.refresh_token);
            localStorage.setItem('authToken', 'Bearer ' + login.token);
            this.api.updateHeaders();

            this.user = await this.api.get('user');
            this.userService.updateUser(this.user);

            localStorage.setItem('user', JSON.stringify(this.user));
            this.updateAuthStatus();
            return this.redirectUrl
                ? `${this.redirectUrl}?email=${this.user.email}`
                : `projects?email=${this.user.email}`;
        } catch (error) {
            console.error('Login failed', error);
            throw error;
        }
    }

    async loginSocialUser(data: any, pixel: any, bing: any) {
        data.append("website", "VirtualStaging");
        const login = await this.api.post('login/social', data);
        localStorage.setItem('authToken', 'Bearer ' + login.token);
        this.api.updateHeaders();

        this.user = await this.api.get('user');
        this.userService.updateUser(this.user);

        localStorage.setItem('user', JSON.stringify(this.user));
        if (login.new) {
            pixel.trackEvent('CompleteRegistration');
            bing.trackEvent('subscribe');
        }
        this.updateAuthStatus();
        return this.redirectUrl ? `${this.redirectUrl}?email=${this.user.email}` : `projects?email=${this.user.email}`;
    }

    async register(data: any) {
        const register = await this.api.post('user/register', data);

        return register;
    }

    logout() {
        this.clearData();
        this.api.updateHeaders();
        this.updateAuthStatus();
    }

    checkLogin() {
        const user = localStorage.getItem('user');
        if (!user || user === 'undefined') {
            return false;
        }

        if (localStorage.getItem('authToken') && user) {
            this.api.updateHeaders();
            return true;
        }

        return false;
    }

    checkRole(roles: any[]) {
        const localUser: any = localStorage.getItem('user');
        if (!this.checkLogin()) {
            this.logout();
        }
        if (!roles) {
            return true;
        }

        if (typeof localUser === 'string') {
            this.user = JSON.parse(localUser);
        }

        return this.user ? intersection(roles, this.user.roles).length : false;
    }

    clearData() {
        localStorage.clear();
        this.api.updateHeaders();
    }
}
