<div
    class="wrapper"
    [ngClass]="breakpointService.currentBreakpointClass">
    <div class="section">
        <h1>
            Give Free Virtual Staging,<br />
            Get $100
        </h1>
        <img
            [src]="getImgUrl()"
            alt="" />
        <p>{{ getDescription() }}</p>
        <div class="image-triggers">
            <div
                class="radio-input"
                *ngFor="let button of [1, 2, 3, 4]">
                <input
                    type="radio"
                    [(ngModel)]="currentStep"
                    [value]="button"
                    name="referral-program"
                    id="referral-program-{{ button }}"
                    hidden />
                <label for="referral-program-{{ button }}"></label>
            </div>
        </div>
    </div>
    <div
        class="section"
        *ngIf="emailInvites === false">
        <div class="link">
            <h4>Copy and share your personal invite link...</h4>
            <div class="copy-link">
                <div class="link-box">
                    <p>
                        {{ linkToShare }}
                    </p>
                </div>
                <button
                    class="primary"
                    [ngClass]="{ copied: copied }"
                    (click)="copyLinkToShare()">
                    {{ copied ? "Copied!" : "Copy link" }}
                </button>
            </div>
        </div>
        <div class="social-media">
            <h4>Share via social media</h4>
            <div class="social-media-icons">
                <button
                    class="fb"
                    (click)="shareFb()">
                    <mat-icon svgIcon="fb" />
                </button>
                <button
                    class="x"
                    (click)="shareX()">
                    <mat-icon svgIcon="x" />
                </button>
                or
                <button
                    class="email"
                    (click)="emailInvites = true">
                    Invite via email
                </button>
            </div>
        </div>
    </div>
    <div
        class="section email-invites"
        *ngIf="emailInvites === true">
        <h2>Invite Email</h2>
        <div class="add-email">
            <p>Enter your friend's email address:</p>
            <div
                class="email-input"
                [class.invalid]="emailInvalid">
                <input
                    #emailInput
                    type="email"
                    [(ngModel)]="recipientEmail"
                    name="email"
                    placeholder="Email address"
                    required />
                <button
                    class="primary"
                    (click)="addRecipient()">
                    Add
                </button>
            </div>
            <div class="emails">
                <div
                    class="email"
                    *ngFor="let email of recipients">
                    <p>{{ email }}</p>
                    <button
                        class="x"
                        (click)="removeRecipient(email)">
                        <mat-icon svgIcon="xMark" />
                    </button>
                </div>
            </div>
        </div>
        <div class="email-actions">
            <button
                class="transparent"
                (click)="previewEmailTemplate()">
                Preview Email
            </button>
            <button
                class="primary"
                [disabled]="recipients.length === 0"
                [class.sent]="emailsSent"
                (click)="sendInvites()">
                {{ emailsSent ? "Sent!" : "Send Invites" }}
            </button>
        </div>
        <button
            (click)="emailInvites = false"
            class="switch-to-social">
            Share another way
        </button>
    </div>
</div>
