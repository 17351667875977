<div
    class="wrapper"
    [class]="breakpointService.currentBreakpointClass">
    <button
        class="close"
        (click)="clickEvent()">
        <mat-icon svgIcon="xMark" />
    </button>
    <div class="header">
        <h1>Add Disclaimer</h1>
        <p>
            Enter the disclaimer text you want to add and select its position on
            the image.
        </p>
    </div>
    <div class="positions">
        <div class="positions-box">
            <div
                class="position"
                [ngClass]="i > 2 ? 'second-row' : ''"
                *ngFor="let position of positions; let i = index">
                <input
                    hidden
                    type="radio"
                    name="positionGroup"
                    [(ngModel)]="selectedPosition"
                    [value]="position"
                    [id]="position" />
                <label [for]="position"
                    ><mat-icon svgIcon="blueCheckmark2"
                /></label>
            </div>
        </div>
        <div class="title">
            <mat-icon svgIcon="blueCheckmark" />
            <h4>
                {{ getPositionName(selectedPosition) }}
            </h4>
        </div>
    </div>
    <div class="watermark">
        <h4>
            Watermark text
            <span class="limit-indicator">{{ watermarkText.length }}/32</span>
        </h4>
        <input
            type="text"
            [(ngModel)]="watermarkText"
            placeholder="Enter your watermark text"
            id="watermark-text"
            maxlength="32"
            autocomplete="off" />
        <div class="option">
            <input
                type="checkbox"
                name="email-results"
                [(ngModel)]="emailResults"
                hidden
                id="email-results" />
            <label for="email-results"
                ><span class="checkmark"
                    ><mat-icon
                        svgIcon="blueCheckmark2"
                        *ngIf="emailResults"
                /></span>
                Send files via email. You can safely leave the page and we'll
                email you when the download is ready.
            </label>
        </div>
        <div class="option mb">
            <input
                type="checkbox"
                name="compress-images"
                [(ngModel)]="compressImages"
                hidden
                id="compress-images" />
            <label for="compress-images"
                ><span class="checkmark"
                    ><mat-icon
                        svgIcon="blueCheckmark2"
                        *ngIf="compressImages"
                /></span>
                Compress images to reduce file size without changing resolution,
                maintaining quality with minimal loss.
            </label>
        </div>
        <button
            (click)="startLoading()"
            [disabled]="isLoading">
            {{ isLoading ? "Generating and downloading" : "Download all images"
            }}<img
                src="/assets/rolling.gif"
                *ngIf="isLoading" />
        </button>
    </div>
</div>
