import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
} from "@angular/core";

import { ApiService } from "src/app/services/api.service";
import { environment } from "src/app/environments/environment";
import { MatDialogRef } from "@angular/material/dialog";
import { BreakpointService } from "src/app/services/breakpoint.service";

declare global {
    interface Window {
        ReactNativeWebView: any;
    }
}
window.ReactNativeWebView = window.ReactNativeWebView || null;

@Component({
    selector: "watermark",
    templateUrl: "./watermark.component.html",
    styleUrls: ["./watermark.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WatermarkComponent {
    @Input() project: any;
    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

    public popupOpen: boolean = false;
    public isLoading: boolean = false;
    public cdn: string = environment.cdn;
    public isWebView = window.ReactNativeWebView !== null;
    public positions = ["topLeft", "centerTop", "topRight", "bottomLeft", "centerBottom", "bottomRight"];
    public selectedPosition = "centerBottom";
    public watermarkText = "";
    public compressImages = false;
    public emailResults = false;


    constructor(
        private api: ApiService,
        private ref: ChangeDetectorRef,
        private dialogRef: MatDialogRef<WatermarkComponent>,
        public breakpointService: BreakpointService
    ) { }

    render() {
        this.ref.detectChanges();
    }

    async startLoading() {
        this.isLoading = true;
        let options: any = {
            message: this.watermarkText.length > 0 ? this.watermarkText : 'Virtually Staged',
            watermarkPosition: this.selectedPosition,
            compress: this.compressImages,
        }
        if (this.emailResults) {
            options.async = true;
        }
        const location = await this.api.post(
            `assets/upload/${this.project.guid}/watermark`,
            options
        );
        this.isLoading = false;
        if (location === 200) {
            this.dialogRef.close();
            return;
        }
        const file = encodeURIComponent(location.split("/").pop());
        const url = environment.downloadServer + "download/" + location;
        this.openWindowInSameTab(
            `${environment.downloadServer}download/file/streamed?url=${url}&filename=${file}&t=${new Date().getTime()}`
        );
        this.dialogRef.close();
    }

    clickEvent(): void {
        this.onClick.emit();
    }

    openWindowInSameTab(url: string) {
        const newwindow = window.open(url, "_self");
        newwindow?.focus();
    }

    getPositionName(str: string) {
        let words = str.split(/(?=[A-Z])/);
        return words.map((word, index) => {
            if (index === 0) {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            } else {
                return word.toLowerCase();
            }
        }).join(" ");
    }
}
