import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { BingService } from '../services/bing.service';
import { PixelService } from '../services/pixel.service';

@Component({
    selector: 'success',
    templateUrl: './success.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {

    constructor(
        private api: ApiService,
        private router: Router,
        private bing: BingService,
        private pixel: PixelService
    ) { }

    async ngOnInit() {
        const amount = await this.api.post('wallet/confirmPayment');
        this.pixel.trackEvent('Purchase', {
            value: amount,
            currency: 'USD',
        });
        this.bing.trackEvent('purchase', {
            revenue_value: amount > 0 ? amount : 0,
            currency: 'USD',
        });
        const redirect_to = localStorage.getItem('redirect_to');
        if (redirect_to) {
            window.location.href = redirect_to;
        }
        return this.router.navigate(['/projects']);
    }

}
