import { Component, Output, EventEmitter } from '@angular/core';
import { BreakpointService } from 'src/app/services/breakpoint.service';

@Component({
    selector: 'app-ie-info',
    templateUrl: './ie-info.component.html',
    styleUrls: ['./ie-info.component.scss']
})
export class IeInfoComponent {
    public activeTab: number = 1;
    public comments: string = '';
    public serviceRequest: any;
    public service: any;
    public task: any;
    public projectGuid: string = '';
    public changed: (dirty: boolean) => void = () => { };
    public hardCopy: any;
    public isPp: boolean = false;
    public expanded: any = {
        comments: false,
    }

    @Output() serviceInfo = new EventEmitter<any>();
    public triggerServiceInfoEvent(): void {
        this.serviceInfo.emit(this.service);
        this.hardCopy = JSON.stringify(this.service);
    }

    constructor(
        public breakpointService: BreakpointService
    ) { }

    ngOnInit() {
        this.initiateService();
        this.hardCopy = JSON.stringify(this.service);
    }

    initiateService() {
        if (this.serviceRequest.service_request_tasks?.length > 0) {
            let service = this.serviceRequest.service_request_tasks.find((service: any) => service.settings_task.id === this.task.id);
            if (service) {
                this.service = service;
            } else {
                this.setService();
            }
        } else {
            this.setService();
        }
    }

    setService() {
        this.service = {
            name: this.task.name,
            price: this.task.price,
            settings_task: {
                id: this.task.id
            },
            sort: this.task.sort,
            data: {
                imageData: [],
                description: '',
            },
            reference_assets: [],

        }
    }

    addReferenceImage(event: any) {
        if (this.service.reference_assets) {
            this.service.reference_assets.push(event);
        } else {
            this.service.reference_assets = [event];
        }
        this.checkIfNeedsToSave();
    }
    removeReferenceImage(id: any) {
        this.service.reference_assets = this.service.reference_assets.filter((image: any) => image.id !== id);
        this.checkIfNeedsToSave();
    }
    checkIfNeedsToSave() {
        if (JSON.stringify(this.service) !== this.hardCopy) {
            this.changed(true);
        } else {
            this.changed(false);
        }
    }

    validateService(): boolean {
        return true;
    }

    isValid(): boolean {
        return true;
    }
}
