import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { HammerModule } from '@angular/platform-browser';
import { BreakpointService } from 'src/app/services/breakpoint.service';

@Component({
    selector: 'app-latest',
    standalone: true,
    imports: [
        MatIconModule,
        CommonModule,
        HammerModule
    ],
    templateUrl: './latest.component.html',
    styleUrl: './latest.component.scss'
})
export class LatestComponent {
    @Input() articles: any = {};
    @ViewChild('articlesWrapper') articlesWrapper!: ElementRef;
    @ViewChild('wrapper') wrapper!: ElementRef;
    @ViewChild('scrollWrapper') scrollWrapper!: ElementRef;
    public showSwipe: boolean = true;

    constructor(
        public breakpointService: BreakpointService
    ) { }

    ngOnInit(): void {
        this.showSwipe = localStorage.getItem('showSwipe') === 'false' ? false : true;
    }

    slide(direction: number): void {
        this.scrollWrapper.nativeElement.scrollLeft += direction * 384;
    }

    learnedSwipe() {
        localStorage.setItem('showSwipe', 'false');
        this.showSwipe = false;
        this.slide(1);
    }
}
