import { Component } from '@angular/core';
import { IntlTelInputDirective } from 'src/app/directives/intl-tel-input.directive';
import { ApiService } from 'src/app/services/api.service';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@Component({
    selector: 'app-update-company',
    standalone: true,
    imports: [
        CommonModule,
        IntlTelInputDirective,
        ReactiveFormsModule,
        FormsModule
    ],
    templateUrl: './update-company.component.html',
    styleUrl: './update-company.component.scss'
})

export class UpdateCompanyComponent {
    public companyName = '';
    public updatingCompany: boolean = false;
    public companyInvalid: boolean = false;
    public user: any;

    constructor(
        private api: ApiService,
        public dialogRef: MatDialogRef<UpdateCompanyComponent>,
    ) {
        this.user = JSON.parse(localStorage.getItem('user') || '{}');
        if (this.user.real_estate_group) {
            this.companyName = this.user.real_estate_group;
        }
    }

    saveCompany() {
        this.updatingCompany = true;

        if (this.companyName === '') {
            this.companyInvalid = true;
            setTimeout(() => {
                this.companyInvalid = false;
            }, 4000);
            this.updatingCompany = false;
            return;
        }

        this.api
            .put(`user/${this.user.id}`, {
                firstName: this.user.first_name,
                lastName: this.user.last_name,
                smsNotification: this.user.sms_notification,
                downloadOriginal: this.user.download_original,
                country: this.user.country,
                email: this.user.email,
                phoneNumber: this.user.phone_number,
                realEstateGroup: this.companyName || '',
            })
            .then(
                (res) => {
                    localStorage.setItem("user", JSON.stringify(res));
                    this.user = res;
                    this.updatingCompany = false;
                    this.closeDialog(true);
                },
            );
    }

    closeDialog(success: boolean) {
        this.dialogRef.close(success);
    }
}
