import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { CanActivateFn } from '@angular/router';

export const authGuard: CanActivateFn = (route, state) => {
    const router = inject(Router);
    const url: string = state.url;

    const localUser: any = localStorage.getItem('user');
    if (typeof localUser === 'string') {
        const user = JSON.parse(localUser);

        if (user.roles && user.roles.includes('ROLE_LOCK')) {
            router.navigate(['/blank-page']);
            return false;
        }

        if (user.roles && user.roles.includes('ROLE_ORDER_LOCK')) {
            if (url === '/new-project' || (url.startsWith('/projects/') && url.endsWith('/summary'))) {
                router.navigate(['/overdue']);
                return false;
            }
        }

        if (route.data['restricted'] && route.data['restricted'].includes(user.email)) {
            return false;
        }
    } else {
        router.navigate(['/login']);
        return false;
    }

    if (!checkLogin(url)) {
        router.navigate(['/login']);
        return false;
    }
    return true;
};

function checkLogin(url: string): boolean {
    const authService = inject(AuthService);
    const router = inject(Router);
    if (authService.checkLogin()) {
        return true;
    }

    if (url.indexOf('login') === -1) {
        authService.redirectUrl = url.split('?email=')[0];
    }

    router.navigate(['login']);
    return false;
}
