import { Component, Input, ViewChild, ViewChildren, QueryList, Output, EventEmitter, Renderer2, ChangeDetectorRef } from '@angular/core';
import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { environment } from '../../environments/environment';
import { MatMenuTrigger } from '@angular/material/menu';


@Component({
    selector: 'app-annotations',
    templateUrl: './annotations.component.html',
    styleUrls: ['./annotations.component.scss']
})
export class AnnotationsComponent {
    @Input() annotations: any = [];
    @Input() imageHeight: string | null = '';
    @Input() serviceRequest: any;
    @Output() annotationsChange = new EventEmitter<any>();

    @ViewChild('annotationContainer') annotationContainer: any;
    @ViewChild('imageElement') imageElement: any;
    @ViewChildren(MatMenuTrigger) triggers?: QueryList<MatMenuTrigger>;

    dragging: boolean = false;

    constructor(
        private renderer: Renderer2,
        private cdr: ChangeDetectorRef,
    ) { }


    ngAfterViewInit() {
        const img = this.imageElement.nativeElement;
        if (img.complete) {
            this.processImage(img);
        } else {
            img.onload = () => {
                this.processImage(img);
            };
        }
    }

    processImage(img: HTMLImageElement) {
        const aspectRatio = img.naturalWidth / img.naturalHeight;
        if (aspectRatio < 1) {
            this.renderer.setStyle(this.imageElement.nativeElement, 'max-height', this.imageHeight);
        }
    }

    createAnnotation(event: any) {
        if (!this.annotations) {
            this.annotations = [];
        }
        let annotationContainer = this.annotationContainer.nativeElement;
        const x = (100 * event.offsetX) / annotationContainer.offsetWidth;
        const y = (100 * event.offsetY) / annotationContainer.offsetHeight;
        let annotation = {
            id: (this.annotations?.length || 0) + 1,
            positionX: x,
            positionY: y,
            textValue: '',
        };
        this.annotations.push(annotation);
        this.cdr.detectChanges();
        this.triggers?.last.openMenu();
        this.annotationsChange.emit(this.annotations);
    }

    getPositionStyle(annotation: any) {
        return {
            top: `calc(${annotation.positionY}% - 15px)`,
            left: `calc(${annotation.positionX}% - 15px)`,
        };
    }

    deleteAnnotation(annotation: any) {
        this.annotations = this.annotations.filter((a: any) => a.id !== annotation.id);
        // update ids of annotations that come after the deleted one
        this.annotations = this.annotations.map((a: any, index: number) => {
            a.id = index + 1;
            return a;
        });
        this.annotationsChange.emit(this.annotations);
    }

    saveAnnotation(annotation: any) {
        this.annotations = this.annotations.map((a: any) => {
            if (a.id === annotation.id) {
                return annotation;
            }
            return a;
        });
    }

    drop(event: CdkDragEnd) {
        let target = event.source.getRootElement();
        let annotationContainer = this.annotationContainer.nativeElement;
        // annotation ID is in format 'annotation-{id}', get the id
        let annotationId = target.id.split('-')[1];
        let annotation = this.annotations.find((a: any) => a.id === parseInt(annotationId));

        // calculate how many % of the container the annotation is moved
        const x = (100 * event.distance.x) / annotationContainer.offsetWidth;
        const y = (100 * event.distance.y) / annotationContainer.offsetHeight;

        // update annotation position
        annotation.positionX += x;
        annotation.positionY += y;

        // reset cdkdrag
        event.source.reset();

        this.annotationsChange.emit(this.annotations);
    }

    openContextMenu(event: MouseEvent, trigger: any) {
        if (this.dragging) {
            this.dragging = false;
            trigger.closeMenu();
            return;
        } else {
            event.preventDefault();
            trigger.openMenu();
        }
    }

    dragStart() {
        this.dragging = true;
    }

    addActiveClass(trigger: any) {
        this.renderer.addClass(trigger._element.nativeElement, 'active');
    }

    removeActiveClass(trigger: any) {
        this.renderer.removeClass(trigger._element.nativeElement, 'active');
    }

    getUrl() {
        let url: string;
        if (this.serviceRequest.final_asset) {
            url = environment.cdn + this.serviceRequest.final_asset.url;
        } else {
            url = environment.cdn + this.serviceRequest.asset.url;
        }
        return environment.server + "/asset/thumbnail/" + url.split("/").slice(-1)[0];
    }
}
