import { Injectable } from '@angular/core';
import {
    BreakpointObserver,
    BreakpointState,
    Breakpoints,
} from '@angular/cdk/layout';

const AppleDevice =
    '(min-width: 1440px) and (max-width: 4096px) and (min-aspect-ratio: 4/3) and (max-aspect-ratio: 16/10)';
const WideScreen = '(min-aspect-ratio: 21/9) and (min-width: 1922px)';
const NormalScreen = '(min-width: 1280px) and (max-width: 1921px)';
const customPortrait = '(min-width: 840px) and (max-width: 1024px) and (min-height: 840px) and (max-height: 1366px) and (orientation: portrait)';

@Injectable({ providedIn: 'root' })
export class BreakpointService {
    public currentBreakpointClass: string = '';
    public isTouchDevice: boolean = false;

    constructor(private breakpointObserver: BreakpointObserver) {
        this.breakpointObserver
            .observe([
                Breakpoints.HandsetPortrait,
                Breakpoints.HandsetLandscape,
                Breakpoints.TabletPortrait,
                Breakpoints.TabletLandscape,
                WideScreen,
                NormalScreen,
                Breakpoints.Large,
                AppleDevice,
                customPortrait
            ])
            .subscribe((result) => {
                this.updateCurrentBreakpointClass(result);
            });
    }

    updateCurrentBreakpointClass(result: BreakpointState) {
        if (result.breakpoints[Breakpoints.HandsetPortrait]) {
            this.currentBreakpointClass = 'is-phone-portrait';
            this.isTouchDevice = true;
        } else if (result.breakpoints[Breakpoints.HandsetLandscape]) {
            this.currentBreakpointClass = 'is-phone-landscape';
            this.isTouchDevice = true;
        } else if (result.breakpoints[Breakpoints.TabletLandscape]) {
            this.currentBreakpointClass = 'is-tablet-landscape';
            this.isTouchDevice = true;
        } else if (result.breakpoints[WideScreen]) {
            this.currentBreakpointClass = 'is-wide-screen';
            this.isTouchDevice = false;
        } else if (result.breakpoints[NormalScreen]) {
            this.currentBreakpointClass = 'is-normal-screen';
            this.isTouchDevice = false;
        } else if (result.breakpoints[Breakpoints.TabletPortrait]) {
            this.currentBreakpointClass = 'is-tablet-portrait';
            this.isTouchDevice = true;
        } else if (result.breakpoints[AppleDevice]) {
            this.currentBreakpointClass = 'is-apple-desktop';
            this.isTouchDevice = false;
        } else if (result.breakpoints[customPortrait]) {
            this.currentBreakpointClass = 'is-custom-portrait';
            this.isTouchDevice = true;
        } else {
            this.currentBreakpointClass = '';
            this.isTouchDevice = false;
        }
    }
}
