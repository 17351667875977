import { Component, ViewChild, HostListener, ChangeDetectorRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { BreakpointService } from '../services/breakpoint.service';
import { ThumbnailService } from '../services/thumbnail.service';


@Component({
	selector: 'app-projects',
	templateUrl: './projects.component.html',
	styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent {
	public user: any;
	public allProjects: any = [];
	public visibleProjects: any = [];
	public view: string;
	private thumbnailSubscriptions: Subscription[] = [];
	@ViewChild('wrapper') wrapper: any;
	@ViewChild('mainGrid') mainGrid: any;
	public searchTerm: string = '';
	private searchTerms = new Subject<string>();

	constructor(
		private titleService: Title,
		private api: ApiService,
		public breakpointService: BreakpointService,
		private thumbnailService: ThumbnailService,
		private router: Router,
		private cdr: ChangeDetectorRef
	) {
		this.titleService.setTitle('Your Projects');
		this.user = JSON.parse(localStorage.getItem('user') || '{}');
		this.view = localStorage.getItem('view') || 'grid-view';
		this.listenToSearchTerms();
	}

	ngOnInit() {
		if (this.allProjects.length === 0) {
			Promise.all([
				this.api.get("projects/v2"),
				this.api.get("projects/v2/shared"),
			]).then(([projects, sharedProjects]) => {
				if (projects.length === 0 && sharedProjects.length === 0) {
					this.router.navigate(['/new-project'], { queryParams: { noProjects: true } });
				}
				for (let project of sharedProjects) {
					project.shared = true;
				}
				this.allProjects = [...sharedProjects, ...projects];
				this.allProjects.sort((a: any, b: any) => new Date(b.created).getTime() - new Date(a.created).getTime());
				this.setVisibleProjects(0, 12);
			}).catch((err) => {
				console.error(err);
				this.router.navigate(['/new-project'], { queryParams: { noProjects: true } });
			});
		} else {
			this.setVisibleProjects(0, 12);
		}
	}

	ngAfterViewInit() {
		if (this.view === 'grid-view') {
			this.setMainGridWidth();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if (this.view === 'grid-view') {
			this.setMainGridWidth();
		}
	}

	@HostListener('window:orientationchange', ['$event'])
	onOrientationChange(event: any) {
		if (this.view === 'grid-view') {
			this.setMainGridWidth();
		}
	}

	private listenToSearchTerms() {
		this.searchTerms.pipe(
			debounceTime(300)
		).subscribe(term => {
			this.filterProjects(term);
		});
	}

	setVisibleProjects(start: number, end: number, oldProjects: any = []) {
		this.visibleProjects = [...oldProjects, ...this.allProjects.slice(start, end)];
		this.visibleProjects.forEach((project: any, project_index: number) => {
			if (typeof project.thumbnails === 'object' && !Array.isArray(project.thumbnails)) {
				project.thumbnails = Object.values(project.thumbnails);
			}
			project.thumbnails = project.thumbnails.slice(0, 3);
			project.thumbnails.forEach((thumbnail: any, thumbnail_index: number) => {
				if (!thumbnail.processed) {
					this.loadThumbnailAndReplaceSrc(thumbnail, project_index, thumbnail_index);
				}
			});
		});
	}

	loadThumbnailAndReplaceSrc(thumbnail: any, project_index: number, thumbnail_index: number) {
		const thumbnailUrl = typeof thumbnail === 'string' ? thumbnail : thumbnail.url;
		this.visibleProjects[project_index].thumbnails[thumbnail_index] = {
			src: 'assets/blur.webp',
			url: thumbnailUrl,
		};

		const subscription = this.thumbnailService.enqueueThumbnail(thumbnailUrl)
			.subscribe(
				(data: any) => {
					this.visibleProjects[project_index].thumbnails[thumbnail_index] = {
						src: data,
						url: thumbnailUrl,
						processed: true
					};
				},
				(error: any) => {
					console.error('Error loading thumbnail:', error);
				}
			);

		this.thumbnailSubscriptions.push(subscription);
	}

	setMainGridWidth() {
		if (this.breakpointService.currentBreakpointClass === 'is-phone-portrait') {
			this.mainGrid.nativeElement.style.width = '100%';
			return;
		}
		const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
		let columnWidth = rootFontSize * 30;
		let gapWidth = rootFontSize * 2.4;
		const wrapperWidth = this.wrapper.nativeElement.offsetWidth;
		const columns = Math.floor((wrapperWidth + gapWidth) / (columnWidth + gapWidth));
		const mainGridWidth = columns * (columnWidth + gapWidth) - gapWidth;
		this.mainGrid.nativeElement.style.width = `${mainGridWidth}px`;
	}

	deleteProject(guid: any) {
		const updatedProjects = this.allProjects.filter((project: any) => project.guid !== guid);
		this.allProjects = [...updatedProjects];
		this.setVisibleProjects(0, 12);
	}

	loadMoreProjects() {
		this.cancelThumbnailLoading();
		this.thumbnailService.resetQueue();
		const currentLength = this.visibleProjects.length;
		this.setVisibleProjects(currentLength, currentLength + 12, this.visibleProjects);
	}

	showLoadMoreButton() {
		return this.visibleProjects.length < this.allProjects.length && this.searchTerm === '';
	}

	saveViewSettingToLocal() {
		localStorage.setItem('view', this.view);
		this.cdr.detectChanges();
		if (this.view === 'grid-view') {
			this.setMainGridWidth();
		}
	}

	gutTable(): boolean {
		return this.breakpointService.currentBreakpointClass === 'is-phone-portrait' || this.breakpointService.currentBreakpointClass === 'is-phone-landscape' || this.breakpointService.currentBreakpointClass === 'is-tablet-portrait' || this.breakpointService.currentBreakpointClass === 'is-custom-portrait' || this.breakpointService.currentBreakpointClass === 'is-tablet-landscape';
	}

	cancelThumbnailLoading() {
		this.thumbnailSubscriptions.forEach(sub => sub.unsubscribe());
		this.thumbnailSubscriptions = [];
	}

	onSearchTermChange(term: string) {
		this.searchTerms.next(term);
	}

	filterProjects(term: string) {
		if (term === '') {
			this.setVisibleProjects(0, 12);
		} else {
			const filteredProjects = this.allProjects.filter((project: any) => project.name?.toLowerCase().includes(term.toLowerCase()));
			this.setVisibleProjectsFromList(filteredProjects, 0, Math.min(filteredProjects.length, 12));
		}
	}

	setVisibleProjectsFromList(projectsList: any[], start: number, end: number) {
		this.visibleProjects = projectsList.slice(start, end);
		this.visibleProjects.forEach((project: any, project_index: number) => {
			if (typeof project.thumbnails === 'object' && !Array.isArray(project.thumbnails)) {
				project.thumbnails = Object.values(project.thumbnails);
			}
			project.thumbnails = project.thumbnails.slice(0, 3);
			project.thumbnails.forEach((thumbnail: any, thumbnail_index: number) => {
				if (!thumbnail.processed) {
					this.loadThumbnailAndReplaceSrc(thumbnail, project_index, thumbnail_index);
				}
			});
		});
	}
}
