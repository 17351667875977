import { Component, Output, EventEmitter } from '@angular/core';
import { BreakpointService } from 'src/app/services/breakpoint.service';

@Component({
    selector: 'app-fc-info',
    templateUrl: './fc-info.component.html',
    styleUrls: ['./fc-info.component.scss']
})
export class FcInfoComponent {
    public activeTab: number = 1;
    public comments: string = '';
    public serviceRequest: any;
    public service: any;
    public projectGuid: string = '';
    public task: any;
    @Output() serviceInfo = new EventEmitter<any>();
    public changed: (dirty: boolean) => void = () => { };
    public hardCopy: any;
    public isPp: boolean = false;
    public expanded: any = {
        comments: false,
    }

    public triggerServiceInfoEvent(): void {
        this.validateService();
        if (this.service.valid) {
            this.serviceInfo.emit(this.service);
            this.hardCopy = JSON.stringify(this.service);
        } else {
            this.indicateRequiredField('comment-wrapper');
        }
    }

    constructor(
        public breakpointService: BreakpointService
    ) { }

    ngOnInit() {
        this.initiateService();
        this.hardCopy = JSON.stringify(this.service);
    }

    initiateService() {
        if (this.serviceRequest.service_request_tasks?.length > 0) {
            let service = this.serviceRequest.service_request_tasks.find((service: any) => service.settings_task.id === this.task.id);
            if (service) {
                this.service = service;
            } else {
                this.setService();
            }
        } else {
            this.setService();
        }
    }

    setService() {
        this.service = {
            name: this.task.name,
            price: this.task.price,
            settings_task: {
                id: this.task.id
            },
            sort: this.task.sort,
            data: {
                imageData: [],
                description: '',
            },
            reference_assets: [],

        }
    }


    addReferenceImage(event: any) {
        if (this.service.reference_assets) {
            this.service.reference_assets.push(event);
        } else {
            this.service.reference_assets = [event];
        }
        this.checkIfNeedsToSave();
    }
    removeReferenceImage(id: any) {
        this.service.reference_assets = this.service.reference_assets.filter((image: any) => image.id !== id);
        this.checkIfNeedsToSave();
    }

    validateService(): boolean {
        if (this.service.data.description.trim() === '') {
            this.service = {
                ...this.service,
                valid: false,
            }
            return false;
        } else {
            this.service = {
                ...this.service,
                valid: true,
            }
            return true;
        }
    }

    isValid(): boolean {
        if (this.service.data.description.trim() === '') {
            return false;
        } else {
            return true;
        }
    }

    indicateRequiredField(id: string) {
        this.activeTab = 1;
        let el = document.getElementById(id);
        el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        // flash red border 3 times
        let count = 0;
        const interval = setInterval(() => {
            if (count < 3) {
                el?.classList.add('border-red');
                setTimeout(() => {
                    el?.classList.remove('border-red');
                }, 200);
            } else {
                clearInterval(interval);
            }
            count++;
        }, 400);
    }

    checkIfNeedsToSave() {
        if (JSON.stringify(this.service) !== this.hardCopy) {
            this.changed(true);
        } else {
            this.changed(false);
        }
    }
}
