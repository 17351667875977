<div
    class="wrapper"
    [class]="breakpointService.currentBreakpointClass">
    <button
        class="close"
        (click)="dialogRef.close()">
        <mat-icon svgIcon="xMark" />
    </button>
    <div class="tabs">
        <h2>Share your project</h2>
        <div
            class="tabs-list"
            [class.show-floorplan]="floorPlanToken">
            <div
                class="tab-item"
                [class.active]="selectedTab === 0">
                <label for="invite-others">
                    <mat-icon svgIcon="inviteOthers" />
                    <span>Invite {{ isPhone() ? "" : "others" }}</span>
                </label>
                <input
                    [(ngModel)]="selectedTab"
                    value="0"
                    type="radio"
                    id="invite-others"
                    name="tabs"
                    hidden />
            </div>
            <div
                class="tab-item"
                [class.active]="selectedTab === 1">
                <label for="share-publicly">
                    <mat-icon svgIcon="sharePublicly" />
                    <span>Share {{ isPhone() ? "" : "publicly" }}</span>
                </label>
                <input
                    [(ngModel)]="selectedTab"
                    value="1"
                    type="radio"
                    id="share-publicly"
                    name="tabs"
                    hidden />
            </div>
            <div
                class="tab-item"
                [class.active]="selectedTab === 2"
                #tabTooltip="matTooltip"
                (click)="project.is_public ? null : tabTooltip.toggle()"
                [matTooltip]="
                    project.is_public
                        ? ''
                        : 'Enable public sharing to embed your project'
                "
                [class.disabled]="!project.is_public">
                <label for="embed">
                    <mat-icon svgIcon="embed" />
                    <span>Embed</span>
                </label>
                <input
                    [(ngModel)]="selectedTab"
                    value="2"
                    type="radio"
                    id="embed"
                    name="tabs"
                    hidden />
            </div>
            <div
                *ngIf="floorPlanToken"
                class="tab-item"
                [class.active]="selectedTab === 3">
                <label for="share-fp">
                    <mat-icon svgIcon="fpView" />
                    <span>Share floor plan</span>
                </label>
                <input
                    [(ngModel)]="selectedTab"
                    value="3"
                    type="radio"
                    id="share-fp"
                    name="tabs"
                    hidden />
            </div>
        </div>
        <mat-tab-group [(selectedIndex)]="selectedTab">
            <mat-tab>
                <div class="content">
                    <div class="invite-team-members">
                        <h5>Invite team members</h5>
                        <form
                            class="send-invite"
                            (ngSubmit)="share()"
                            [formGroup]="inviteGroup">
                            <div class="email-input">
                                <input
                                    matInput
                                    placeholder="Enter email address"
                                    formControlName="email"
                                    (blur)="updateErrorMessage()"
                                    required />
                                <mat-error
                                    *ngIf="
                                        email?.invalid && email?.value !== ''
                                    "
                                    >{{ errorMessage }}</mat-error
                                >
                            </div>
                            <mat-form-field class="view-revise">
                                <mat-select
                                    [value]="mode"
                                    formControlName="mode">
                                    <mat-option value="1">View only</mat-option>
                                    <mat-option value="2"
                                        >View & Revise</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                            <button
                                [disabled]="email?.invalid"
                                [class.invite-sent]="inviteSent">
                                Send invite
                            </button>
                        </form>
                    </div>
                    <div
                        class="shared-with"
                        *ngIf="sharedWith.length > 0">
                        <h5>Project shared with</h5>
                        <div class="shared-list">
                            <div
                                class="shared-list-item"
                                *ngFor="let user of sharedWith">
                                <div class="left">
                                    <img
                                        class="avatar"
                                        src="assets/profile.png"
                                        alt="user" />
                                    <div
                                        class="info"
                                        [matTooltip]="user.email">
                                        <p
                                            class="name"
                                            *ngIf="
                                                user.first_name &&
                                                user.last_name
                                            ">
                                            {{
                                                user.first_name +
                                                    " " +
                                                    user.last_name
                                            }}
                                        </p>
                                        <p
                                            class="name"
                                            *ngIf="
                                                !user.first_name ||
                                                !user.last_name
                                            ">
                                            {{ user.email }}
                                        </p>
                                        <p class="status">
                                            {{
                                                user.accepted
                                                    ? "Accepted"
                                                    : "Pending"
                                            }}
                                        </p>
                                    </div>
                                </div>
                                <div class="right">
                                    <mat-form-field
                                        class="view-revise"
                                        [class.is-phone]="isPhone()">
                                        <mat-select
                                            (valueChange)="
                                                updateMode(user, $event)
                                            "
                                            [value]="
                                                user.mode === 'readonly'
                                                    ? '1'
                                                    : '2'
                                            ">
                                            <mat-option value="1"
                                                >View only</mat-option
                                            >
                                            <mat-option value="2"
                                                >View & Revise</mat-option
                                            >
                                        </mat-select>
                                    </mat-form-field>
                                    <button
                                        class="remove-access"
                                        matTooltip="Remove share access for this user"
                                        (click)="removeAccess(user)">
                                        <mat-icon svgIcon="delete" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <div class="content">
                    <div
                        class="project-link"
                        *ngIf="project.is_public && publicToken">
                        <h5>Project link</h5>
                        <div class="link">
                            <input
                                matInput
                                [value]="getPublicLink()"
                                readonly />
                            <button
                                class="copy-link"
                                (click)="copy(getPublicLink())"
                                [class.link-copied]="copied">
                                {{ copied ? "Copied!" : "Copy link" }}
                            </button>
                        </div>
                    </div>
                    <div class="who-can-see">
                        <div class="left">
                            <mat-icon svgIcon="global" />
                            <div class="info">
                                <p class="who-see">
                                    @if(project.is_public) {Anyone with the
                                    link} @else {Click enable to generate a
                                    public link}
                                </p>
                                <p class="details">
                                    @if(project.is_public) {The project link is
                                    publicly viewable} @else {Public viewing is
                                    disabled}
                                </p>
                            </div>
                        </div>
                        <div class="right">
                            <button (click)="togglePublic()">
                                @if(project.is_public) {Disable} @else {Enable}
                            </button>
                        </div>
                    </div>
                    <div class="affiliate">
                        <div class="left">
                            <mat-icon
                                svgIcon="affiliate"
                                class="affiliate-icon" />
                            <div class="info">
                                <p class="who-see">
                                    @if (!project.affiliatable) { Enable
                                    affiliate program } @else { Disable
                                    affiliate program }
                                    <mat-icon
                                        #infoIcon="matTooltip"
                                        (click)="infoIcon.toggle()"
                                        svgIcon="info"
                                        matTooltip="If someone signs up for VirtualStaging.com after visiting your project, they will automatically become your referral. This means they can get one image staged for free, and when they spend $100 with us, you will receive $100 in credits. By enabling the referral program, we will add some extra text and a button on your share project page. If you prefer not to have the additional text and button on your pages, you can disable the affiliate program." />
                                </p>
                                <p class="details">
                                    @if (!project.affiliatable) { Once enabled,
                                    you can earn $100 from people who end up
                                    using our services } @else { Affiliate
                                    program is enabled. To disable it, click the
                                    disable button}
                                </p>
                            </div>
                        </div>
                        <div class="right">
                            <button (click)="toggleAffiliate()">
                                @if(!project.affiliatable){Enable}@else
                                {Disable}
                            </button>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <div class="content">
                    <ba-slider
                        [publicToken]="publicToken"
                        *ngIf="project.is_public"></ba-slider>
                    <div
                        class="embed"
                        *ngIf="project.is_public">
                        <h5>Select size to embed</h5>
                        <mat-form-field class="embed-size">
                            <mat-select
                                [(value)]="selectedEmbedSize"
                                (valueChange)="updateSnippet()">
                                <mat-option
                                    *ngFor="let size of embedSizes"
                                    [value]="size"
                                    >{{ size.name }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                        <div class="embed-code">
                            <textarea
                                readonly
                                [value]="iframeSnippet"></textarea>
                            <button
                                (click)="copy(iframeSnippet)"
                                [class.snippet-coppied]="copied">
                                {{ copied ? "Copied!" : "Copy embed code" }}
                            </button>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <div class="content">
                    <form
                        class="property-info-form"
                        (ngSubmit)="updatePropertyInfo()"
                        [formGroup]="propertyInfoGroup">
                        <div class="row">
                            <div class="item">
                                <label for="property-name">Property name</label>
                                <div class="field">
                                    <mat-form-field class="property-name">
                                        <input
                                            matInput
                                            formControlName="propertyName"
                                            id="property-name"
                                            type="text"
                                            required />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="item">
                                <label for="bathrooms">Status</label>
                                <div class="field">
                                    <mat-form-field
                                        class="status-selector"
                                        [class.disabled]="
                                            propertyInfoGroup.get('statusHide')!
                                                .value
                                        ">
                                        <input
                                            matInput
                                            formControlName="status"
                                            name="status"
                                            [matAutocomplete]="auto2"
                                            required
                                            placeholder="Status" />
                                        <mat-autocomplete
                                            #auto2="matAutocomplete"
                                            class="status-autocomplete"
                                            autoActiveFirstOption>
                                            <mat-option [value]="'For Sale'">
                                                For Sale
                                            </mat-option>
                                            <mat-option [value]="'For Rent'">
                                                For Rent
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    <div class="checkbox">
                                        <input
                                            formControlName="statusHide"
                                            type="checkbox"
                                            hidden
                                            id="status-hide" />
                                        <label for="status-hide">
                                            <span class="checkmark"
                                                ><mat-icon
                                                    svgIcon="blueCheckmark2"
                                            /></span>
                                            Don't show
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="item">
                                <label for="bedrooms">Bedrooms</label>
                                <div class="field">
                                    <mat-form-field
                                        class="bedrooms"
                                        [class.disabled]="
                                            propertyInfoGroup.get(
                                                'bedroomsHide'
                                            )!.value
                                        ">
                                        <input
                                            matInput
                                            formControlName="bedrooms"
                                            id="bedrooms"
                                            type="number"
                                            required />
                                    </mat-form-field>
                                    <div class="checkbox">
                                        <input
                                            formControlName="bedroomsHide"
                                            type="checkbox"
                                            hidden
                                            id="bedrooms-hide" />
                                        <label for="bedrooms-hide">
                                            <span class="checkmark"
                                                ><mat-icon
                                                    svgIcon="blueCheckmark2"
                                            /></span>
                                            Don't show
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <label for="bathrooms">Bathrooms</label>
                                <div class="field">
                                    <mat-form-field
                                        class="bathrooms"
                                        [class.disabled]="
                                            propertyInfoGroup.get(
                                                'bathroomsHide'
                                            )!.value
                                        ">
                                        <input
                                            matInput
                                            formControlName="bathrooms"
                                            id="bathrooms"
                                            type="number"
                                            required />
                                    </mat-form-field>
                                    <div class="checkbox">
                                        <input
                                            formControlName="bathroomsHide"
                                            type="checkbox"
                                            hidden
                                            id="bathrooms-hide" />
                                        <label for="bathrooms-hide">
                                            <span class="checkmark"
                                                ><mat-icon
                                                    svgIcon="blueCheckmark2"
                                            /></span>
                                            Don't show
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="item">
                                <label for="sqft">Sqft</label>
                                <div class="field">
                                    <mat-form-field
                                        class="sqft"
                                        [class.disabled]="
                                            propertyInfoGroup.get('sqftHide')!
                                                .value
                                        ">
                                        <input
                                            matInput
                                            formControlName="sqft"
                                            id="sqft"
                                            type="number"
                                            required />
                                    </mat-form-field>
                                    <div class="checkbox">
                                        <input
                                            formControlName="sqftHide"
                                            type="checkbox"
                                            hidden
                                            id="sqft-hide" />
                                        <label for="sqft-hide">
                                            <span class="checkmark"
                                                ><mat-icon
                                                    svgIcon="blueCheckmark2"
                                            /></span>
                                            Don't show
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <label for="sqft-lot">Sqft lot</label>
                                <div class="field">
                                    <mat-form-field
                                        class="sqft-lot"
                                        [class.disabled]="
                                            propertyInfoGroup.get(
                                                'sqftLotHide'
                                            )!.value
                                        ">
                                        <input
                                            matInput
                                            formControlName="sqftLot"
                                            id="sqft-lot"
                                            type="number"
                                            required />
                                    </mat-form-field>
                                    <div class="checkbox">
                                        <input
                                            formControlName="sqftLotHide"
                                            type="checkbox"
                                            hidden
                                            id="sqft-lot-hide" />
                                        <label for="sqft-lot-hide">
                                            <span class="checkmark"
                                                ><mat-icon
                                                    svgIcon="blueCheckmark2"
                                            /></span>
                                            Don't show
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="item">
                                <label for="property-type">Property type</label>
                                <div class="field">
                                    <mat-form-field
                                        class="property-type-selector"
                                        [class.disabled]="
                                            propertyInfoGroup.get(
                                                'propertyTypeHide'
                                            )!.value
                                        ">
                                        <input
                                            matInput
                                            formControlName="propertyType"
                                            name="property-type"
                                            [matAutocomplete]="auto"
                                            required
                                            placeholder="Property type" />
                                        <mat-autocomplete
                                            #auto="matAutocomplete"
                                            class="property-type-autocomplete"
                                            autoActiveFirstOption>
                                            <mat-option
                                                *ngFor="
                                                    let type of propertyTypes
                                                "
                                                [value]="type">
                                                {{ type }}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    <div class="checkbox">
                                        <input
                                            formControlName="propertyTypeHide"
                                            type="checkbox"
                                            hidden
                                            id="property-type-hide" />
                                        <label for="property-type-hide">
                                            <span class="checkmark"
                                                ><mat-icon
                                                    svgIcon="blueCheckmark2"
                                            /></span>
                                            Don't show
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="item">
                                <label for="email-agent">Email agent</label>
                                <div class="field">
                                    <mat-form-field
                                        class="email-agent"
                                        [class.disabled]="
                                            propertyInfoGroup.get(
                                                'agentEmailHide'
                                            )!.value
                                        ">
                                        <input
                                            matInput
                                            formControlName="agentEmail"
                                            id="email-agent"
                                            type="email"
                                            placeholder="Email address"
                                            required />
                                    </mat-form-field>
                                    <div class="checkbox">
                                        <input
                                            formControlName="agentEmailHide"
                                            type="checkbox"
                                            hidden
                                            id="agent-email-hide" />
                                        <label for="agent-email-hide">
                                            <span class="checkmark"
                                                ><mat-icon
                                                    svgIcon="blueCheckmark2"
                                            /></span>
                                            Don't show
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="save-cancel"
                            *ngIf="propertyInfoGroup.dirty">
                            <button
                                type="submit"
                                class="primary">
                                Save
                            </button>
                            <button
                                type="button"
                                class="transparent"
                                (click)="resetPropertyInfo()">
                                Cancel
                            </button>
                        </div>
                    </form>
                    <div
                        class="landing-page-link"
                        *ngIf="!propertyInfoGroup.dirty">
                        <h4>Landing page link</h4>
                        <div class="link">
                            <input
                                matInput
                                [value]="getLandingPageLink()"
                                readonly />
                            <button
                                class="copy-link"
                                (click)="copy(getLandingPageLink())"
                                [class.link-copied]="copied">
                                {{ copied ? "Copied!" : "Copy link" }}
                            </button>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
