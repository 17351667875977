<div
    class="wrapper"
    *ngIf="!loading">
    <mat-tab-group
        [(selectedIndex)]="selectedSlide"
        *ngIf="project.images.length >= 1">
        <mat-tab *ngFor="let image of project.images">
            <div class="content">
                <ng-container
                    *ngTemplateOutlet="
                        beforeAfter;
                        context: { $implicit: image }
                    "></ng-container>
            </div>
        </mat-tab>
    </mat-tab-group>
    <button
        [class.sliding]="this.isDragging"
        *ngIf="project.images.length >= 1"
        class="left"
        (click)="moveSlider(-1)">
        <mat-icon svgIcon="previous"></mat-icon>
    </button>
    <button
        [class.sliding]="this.isDragging"
        *ngIf="project.images.length >= 1"
        class="right"
        (click)="moveSlider(1)">
        <mat-icon svgIcon="next"></mat-icon>
    </button>
    <div
        class="slides"
        *ngIf="project.images.length >= 1">
        <div
            class="slide"
            *ngFor="let slide of project.images; let i = index">
            <input
                type="radio"
                name="slide"
                [id]="i"
                [value]="i"
                hidden
                [(ngModel)]="selectedSlide" />
            <label
                class="radio-input"
                [for]="i">
            </label>
        </div>
    </div>
    <div
        class="placeholder"
        *ngIf="project.images.length === 0">
        <div class="image-wrapper"></div>
        <p>Project needs to be completed to display before and after images</p>
    </div>
</div>

<ng-template
    #beforeAfter
    let-image>
    <div class="before-after">
        <img-comparison-slider (slide)="onSlide()">
            <img
                #after_element
                id="before_element"
                width="100%"
                slot="after"
                [src]="image.before" />
            <img
                id="after_element"
                width="100%"
                slot="before"
                [src]="image.after" />
            <div
                class="divider-hint"
                slot="handle">
                <mat-icon svgIcon="previous"></mat-icon>
                <mat-icon svgIcon="next"></mat-icon>
            </div>
        </img-comparison-slider>
    </div>
</ng-template>
