<div
    class="wrapper annotations-container"
    #annotationContainer
    cdkDropList
    cdkDropListConnectedTo="drop-list">
    <img
        #imageElement
        [src]="getUrl()"
        (click)="createAnnotation($event)"
        alt="Image" />
    <ng-container *ngFor="let annotation of annotations">
        <ng-template
            [ngTemplateOutlet]="annotationTemplate"
            [ngTemplateOutletContext]="{
                annotation: annotation
            }"></ng-template>
    </ng-container>
</div>

<ng-template
    #annotationTemplate
    let-annotation="annotation">
    <button
        class="annotation"
        [style]="getPositionStyle(annotation)"
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="annotationMenu"
        cdkDrag
        (cdkDragStarted)="dragStart()"
        (cdkDragEnded)="drop($event)"
        (click)="openContextMenu($event, menuTrigger)"
        (menuOpened)="addActiveClass(menuTrigger)"
        (menuClosed)="removeActiveClass(menuTrigger)"
        [id]="'annotation-' + annotation.id"
        cdkDragBoundary=".wrapper">
        <h3>{{ annotation.id }}</h3>
    </button>
    <mat-menu
        #annotationMenu="matMenu"
        [overlapTrigger]="false"
        xPosition="before"
        yPosition="below"
        class="comment-menu">
        <div
            class="comment"
            (click)="$event.stopPropagation()">
            <textarea
                placeholder="Enter your comment"
                rows="8"
                [(ngModel)]="annotation.textValue"></textarea>
            <div class="buttons">
                <button
                    class="transparent"
                    (click)="deleteAnnotation(annotation)">
                    <span>Delete</span>
                </button>
                <button
                    class="primary"
                    (click)="menuTrigger.closeMenu()">
                    <span>Save</span>
                </button>
            </div>
        </div>
    </mat-menu>
</ng-template>
