import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { environment } from '../environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class ApiService implements OnDestroy {
    private memberships$: Subscription[] = [];
    private endpoint = environment.api;
    private uploadendpoint = environment.noncfendpoint;
    public notify = true;
    private refreshInProgress = false;

    private headers: HttpHeaders = new HttpHeaders({
        Authorization: localStorage.getItem('authToken') || '',
    });

    constructor(
        private http: HttpClient,
        private router: Router
    ) { }

    ngOnDestroy() {
        for (let membership of this.memberships$) {
            membership.unsubscribe();
        }
        this.memberships$ = [];
    }

    get(url: string, params?: {}): Promise<any> {
        return new Promise((resolve, reject) => {
            const sub = this.http
                .get(this.endpoint + url, {
                    headers: this.headers,
                    params,
                })
                .pipe(
                    catchError((error) => {
                        console.error('Request failed', error);
                        return throwError(() => error);
                    })
                );
            this.memberships$.push(
                sub.subscribe({
                    next: (res) => {
                        resolve(res);
                    },
                    error: (err) => {
                        this.handleError(err, 'get');
                        reject(err);
                    },
                    complete: () => { },
                })
            );
        });
    }

    getDirect(url: string) {
        return this.http
            .get(url, { headers: this.headers })
            .toPromise()
            .then((response) => {
                return response;
            })
            .catch((err) => this.handleError(err, 'get'));
    }

    postDirect(url: string, data?: {}, params?: {}): Promise<any> {
        return new Promise((resolve, reject) => {
            const sub = this.http
                .post(this.uploadendpoint + url, data, {
                    headers: this.headers,
                    params,
                })
                .pipe(
                    catchError((error) => {
                        console.error('Request failed', error);
                        return throwError(() => error);
                    })
                );
            this.memberships$.push(
                sub.subscribe({
                    next: (res) => {
                        resolve(res);
                    },
                    error: (err) => {
                        this.handleError(err, 'post');
                        reject(err);
                    },
                    complete: () => { },
                })
            );
        });
    }

    post(
        url: string,
        data?: {},
        params?: {},
        successMessage?: string
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            const sub = this.http
                .post(this.endpoint + url, data, {
                    headers: this.headers,
                    params,
                })
                .pipe(
                    catchError((error) => {
                        console.error('Request failed', error);
                        return throwError(() => error);
                    })
                );
            this.memberships$.push(
                sub.subscribe({
                    next: (res) => {
                        resolve(res);
                    },
                    error: (err) => {
                        this.handleError(err, 'post');
                        reject(err);
                    },
                    complete: () => { },
                })
            );
        });
    }

    put(url: string, data?: {}, params?: {}): Promise<any> {
        return new Promise((resolve, reject) => {
            const sub = this.http
                .put(this.endpoint + url, data, {
                    headers: this.headers,
                    params,
                })
                .pipe(
                    catchError((error) => {
                        console.error('Request failed', error);
                        return throwError(() => error);
                    })
                );
            this.memberships$.push(
                sub.subscribe({
                    next: (res) => {
                        resolve(res);
                    },
                    error: (err) => {
                        this.handleError(err, 'put');
                        reject(err);
                    },
                    complete: () => { },
                })
            );
        });
    }

    patch(url: string, data?: {}, params?: {}): Promise<any> {
        return new Promise((resolve, reject) => {
            const sub = this.http
                .patch(this.endpoint + url, data, {
                    headers: this.headers,
                    params,
                })
                .pipe(
                    catchError((error) => {
                        console.error('Request failed', error);
                        return throwError(() => error);
                    })
                );
            this.memberships$.push(
                sub.subscribe({
                    next: (res) => {
                        resolve(res);
                    },
                    error: (err) => {
                        this.handleError(err, 'patch');
                        reject(err);
                    },
                })
            );
        });
    }

    delete(url: string, params?: {}): Promise<any> {
        return new Promise((resolve, reject) => {
            const sub = this.http
                .delete(this.endpoint + url, {
                    headers: this.headers,
                    params,
                })
                .pipe(
                    catchError((error) => {
                        console.error('Request failed', error);
                        return throwError(() => error);
                    })
                );
            this.memberships$.push(
                sub.subscribe({
                    next: (res) => {
                        resolve(res);
                    },
                    error: (err) => {
                        this.handleError(err, 'delete');
                        reject(err);
                    },
                    complete: () => { },
                })
            );
        });
    }

    uploadGeneric(type: string, url: string, data: string) {
        return this.upload(url, data);
        // if (type === 'File') {
        //     return this.upload(url, data);
        // }
        // return this.uploadImage(url, data);
    }

    uploadImage(url: string, data?: {}, params?: {}) {
        return this.http
            .request(
                new HttpRequest('POST', this.endpoint + url, data, {
                    headers: this.headers,
                    reportProgress: true,
                    ...params,
                })
            )
            .pipe(
                catchError((error) => {
                    console.error('Request failed', error);
                    return throwError(() => error);
                })
            );
    }

    upload(url: string, data?: {}, params?: {}) {
        return this.http
            .request(
                new HttpRequest('POST', this.endpoint + url, data, {
                    headers: this.headers,
                    reportProgress: true,
                    ...params,
                })
            )

            .pipe(
                catchError((error) => {
                    console.error('Request failed', error);
                    return throwError(() => error);
                })
            );
    }


    updateHeaders() {
        this.headers = new HttpHeaders({
            Authorization: localStorage.getItem('authToken') || '',
        });
    }

    private async handleError(error: any, method: string): Promise<any> {
        switch (error.status) {
            case 401:
                if (this.refreshInProgress) {
                    return;
                }
                this.refreshInProgress = true;
                await this.refresh();
                break;

            case 403:
                window.location.href = '/projects';
                break;

            case 409:
                if (error.error.message) {
                    console.error('An error occurred', error.error.message);
                }
                break;
            case 400:
                break;
            default:
                if (!environment.production) {
                    console.error('An error occurred', error);
                }
                break;
        }

        return Promise.reject(error);
    }

    getAuth() {
        let auth = {
            authorization: localStorage.getItem('authToken') || '',
        };
        return JSON.stringify(auth);
    }

    getEndpoint() {
        return this.endpoint;
    }

    refresh() {
        const user = localStorage.getItem('user');
        if (!user || user === 'undefined') {
            localStorage.clear();
            this.updateHeaders();
            return;
        }

        const data = {
            token: localStorage.getItem('authToken') || '',
            username: JSON.parse(user).email,
            refresh_token: localStorage.getItem('refreshToken') || '',
        };
        return new Promise<void>((resolve, reject) => {
            this.http
                .post(`${this.endpoint}token/refresh`, data, {
                    headers: this.headers,
                })
                .toPromise()
                .then((login: any) => {
                    localStorage.setItem('refreshToken', login.refresh_token);
                    localStorage.setItem('authToken', 'Bearer ' + login.token);
                    window.location.reload();
                    resolve();
                })
                .catch((err) => {
                    localStorage.clear();
                    window.location.href = "/login";
                    reject();
                });
        })
    }
}
