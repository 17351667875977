<div
    class="wrapper"
    [class]="breakpointService.currentBreakpointClass">
    <div
        class="tabs"
        *ngIf="!isPp">
        <button
            class="required-info"
            [ngClass]="activeTab === 1 ? 'active' : ''">
            Optional Info
        </button>
    </div>
    <div class="comments">
        <h4 *ngIf="!isPp">
            <label for="comment-box">Your comments:</label>
        </h4>
        <button
            class="comments-label-pp"
            *ngIf="isPp"
            (click)="expanded.comments = !expanded.comments">
            <h3>Your comments (optional)</h3>
            <mat-icon
                *ngIf="!expanded.comments"
                svgIcon="plus3" />
            <mat-icon
                *ngIf="expanded.comments"
                svgIcon="minus3" />
        </button>
        <textarea
            id="comment-box"
            *ngIf="expanded.comments || !isPp"
            [(ngModel)]="service.data.description"
            (ngModelChange)="checkIfNeedsToSave()"
            placeholder="Click here to leave a note for our designers if you have any specific instructions. This field is optional, and if you don't leave any comments, our designers will use their best judgment when creating your design."
            name="comments"
            rows="9">
        </textarea>
    </div>
    <div class="upload-ref">
        <app-upload-ref
            (refAdded)="addReferenceImage($event)"
            (refRemoved)="removeReferenceImage($event)"
            [projectGuid]="projectGuid"
            [serviceRequest]="serviceRequest"
            [service]="service"></app-upload-ref>
    </div>
</div>
