import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-continuation-popup',
    templateUrl: './continuation-popup.component.html',
    styleUrls: ['./continuation-popup.component.scss']
})
export class ContinuationPopupComponent {
    public project: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog,
        private router: Router,
    ) { }

    ngOnInit() {
        this.project = this.data.project;
    }

    continueProject() {
        this.router.navigate(['new-project'], { queryParams: { continuation: encodeURIComponent(JSON.stringify(this.project)) } });
        this.dialog.closeAll();
    }
}
