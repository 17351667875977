import { Component } from '@angular/core';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {
	constructor(
		public breakpointService: BreakpointService,
		private router: Router
	) { }

	goToProjectPage(param?: string) {
		this.router.navigate(['new-project'], { queryParams: { type: param } });
	}

	goTo(path: string) {
		window.open(path, "_blank");
	}
}
