const tiers: any = [
    {
        "id": 1,
        "price": 700,
        get name() {
            return `Up to 700 sq. ft. - $${this.price}`;
        }
    },
    {
        "id": 2,
        "price": 1250,
        get name() {
            let price2 = this.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return `701 - 1,250 sq. ft. - $${price2}`;
        }
    },
    {
        "id": 3,
        "price": 1750,
        get name() {
            let price2 = this.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return `1,251 - 1,700 sq. ft. - $${price2}`;
        }
    },
    {
        "id": 4,
        "price": 2100,
        get name() {
            let price2 = this.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return `1,701 - 2,100 sq. ft. - $${price2}`;
        }
    },
    {
        "id": 5,
        "price": 2500,
        get name() {
            let price2 = this.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return `2,101 - 2,750 sq. ft. - $${price2}`;
        }
    },
    {
        "id": 6,
        "price": 3000,
        get name() {
            let price2 = this.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return `2,751 - 3,500 sq. ft. - $${price2}`;
        }
    }
]

export default tiers;
