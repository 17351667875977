<div
    class="wrapper"
    [class]="breakpointService.currentBreakpointClass">
    <img
        src="assets/guidelines-graphic.png"
        alt="" />
    <div class="content">
        <div class="text">
            <h3>Important Guidelines:</h3>
            <div class="guidelines">
                <p>
                    <mat-icon svgIcon="grayCheckmark" />
                    <span>Bathroom, Kitchen, and Flooring:</span> Due to
                    limitations with 3D models, elements may not be exact.
                    Flooring will have a similar color but may not match
                    perfectly.
                </p>
                <p>
                    <mat-icon svgIcon="grayCheckmark" />
                    <span>Furniture and Decor:</span> TIf you order virtual
                    staging along with the floor plan, the same furniture will
                    appear in the 3D plans.
                </p>
                <p>
                    <mat-icon svgIcon="grayCheckmark" />
                    <span>Example Floor Plans:</span> Please review the example
                    floor plans to understand what to expect from our 3D
                    designs.
                </p>
            </div>

            <p>
                <i
                    >The floor plan costs ${{ data.price }} per level during the
                    promotional period. For properties with two levels, the
                    total cost will be 2 x ${{ data.price }}.</i
                >
            </p>

            <h3>Deliverables:</h3>
            <div class="deliverables">
                <p>
                    <mat-icon svgIcon="grayCheckmark" />
                    <span>Orthogonal Render:</span> A detailed, top-down view
                    showcasing the spatial arrangement and layout.
                </p>
                <div class="example">
                    <img
                        src="assets/ortho-render.jpg"
                        alt="" />
                    <span>Example Orthogonal Render</span>
                </div>
                <p>
                    <mat-icon svgIcon="grayCheckmark" />
                    <span>3D Floor Plan Render:</span> A realistic visual
                    representation of the floor plan.
                </p>
                <div class="example">
                    <img
                        src="assets/3d-render.jpg"
                        alt="" />
                    <span>Example 3D Floor Plan Render</span>
                </div>
            </div>
        </div>

        <div class="sign">
            <input
                type="checkbox"
                name="signature-disclaimer-sign"
                [(ngModel)]="disclaimerUnderstood"
                hidden
                id="signature-disclaimer-sign" />
            <label for="signature-disclaimer-sign"
                ><span class="checkmark"
                    ><mat-icon
                        svgIcon="blueCheckmark2"
                        *ngIf="disclaimerUnderstood"
                /></span>
                By choosing our 3D Floor Plan Designs, you agree to these
                guidelines for the staging process.</label
            >
        </div>
        <button
            class="primary"
            [disabled]="!disclaimerUnderstood"
            (click)="confirm()">
            Accept
        </button>
    </div>
</div>
