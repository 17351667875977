import { Component, ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { BreakpointService } from '../services/breakpoint.service';
import { UserService } from '../services/user.service';
import { IntlTelInputDirective } from '../directives/intl-tel-input.directive';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
    @ViewChild(IntlTelInputDirective, { static: false }) intlTelInputDirective!: IntlTelInputDirective;

    public loading: boolean = true;
    public enableSms: boolean = false;
    public keepOriginal: boolean = false;
    public user: any;
    public firstName: string = '';
    public lastName: string = '';
    public email: string = '';
    public phone: string = '';
    public company: string = '';
    public oldPassword: string = '';
    public newPassword: string = '';
    public confirmPassword: string = '';
    public updating: boolean = false;
    public updatedProfile: boolean = false;
    public updatedPassword: boolean = false;
    public invalidLogin: boolean = false;
    public incorrectPassword: boolean = false;
    public ccEmail1: string = '';
    public ccEmail2: string = '';
    public avatar: string = '';
    public phoneInvalid: boolean = false;

    constructor(
        private api: ApiService,
        public breakpointService: BreakpointService,
        private userService: UserService
    ) {
        this.api.get('user').then((res) => {
            this.user = res;
            localStorage.setItem('user', JSON.stringify(this.user));
            this.enableSms = this.user.sms_notification;
            this.keepOriginal = this.user.download_original;
            this.firstName = this.user.first_name;
            this.lastName = this.user.last_name;
            this.email = this.user.email;
            if (this.user.cc) {
                this.ccEmail1 = this.user.cc.split(',')[0];
                this.ccEmail2 = this.user.cc.split(',')[1];
            }
            if (this.user.phone_number) {
                this.phone = this.user.phone_number;
            } else {
                this.phone = '+1';
            }
            this.company = this.user.real_estate_group;
            this.avatar = this.user.avatar?.url;
            this.loading = false;
        });
    }

    async updateProfile() {
        this.updating = true;

        if (!this.intlTelInputDirective.isValidNumber()) {
            if (this.phoneDirty() && this.phone !== '+1' && this.phone !== '') {
                this.phoneInvalid = true;
                setTimeout(() => {
                    this.phoneInvalid = false;
                }, 4000);
                this.updating = false;
                return;
            } else {
                this.phone = '';
            }
        }

        if (this.ccChanged()) {
            await this.updateCc();
        }
        this.api
            .put(`user/${this.user.id}`, {
                firstName: this.firstName,
                lastName: this.lastName,
                smsNotification: this.enableSms,
                downloadOriginal: this.keepOriginal,
                country: this.user.country,
                email: this.email,
                phoneNumber: this.intlTelInputDirective.getNumber(),
                realEstateGroup: this.company,
            })
            .then(
                (res) => {
                    localStorage.setItem("user", JSON.stringify(res));
                    this.user = res;
                    this.updating = false;
                    this.updatedProfile = true;
                    setTimeout(() => {
                        this.updatedProfile = false;
                    }, 2000);
                },
                () => (this.updating = false)
            );
    }

    async updateCc() {
        const ccs = [];
        if (this.ccEmail1) {
            ccs.push(this.ccEmail1);
        }
        if (this.ccEmail2) {
            ccs.push(this.ccEmail2);
        }
        let ccsString = ccs.join(',');
        this.api.patch("user/cc", { cc: ccsString });
    }

    ccChanged() {
        if (this.user.cc) {
            return this.ccEmail1 !== this.user.cc.split(',')[0] || this.ccEmail2 !== this.user.cc.split(',')[1];
        } else {
            if (this.ccEmail1 || this.ccEmail2) {
                return true;
            } else {
                return false;
            }
        }
    }

    validateEmail(): boolean {
        const email = this.email;
        const regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    }

    phoneDirty() {
        if (this.user.phone_number) {
            return this.phone !== this.user.phone_number;
        } else {
            return this.phone !== '+1';
        }
    }

    dirtyProfile() {
        if (
            this.firstName !== this.user.first_name ||
            this.lastName !== this.user.last_name ||
            this.email !== this.user.email ||
            this.phoneDirty() ||
            this.company !== this.user.real_estate_group ||
            this.enableSms !== this.user.sms_notification ||
            this.keepOriginal !== this.user.download_original ||
            this.ccChanged()
        ) {
            return true;
        }
        return false;
    }

    invalidProfile() {
        if (
            !this.validateEmail() ||
            !this.firstName ||
            !this.lastName ||
            !this.email ||
            (!this.phone && this.enableSms)
        ) {
            return true;
        }
        return false;
    }

    updatePassword() {
        this.updating = true;
        if (this.newPassword !== this.confirmPassword || !this.oldPassword || !this.newPassword || !this.confirmPassword) {
            this.invalidLogin = true;
            this.updating = false;
            return;
        } else {
            this.invalidLogin = false;
        }
        this.api.patch("user/changePassword", {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
            passwordAgain: this.confirmPassword,
        }).then(
            () => {
                this.updatedPassword = true;
                this.oldPassword = '';
                this.newPassword = '';
                this.confirmPassword = '';
                setTimeout(() => {
                    this.updatedPassword = false;
                }, 2000);
                this.incorrectPassword = false;
                this.phoneInvalid = false;
                this.updating = false;
            },
        ).catch(() => {
            this.incorrectPassword = true;
            this.invalidLogin = true;
            this.oldPassword = '';
            this.updating = false;
        });
    }

    invalidPassword() {
        if (this.newPassword !== this.confirmPassword || !this.oldPassword || !this.newPassword || !this.confirmPassword) {
            return true;
        }
        return false;
    }

    updatePhone(e: any) {
        this.phone = e.target.value;
    }

    uploadAvatar(event: any) {
        const files = event.target.files || event.dataTransfer.files;
        if (files && files.length > 0) {
            const formData = new FormData();
            formData.append('avatar', files[0]);
            this.api.post('user/set-avatar', formData)
                .then((res) => {
                    this.user = res;
                    localStorage.setItem('user', JSON.stringify(this.user));
                    this.avatar = this.user.avatar?.url;
                    this.userService.updateUser(this.user);
                }
                );
        } else {
            console.error('No files selected');
        }
    }

    deleteAvatar() {
        this.api.post('user/set-avatar')
            .then((res) => {
                this.user = res;
                localStorage.setItem('user', JSON.stringify(this.user));
                this.avatar = this.user.avatar?.url;
                this.userService.updateUser(this.user);
            });
    }

    onCountryCodeChange(countryCode: string) {
        this.phone = '';
    }
}
