import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, Subject, defer, timer } from 'rxjs';
import { mergeMap, map, catchError, filter, takeUntil, switchMap } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ThumbnailService {
    private static MAX_CONCURRENT_REQUESTS = 12;
    private currentRequests: number = 0;
    private thumbnailQueue: Subject<string> = new Subject<string>();
    private stopProcessing: Subject<void> = new Subject<void>();
    private cache: Map<string, SafeUrl> = new Map<string, SafeUrl>();

    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {
    this.thumbnailQueue
        .pipe(
            mergeMap((thumbnailUrl: string) => this.processThumbnail(thumbnailUrl))
        )
        .subscribe();
    }

    enqueueThumbnail(thumbnailUrl: string): Observable<SafeUrl> {
        return new Observable<SafeUrl>((observer) => {
            if (this.cache.has(thumbnailUrl)) {
                const cachedValue = this.cache.get(thumbnailUrl);
                observer.next(cachedValue);
                observer.complete();
            } else {
                this.thumbnailQueue.next(thumbnailUrl);

                this.processThumbnail(thumbnailUrl)
                    .pipe(takeUntil(this.stopProcessing))
                    .subscribe(
                        (processedUrl: SafeUrl) => {
                            this.cache.set(thumbnailUrl, processedUrl);
                            observer.next(processedUrl);
                            observer.complete();
                        },
                        (error) => {
                            const brokenImageUrl = 'assets/placeholder.png';
                            observer.next(this.sanitizer.bypassSecurityTrustUrl(brokenImageUrl));
                            observer.complete();
                        }
                    );
                }
        });
    }

    private processThumbnail(thumbnailUrl: string): Observable<SafeUrl> {
        const thumbnailUrlOrig = `${thumbnailUrl}`;
        return defer(() => {
            if (this.currentRequests < ThumbnailService.MAX_CONCURRENT_REQUESTS) {
                this.currentRequests++;

                const name = thumbnailUrlOrig.split("/").slice(-1)[0];
                const url = `${environment.server}/asset/thumbnail/${name}`;

                return this.http.get(url, { responseType: 'blob', observe: 'response' })
                    .pipe(
                    map((response: any) => {
                        const imageUrl = this.sanitizeImageUrl(response.body);
                        this.currentRequests--;
                        return imageUrl;
                    }),
                    catchError(() => {
                        this.currentRequests--;
                        throw new Error('Failed to load image');
                    }),
                    filter((imageUrl: SafeUrl) => !!imageUrl)
                );
            } else {
                return timer(100).pipe(
                    switchMap(() => {
                        return this.processThumbnail(thumbnailUrlOrig);
                    })
                );
            }
        });
    }

    private sanitizeImageUrl(blob: Blob): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
    }

    resetQueue() {
        this.stopProcessing.next();
        this.stopProcessing = new Subject<void>();
    }
}