<div class="wrapper">
    <h1>Redirecting!</h1>
    <img
        src="/assets/rolling.gif"
        alt="" />
    <p>
        *This should be quick! If the wait seems extended, feel free to reach
        out to us.
    </p>
</div>
