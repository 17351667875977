<div
    class="wrapper"
    [ngClass]="breakpointService.currentBreakpointClass">
    <button
        [matTooltip]="
            project.shared && !shareAccepted()
                ? 'You need to accept the project before you can view it'
                : ''
        "
        class="title"
        (click)="openProject()">
        <h5>{{ project.name }}</h5>
    </button>
    <h5 class="status">
        <span [ngStyle]="{ 'background-color': getBackgroundColor() }"></span
        >{{ getStatus() }}
    </h5>

    <div
        class="number-of-images"
        *ngIf="!gutTable()">
        <button
            matTooltip="Add more photos"
            (click)="addMorePhotos()"
            class="add-more-photos">
            <mat-icon svgIcon="addImage" />
        </button>
        <h6>
            {{
                project.service_requests_length > 1
                    ? project.service_requests_length + " images"
                    : project.service_requests_length + " image"
            }}
        </h6>
    </div>
    <div
        class="eta"
        *ngIf="!gutTable()">
        <div *ngIf="shouldDisplayEta(project)">
            <mat-icon svgIcon="clock2" />
            <h6>
                ETA
                {{
                    remainingTime.split(":")[0] +
                        "h" +
                        remainingTime.split(":")[1] +
                        "m"
                }}
            </h6>
        </div>
        <div
            *ngIf="
                project.estimation_proposal && project.status === 'In Review'
            ">
            <mat-icon svgIcon="clock2" />
            <h6>
                {{
                    project.estimation_proposal === "48"
                        ? "Awaiting Review"
                        : "ETA " + project.estimation_proposal
                }}
                {{ project.estimation_proposal !== "48" ? "Hours" : "" }}
            </h6>
        </div>
        <div *ngIf="showDeliveryTime()">
            <mat-icon svgIcon="greenCheckmark" />
            <h6>{{ duration.hours }}h {{ duration.minutes }}m</h6>
        </div>
    </div>
    <div
        class="sharing"
        *ngIf="
            breakpointService.currentBreakpointClass !== 'is-phone-portrait'
        ">
        <button
            *ngIf="!project.shared && project.status !== 'Draft'"
            class="share-project"
            (click)="openShareModal()">
            <mat-icon svgIcon="share" />
            <h6>Share</h6>
        </button>
        <div
            class="foreign-project-details"
            *ngIf="project.shared && shareAccepted()">
            <div class="details">
                <div class="left">
                    <p>Shared by:</p>
                    <p>Permissions:</p>
                </div>
                <div class="right">
                    <p>{{ project.owner }}</p>
                    <p>
                        {{
                            project.mode === "edit"
                                ? "View & Revise"
                                : "View only"
                        }}
                    </p>
                </div>
            </div>
        </div>
        <div
            class="foreign-project-actions"
            *ngIf="project.shared && !shareAccepted()">
            <p>
                {{ project.owner }} wants to share their project with you.
                <a (click)="confirmAccept()">Accept</a> or
                <a (click)="confirmDecline()">Decline</a>
            </p>
        </div>
    </div>
    <div
        class="actions"
        *ngIf="
            breakpointService.currentBreakpointClass !== 'is-phone-portrait'
        ">
        <button
            *ngIf="project.status === 'Draft'"
            (click)="openRenameModal()">
            <span>Rename</span>
        </button>
        <button
            *ngIf="project.status === 'Draft'"
            (click)="openDeleteModal()">
            <span>Delete</span>
        </button>
        <div
            class="feedback"
            *ngIf="project.status === 'Done' && !showTrustpilot">
            <h6>How did we do?</h6>
            <div class="emojis">
                <button
                    *ngFor="
                        let button of ['😠', '😕', '😐', '😃', '🤩'];
                        let i = index
                    "
                    [matMenuTriggerFor]="leaveFeedbackModal"
                    [class.applied]="parseInt(project.project_rating) === i + 1"
                    [class.not-applied]="
                        project.project_rating &&
                        parseInt(project.project_rating) !== i + 1
                    "
                    (click)="project.rated = i + 1">
                    {{ button }}
                </button>
            </div>
        </div>
        <div
            *ngIf="showTrustpilot"
            class="trustpilot">
            <a
                href="https://www.trustpilot.com/review/vs24.com"
                target="_blank"
                ><img src="/assets/trustpilot.png"
            /></a>
        </div>
    </div>
    <div
        class="phone-menu"
        *ngIf="
            breakpointService.currentBreakpointClass === 'is-phone-portrait'
        ">
        <button
            mat-button
            [matMenuTriggerFor]="projectMenu">
            <mat-icon svgIcon="options" />
        </button>
    </div>
</div>

<ng-template #renameProjectModal>
    <div class="rename-project">
        <h4>Enter a new name for the project</h4>
        <input
            type="text"
            [(ngModel)]="project.name"
            id="project-name" />
        <div class="buttons">
            <button
                mat-button
                (click)="renameProject()"
                class="primary">
                <p>Rename</p>
            </button>
            <button
                mat-button
                (click)="closeRenameModal()"
                class="transparent">
                <p>Close</p>
            </button>
        </div>
    </div>
</ng-template>

<mat-menu
    #projectMenu="matMenu"
    xPosition="before"
    yPosition="below">
    <button
        mat-menu-item
        (click)="openShareModal()"
        [disabled]="project.shared || project.status === 'Draft'">
        <span>Share project</span>
    </button>
    <button
        mat-menu-item
        (click)="openDraft()"
        [disabled]="project.status !== 'Draft'">
        <span>Edit project</span>
    </button>
    <button
        mat-menu-item
        (click)="addMorePhotos()"
        [disabled]="project.status === 'Draft'">
        <span>Add more photos</span>
    </button>
    <button
        mat-menu-item
        (click)="openRenameModal()"
        [disabled]="project.status !== 'Draft'">
        <span>Rename project</span>
    </button>
    <button
        mat-menu-item
        [disabled]="project.status !== 'Draft'"
        (click)="openDeleteModal()">
        <span>Delete project</span>
    </button>
</mat-menu>

<mat-menu
    (closed)="project.rated = null"
    #leaveFeedbackModal="matMenu"
    xPosition="before"
    yPosition="below">
    <div class="leave-feedback-modal">
        <textarea
            (click)="$event.stopPropagation()"
            rows="5"
            type="text"
            autofocus
            #feedbackField></textarea>
        <div class="buttons">
            <button
                mat-button
                (click)="leaveFeedback(feedbackField.value)"
                class="primary">
                <p>Leave feedback</p>
            </button>
            <button
                mat-button
                (click)="closeDialog()"
                class="transparent">
                <p>Close</p>
            </button>
        </div>
    </div>
</mat-menu>
