<div
    class="wrapper"
    [class]="breakpointService.currentBreakpointClass"
    *ngIf="!loading">
    <div
        class="title"
        *ngIf="project.status_code === 'in-progress'">
        <h1>
            <span>{{ project.name }} is Underway!</span> We'll email you when
            it's done.
        </h1>
        <p>
            Emails can sometimes be blocked or end up in spam.<br />If you don't
            hear from us, please check our website, as we always complete
            projects on time.<br /><br />You can also enable
            <button (click)="openPhoneModal()">text notifications</button>
            for immediate updates.
        </p>
    </div>
    <div
        class="title"
        *ngIf="project.status_code === 'in-review'">
        <h1>
            Your project
            <span>{{ project.name }} is currently in review</span>
        </h1>
        <p>
            The review usually takes between 15 to 90 minutes, and up to 12
            hours if<br />
            you submit over the weekend.
        </p>
        <p>
            No worries — the turnaround time starts the moment you submit your
            project,<br />
            no matter how long our review takes.
        </p>
        <p>
            As soon as it's approved, we'll send you an email with an exact
            delivery<br />
            timeframe. We promise to get your project to you by then, or even
            sooner!
        </p>
    </div>
    <div
        class="eta"
        *ngIf="
            project.status_code === 'in-progress' && remainingTime !== 'Done'
        ">
        <mat-icon svgIcon="blueCheckmark" />
        <h4>Remaining time:</h4>
        <p class="hours">{{ remainingTime.split(":")[0] }}</p>
        <p>Hrs</p>
        <p class="minutes">{{ remainingTime.split(":")[1] }}</p>
        <p>Mins</p>
        <hr />
    </div>
    <div
        class="eta"
        *ngIf="project.status_code === 'in-review'">
        <mat-icon svgIcon="blueCheckmark" />
        <h4>Estimated turnaround:</h4>
        <p class="hours">
            {{
                project.estimation_proposal === "48"
                    ? "Awaiting Review"
                    : project.estimation_proposal
            }}
        </p>
        <p *ngIf="project.estimation_proposal !== '48'">Hours</p>
        <hr />
    </div>
    <h1>Review your submissions below</h1>
    <div class="card-grid">
        <div
            class="card"
            *ngFor="let request of serviceRequests()">
            <h5>
                {{
                    !request.is_floor_plan
                        ? request.name
                        : getFloorPlanBumpName(request)
                }}
            </h5>
            <div class="image">
                <button
                    *ngIf="!request.is_floor_plan"
                    class="details"
                    (click)="openDetailsDialog(request)">
                    <mat-icon svgIcon="eye" />
                </button>
                <img
                    [src]="getThumbnail(request)"
                    alt="" />
            </div>
            <div class="price-breakdown">
                <div class="services">
                    <div
                        class="service"
                        *ngFor="let service of getTasks(request)">
                        <p>{{ service.name }}</p>
                        <p>${{ service.price.toFixed(2) }}</p>
                    </div>
                </div>
                <div class="total">
                    <p>Total cost</p>
                    <p>${{ getTotal(request).toFixed(2) }}</p>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template
    #detailsDialog
    let-data>
    <div
        class="details-wrapper"
        [class]="breakpointService.currentBreakpointClass">
        <button
            class="close"
            (click)="closeDialog()">
            <mat-icon svgIcon="xMark" />
        </button>
        <h2>{{ data.serviceRequest.name }}</h2>
        <div class="services">
            <div
                class="service"
                *ngFor="let service of getTasks(data.serviceRequest)">
                <button
                    class="nameplate"
                    (click)="expandCollapse(data.serviceRequest, service)">
                    <h4>
                        {{ service.name }}:
                        <span>${{ service.price.toFixed(2) }}</span>
                    </h4>
                    <mat-icon
                        [svgIcon]="
                            service.id === activeService ? 'minus' : 'plus2'
                        " />
                </button>
                <div
                    class="breakdown"
                    [@expandCollapse]="
                        service.id === activeService ? 'expanded' : 'collapsed'
                    ">
                    <div class="image">
                        <img
                            #imageElement
                            [src]="getUrl(data.serviceRequest)"
                            alt="" />
                        <div
                            class="annotations"
                            *ngIf="
                                currentImageData?.length > 0 &&
                                service.name !== 'Item Removal' &&
                                service.id === activeService
                            ">
                            <ng-container
                                *ngFor="let annotation of currentImageData">
                                <ng-template
                                    [ngTemplateOutlet]="annotationTemplate"
                                    [ngTemplateOutletContext]="{
                                        annotation: annotation
                                    }"></ng-template>
                            </ng-container>
                        </div>
                        <div
                            class="item-removal"
                            *ngIf="
                                currentImageData?.length > 0 &&
                                service.name === 'Item Removal' &&
                                service.id === activeService
                            ">
                            <table
                                class="table table-bordered"
                                [style.height]="currentImageHeight + 'px'">
                                <tbody>
                                    <tr *ngFor="let row of [0, 1, 2, 3, 4]">
                                        <td
                                            *ngFor="
                                                let column of [
                                                    0, 1, 2, 3, 4, 5, 6, 7
                                                ]
                                            "
                                            [ngClass]="{
                                                active: checkField(
                                                    row + 1,
                                                    column + 1
                                                )
                                            }"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="details">
                        <div
                            class="details-item"
                            *ngFor="let item of getInfo(service)">
                            <h4>{{ item.key }}:</h4>
                            <p>{{ item.value }}</p>
                        </div>

                        <div
                            class="refs"
                            *ngIf="service.reference_assets.length > 0">
                            <p>Reference images</p>
                            <div class="reference-photos">
                                <div
                                    class="reference-photo"
                                    *ngFor="
                                        let ref of service.reference_assets
                                    ">
                                    <img
                                        [src]="ref.url"
                                        alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template
    #annotationTemplate
    let-annotation="annotation">
    <div
        class="annotation"
        matTooltip="{{ annotation.textValue }}"
        [style]="getPositionStyle(annotation)">
        <h3>{{ annotation.id }}</h3>
    </div>
</ng-template>
