<div
    class="wrapper"
    [ngClass]="{ 'processing-project': processingProject }"
    [class]="breakpointService.currentBreakpointClass"
    *ngIf="!loading">
    <div
        class="logo"
        [class.no-action]="noProjects">
        <button
            (click)="goHome()"
            class="home">
            <mat-icon svgIcon="logoSmall" />
            <p>&#8592; Back to projects</p>
        </button>
        <button
            *ngIf="noProjects"
            (click)="logOut()"
            class="log-out">
            <mat-icon svgIcon="logout"></mat-icon>
            <p>Log out</p>
        </button>
    </div>
    <div
        class="content"
        [class]="'on-step' + currentStep">
        <ng-container [ngSwitch]="currentStep">
            <ng-container *ngSwitchCase="1">
                <ng-template [ngTemplateOutlet]="step1Template"></ng-template>
            </ng-container>
            <ng-container *ngSwitchCase="2">
                <ng-template [ngTemplateOutlet]="step2Template"></ng-template>
            </ng-container>
            <ng-container *ngSwitchCase="3">
                <ng-template [ngTemplateOutlet]="step3Template"></ng-template>
            </ng-container>
            <ng-container *ngSwitchCase="4">
                <ng-template
                    *ngIf="!isPp"
                    [ngTemplateOutlet]="step4Template"></ng-template>
                <app-phone-summary
                    *ngIf="isPp && project"
                    [ppCheckout]="ppCheckout"
                    [project]="project"
                    [floorplanBumpRequests]="floorplanBumpRequests"
                    [eta]="eta"
                    [couponEnabled]="couponEnabled"
                    [couponCode]="couponCode"
                    [serviceCounts]="serviceCounts"
                    [limitValue]="limitValue"
                    [wallet]="wallet"
                    [serviceTypes]="tasks"
                    [furnitureStyles]="furnitureStyles"
                    [roomTypes]="roomTypes" />
            </ng-container>
        </ng-container>
    </div>
    <div class="steps">
        <hr [width]="indicatorWidth" />
        <button
            class="back"
            [class.disabled]="
                processingProject ||
                (currentStep === 4 && !isPp) ||
                (currentStep === 4 && isPp && !ppCheckout) ||
                (currentStep === 1 && noProjects)
            "
            (click)="goBack()">
            Back
        </button>
        <button
            #nextButtonTooltip="matTooltip"
            class="next"
            [matTooltip]="getTooltip()"
            (mouseenter)="nextButtonTooltip.toggle()"
            (mouseleave)="nextButtonTooltip.toggle()"
            (click)="goNext(); nextButtonTooltip.toggle()">
            {{ getNextTitle() }}
        </button>
    </div>
</div>

<ng-template #step1Template>
    <div class="step step-1">
        <div class="text">
            <div>
                <h1 *ngIf="!isFp()">
                    Let's get started with your first project
                </h1>
                <h1 *ngIf="isFp()">
                    Let's get started with your floor plan render
                </h1>
                <p *ngIf="!isFp()">
                    In the next couple of steps, you'll be able to upload your
                    photos, select the services you need, and add as much or as
                    little additional information and instructions for our
                    designers. Our system will guide you through everything
                    step-by-step.
                </p>
                <p *ngIf="isFp()">
                    In the next few steps, you'll be able to upload your floor
                    plans, select the square footage of your property, and add
                    additional information and instructions for our designers.
                    Our system will guide you through everything step by step.
                </p>
                <p>
                    Should you need help at any step of the way, feel free to
                    reach out to our customer support team via live chat or call
                    us at <b>(206) 350-7201</b>.
                </p>
                <div
                    class="sign"
                    *ngIf="!isFp()">
                    <input
                        type="checkbox"
                        name="dont-show-again"
                        [(ngModel)]="dontShowAgain"
                        hidden
                        id="dont-show-again" />
                    <label for="dont-show-again"
                        ><span class="checkmark"
                            ><mat-icon
                                svgIcon="blueCheckmark2"
                                *ngIf="dontShowAgain"
                        /></span>
                        Don't show this page again.</label
                    >
                </div>
            </div>
        </div>
        <div class="image">
            <img
                *ngIf="!isFp()"
                src="assets/house.jfif"
                alt="staged house isometric view" />
            <img
                *ngIf="isFp()"
                src="assets/3dfp.png"
                alt="staged floor plan isometric view" />
        </div>
    </div>
</ng-template>

<ng-template #step2Template>
    <div class="step step-2">
        <div>
            <h1>Now, let's give your project a title</h1>
            <p>Usually the property address. You can always change it later.</p>
            <input
                type="text"
                [(ngModel)]="projectName"
                id="project-name"
                maxlength="32"
                autocomplete="off" />
            <div class="limit-indicator">
                <p>{{ projectName.length }}/32</p>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #step3Template>
    <div class="step step-3">
        <div>
            <div
                class="text"
                *ngIf="!isFp()">
                <h1>Upload Photos of Your Property</h1>
                <p>
                    Select and upload the photos that you'd like our designers
                    to work on.
                </p>
                <p>
                    Once your first photo is uploaded, you'll be able to choose
                    your desired services, furniture style, and provide any
                    specific instructions you may have.
                </p>
                <p>
                    Drag and drop your first photo to begin. You can always add
                    more or remove photos as needed later on.
                </p>
            </div>
            <div
                class="text"
                *ngIf="isFp()">
                <h1>Upload Photos of Your Floor Plans</h1>
                <p>
                    Select and upload photos of the floor plans you'd like our
                    designers to work on. Once your first floor plan is
                    uploaded, you'll be able to select the square footage and
                    add additional details and instructions for our team.
                </p>
                <p>
                    If your property has more than one floor, you can add more
                    floor plans later on.
                </p>
                <p>
                    If you only have the floor plan in a PDF or other CAD file,
                    please reach out to our customer support team via live chat
                    so we can help you get started.
                </p>
            </div>
            <div
                class="drop-zone"
                [class.dragging]="dragging"
                (dragover)="onDragOver($event)"
                (dragleave)="onDragLeave($event)"
                (drop)="onDrop($event)">
                <div
                    class="drop-zone-content"
                    *ngIf="!uploading">
                    <mat-icon svgIcon="uploadImage"></mat-icon>
                    <h3>Drag & drop or browse your photos</h3>
                    <p>Maximum Upload File Size 125mb</p>
                    <input
                        type="file"
                        (change)="onFileSelected($event)"
                        multiple
                        accept="image/x-png, image/gif, image/jpeg, image/png, image/jpg, image/webp, image/tiff, image/heic"
                        hidden
                        id="upload-images" />
                </div>
                <label for="upload-images"></label>
                <div
                    class="progress"
                    *ngIf="uploading">
                    <h1 class="progress-number">{{ totalProgress }}%</h1>
                    <div
                        class="progress-background"
                        [ngStyle]="getDropZoneStyle()"></div>
                </div>
                <div
                    class="errors"
                    *ngIf="errorMessages.length > 0">
                    <p *ngFor="let error of errorMessages">{{ error }}</p>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #step4Template>
    <div class="step step-4">
        <div class="services">
            <div class="text">
                <h1>Now let's tell our designers what to do...</h1>
                <p *ngIf="!isFp()">
                    Click the “Select Services” button in the top-right corner
                    of each photo to specify the type of edits you need.
                </p>
                <p *ngIf="isFp()">
                    Click the “Add Floorplan Service” button in the top-right
                    corner of each photo to provide required information.
                </p>
            </div>
            <div
                class="photos"
                [nxtSortablejs]="project.service_requests"
                [config]="dragOptions">
                <div
                    class="service-request"
                    *ngFor="let serviceRequest of project.service_requests">
                    <div class="photo">
                        <span
                            class="order"
                            *ngIf="serviceRequest.request_image"
                            [matTooltip]="serviceRequest.request_image.image_name"
                            >{{ serviceRequest.sort }}</span
                        >
                        <span
                            class="order"
                            *ngIf="serviceRequest.asset"
                            [matTooltip]="serviceRequest.asset.original_name"
                            >{{ serviceRequest.sort }}</span
                        >
                        <mat-icon
                            svgIcon="greenCheckmark"
                            *ngIf="checkIfServiceRequestReady(serviceRequest)"
                            class="ready"></mat-icon>
                        <img
                            [src]="
                                loadedImages.has(serviceRequest)
                                    ? getThumbnail(serviceRequest)
                                    : 'assets/placeholder.png'
                            "
                            (load)="onImageLoad(serviceRequest)"
                            alt="uploaded image" />
                        <div class="buttons">
                            <button
                                mat-button
                                class="add-services"
                                (click)="openServicesModal(serviceRequest)">
                                <mat-icon svgIcon="plus"></mat-icon>
                                <h6>
                                    {{
                                        isFp()
                                            ? "Add Floorplan Service"
                                            : "Select Services"
                                    }}
                                </h6>
                            </button>
                            <button
                                mat-button
                                (click)="deleteServiceRequest(serviceRequest)"
                                class="menu-button">
                                <mat-icon svgIcon="delete"></mat-icon>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="add-more-wrapper">
                    <div
                        class="photo add-more"
                        [class.dragging]="dragging"
                        (dragover)="onDragOver($event)"
                        (dragleave)="onDragLeave($event)"
                        (drop)="onDrop($event)">
                        <mat-icon
                            svgIcon="addImage"
                            *ngIf="!uploading"></mat-icon>
                        <p *ngIf="!uploading">Add more</p>
                        <input
                            type="file"
                            (change)="onFileSelected($event)"
                            multiple
                            accept="image/x-png, image/gif, image/jpeg, image/png, image/jpg, image/webp, image/tiff, image/heic"
                            hidden
                            *ngIf="!uploading"
                            id="upload-images" />
                        <label
                            for="upload-images"
                            *ngIf="!uploading"></label>
                        <div
                            class="progress"
                            *ngIf="uploading">
                            <h1
                                class="progress-number"
                                *ngIf="!processingUploads">
                                {{ totalProgress }}%
                            </h1>
                            <div
                                class="progress-background"
                                [ngStyle]="getAddMoreStyle()"></div>
                        </div>
                    </div>
                    <div
                        class="errors"
                        *ngIf="errorMessages.length > 0">
                        <p *ngFor="let error of errorMessages">{{ error }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="order-details"
            *ngIf="checkIfAnyServiceRequestsReady()">
            <div
                class="announcements"
                *ngIf="!couponEnabled && !isFp()">
                <img
                    src="assets/bulk-discount.png"
                    alt="" />
                <div
                    class="discount-info"
                    *ngIf="seesBulkDiscount()">
                    <p>
                        Order virtual staging for 12 images and we'll stage 3
                        more for free. That's 15 images staged for only $19.20
                        per image.
                    </p>
                    <p>
                        If you need more than 15, each additional image will be
                        discounted at $19.
                    </p>
                </div>
                <div
                    class="special-offer"
                    *ngIf="specialOfferValid">
                    <h3>
                        <mat-icon svgIcon="specialOffer" />Limited time offer
                    </h3>
                    <p>
                        Order Virtual Staging for 12 photos or more and receive
                        two FREE virtual staging credits, which equals to two
                        virtually staged photos every month for the next 12
                        months ($576 value).
                        <br />
                        <br />
                        Simply place your order in the next
                        {{ getTimeUntilExpiry().hours }} hours and
                        {{ getTimeUntilExpiry().minutes }} minutes and the offer
                        will automatically activate.
                    </p>
                    <p>
                        <button (click)="show12MonthsOfferDialog()">
                            Click here
                        </button>
                        to learn the rules of the promotion.
                    </p>
                    <div
                        class="special-expiry"
                        *ngIf="!specialActivated()">
                        <p class="hours">{{ getTimeUntilExpiry().hours }}</p>
                        <p>Hrs</p>
                        <p class="minutes">
                            {{ getTimeUntilExpiry().minutes }}
                        </p>
                        <p>Mins</p>
                        <hr />
                    </div>
                    <p
                        class="special-activated"
                        *ngIf="specialActivated()">
                        <mat-icon svgIcon="blueCheckmark" />
                        ACTIVATED
                    </p>
                </div>
            </div>
            <div
                class="floorplan-bump"
                *ngIf="!isFp() && hasFp()">
                <img
                    src="assets/interactive-landing-page.png"
                    alt="" />
                <div class="bump-info">
                    <p>
                        You can now add an interactive landing page with your
                        floor plan order.
                        <a
                            [href]="getExampleUrl()"
                            target="_blank"
                            >Click here</a
                        >
                        to see an example.
                        <br />
                        <br />
                        The landing page will feature your virtual staging
                        photos and floor plan.
                        <br />
                        <br />
                        The more photos you stage, the better the landing page
                        will look, with more position points on the floor plan.
                    </p>
                    <div
                        class="sign"
                        *ngIf="!isFp()">
                        <input
                            type="checkbox"
                            name="fp-bump"
                            [(ngModel)]="fpBump"
                            hidden
                            id="fp-bump" />
                        <button (click)="addFpBump()">
                            <span class="checkmark"
                                ><mat-icon
                                    svgIcon="blueCheckmark2"
                                    *ngIf="fpBump"
                            /></span>
                            Yes, I'd like an interactive landing page for ${{
                                fpBumpPrice
                            }}.
                        </button>
                    </div>
                </div>
            </div>
            <div class="pricing">
                <div class="header">
                    <img
                        [src]="getThumbnail(project.service_requests[0])"
                        alt="" />
                    <div class="meta">
                        <p>Order details</p>
                        <h4>{{ project.name }}</h4>
                        <p>
                            <mat-icon svgIcon="photo" />{{
                                project.service_requests.length
                            }}
                            images
                        </p>
                    </div>
                </div>
                <p class="eta">
                    <mat-icon svgIcon="clock" />Estimated Turnaround:
                    {{
                        eta !== "Awaiting Review"
                            ? eta + "h"
                            : eta || "Calculating..."
                    }}
                </p>
                <div class="details">
                    <h3>Order details</h3>
                    <div class="items">
                        <div
                            class="item"
                            (click)="expandCollapse(serviceRequest.id)"
                            *ngFor="let serviceRequest of orderItems()">
                            <p>
                                <mat-icon
                                    class="expand-icon"
                                    *ngIf="
                                        expandedRequest !== serviceRequest.id
                                    "
                                    svgIcon="plus3" />
                                <mat-icon
                                    class="expand-icon"
                                    *ngIf="
                                        expandedRequest === serviceRequest.id
                                    "
                                    svgIcon="minus3" />
                                <span *ngIf="!isFp()">{{
                                    serviceRequest.is_floor_plan
                                        ? getFloorPlanBumpName(serviceRequest)
                                        : serviceRequest.name
                                }}</span>
                                <span *ngIf="isFp()"
                                    >Floor plan
                                    {{
                                        serviceRequest.name.split(" ")[1]
                                    }}</span
                                >
                                <span class="ml-auto">{{
                                    serviceRequest.total === 0 &&
                                    checkIfServiceRequestReady(serviceRequest)
                                        ? "FREE"
                                        : "$" + serviceRequest.total.toFixed(2)
                                }}</span>
                            </p>

                            <div
                                class="breakdown"
                                [@expandCollapse]="
                                    expandedRequest === serviceRequest.id
                                        ? 'expanded'
                                        : 'collapsed'
                                ">
                                <div
                                    class="child-item"
                                    *ngFor="
                                        let service of getTasks(serviceRequest)
                                    ">
                                    <p>{{ service.name }}</p>
                                    <span>
                                        ${{
                                            getTotalForService(service).toFixed(
                                                2
                                            )
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div
                            class="item"
                            *ngIf="fpBump">
                            <p>
                                <span> Interactive Floorplan </span>
                                <span class="ml-auto"
                                    >${{ fpBumpPrice.toFixed(2) }}</span
                                >
                            </p>
                        </div>
                        <div
                            class="coupon"
                            *ngIf="couponEnabled">
                            <div
                                class="not-applied"
                                *ngIf="!project.discount">
                                <h5>Have a Coupon Code?</h5>
                                <div
                                    class="input-wrapper"
                                    [class.error]="couponError">
                                    <input
                                        type="text"
                                        [(ngModel)]="couponCode"
                                        (keydown)="handleCouponKeydown($event)"
                                        name="coupon" />
                                    <button
                                        (click)="checkBeforeApplyingCoupon()">
                                        Apply
                                    </button>
                                </div>
                            </div>
                            <div
                                class="applied"
                                *ngIf="project.discount?.discount">
                                <h5>Coupon Applied</h5>
                                <p>
                                    {{ project.discount.name }}
                                    <span
                                        >-{{ project.discount.discount }}%</span
                                    >
                                </p>
                            </div>
                        </div>

                        <div
                            class="item discount"
                            *ngIf="totalDiscount > 0">
                            <p>
                                Discount
                                <span>${{ totalDiscount.toFixed(2) }}</span>
                            </p>
                        </div>
                        <div
                            class="item discount"
                            *ngIf="vsCreditsApplied > 0">
                            <p>
                                VS Credits
                                <span>{{ vsCreditsApplied }}</span>
                            </p>
                        </div>
                        <div
                            class="item discount"
                            *ngIf="walletFundsApplied > 0">
                            <p>
                                Wallet funds
                                <span
                                    >${{ walletFundsApplied.toFixed(2) }}</span
                                >
                            </p>
                        </div>
                    </div>
                </div>
                <div class="total">
                    <p>Total <span>(USD)</span></p>
                    <span
                        >${{
                            (
                                project.total -
                                couponDiscount -
                                vsCreditsApplied * 24 -
                                walletFundsApplied
                            ).toFixed(2)
                        }}</span
                    >
                </div>
            </div>
            <div class="enable-messages">
                <p>
                    Emails can sometimes be unreliable. If you don't receive any
                    email updates from us regarding your project, please log in
                    and check the status. Alternatively, you can
                    <button (click)="openPhoneModal()">click here</button>
                    to enable text message notifications.
                </p>
            </div>
        </div>
    </div>
</ng-template>

<div
    style="
        position: fixed;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        z-index: 999999999 !important;
        font-size: 1.2rem;
        font-weight: 500;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
    "
    *ngIf="processingProject">
    <span
        style="
            text-align: center;
            display: block;
            max-width: 500px;
            padding: 0 20px;
            color: var(--color-neutral-900);
            font-size: 2rem;
        "
        >Please do not refresh the page or use the "Back" button. We're
        processing your project and preparing to redirect you to our secure
        checkout page. This process can take up to 30 seconds.</span
    >
    <div class="loading-indicator">
        <span class="thumb"></span>
    </div>
</div>

<ng-template #12MonthsOfferDialog>
    <div class="special-offer-content">
        <button (click)="dialog.closeAll()">
            <mat-icon svgIcon="xMark" />
        </button>
        <h2>Free Virtual Staging for 12 Months</h2>
        <h5>Offer Details</h5>
        <p>
            Promotional credits will be added to your account every 30 days,
            aimed at enhancing your experience with virtual staging and
            demonstrating the long-term benefits.<br /><br />
            It's important to note that these credits do not accumulate and must
            be utilized within the 30-day cycle; any credits left unused at the
            end of each month will expire and cannot be carried over to the next
            period. The total value of this promotion is $576, assuming the
            credits are used each month.<br /><br />
            This offer is time-sensitive and expires in
            {{ getTimeUntilExpiry().hours }} hours and
            {{ getTimeUntilExpiry().minutes }} minutes from now. Once you add
            Virtual Staging to 12 images or more, our system will notify you
            that the offer has been activated.<br /><br />
            This offer cannot be reactivated again. Take full advantage of this
            offer before it expires.
        </p>
    </div>
</ng-template>
