import { Component, ViewChild, Renderer2, Input } from '@angular/core';
import { environment } from 'src/app/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ApiService } from 'src/app/services/api.service';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { indexOf } from 'lodash';
import { UpdatePhoneComponent } from 'src/app/components/update-phone/update-phone.component';

@Component({
    selector: 'app-in-review',
    templateUrl: './in-review.component.html',
    styleUrls: ['./in-review.component.scss'],
    animations: [
        trigger('expandCollapse', [
            state('collapsed', style({ maxHeight: '0px', overflow: 'hidden', padding: '0px' })),
            state('expanded', style({ maxHeight: '2000px', overflow: 'hidden', padding: '*' })),
            transition('collapsed <=> expanded', [
                animate('300ms ease-in-out')
            ])
        ])
    ]
})
export class InReviewComponent {
    public project: any;
    public user: any;
    public duration: any;
    public estimation: any;
    public remainingTime: any;
    public loading: boolean = true;
    @ViewChild('detailsDialog') detailsDialog: any;
    public activeService: any;
    public allStyles: any;
    public currentImageHeight: string = '60rem';
    public currentImageData: any = [];
    @ViewChild('imageElement') imageElement: any;
    @Input() articles: any[] = [];

    constructor(
        public dialog: MatDialog,
        private api: ApiService,
        public breakpointService: BreakpointService,
        private renderer: Renderer2
    ) { }

    ngOnInit() {
        this.calculateRemainingTime();
        for (let request of this.project.service_requests) {
            for (let service of request.service_request_tasks) {
                if (service.data) {
                    service.data = JSON.parse(service.data);
                }
            }
        }
        this.api
            .get("user_settings", { type: "furnitureStyle", active: "1" })
            .then((res) => {
                this.allStyles = res;
                this.loading = false;
            });
        this.user = JSON.parse(localStorage.getItem('user') || '{}');
    }

    checkIfPortrait() {
        const img = this.imageElement.nativeElement;
        if (img.complete) {
            this.processImage(img);
        } else {
            img.onload = () => {
                this.processImage(img);
            };
        }
    }

    processImage(img: HTMLImageElement) {
        const aspectRatio = img.naturalWidth / img.naturalHeight;
        if (aspectRatio < 1) {
            this.renderer.setStyle(this.imageElement.nativeElement, 'height', this.currentImageHeight);
        }
    }
    calculateRemainingTime() {
        if (this.project.estimation_time) {
            const endDate: any = this.getEndDate(new Date(this.project.estimation_time));
            const currentTime: any = new Date();
            const duration = endDate - currentTime;

            const countdownEl = {
                hours: Math.floor(duration / (1000 * 60 * 60)),
                minutes: Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60))
            };

            const hours = countdownEl.hours.toString().padStart(2, '0');
            const minutes = countdownEl.minutes.toString().padStart(2, '0');

            if (duration >= 0) {
                this.remainingTime = hours + ":" + minutes;
                this.estimation = countdownEl.hours + " Hours " + countdownEl.minutes + " Minutes";
            } else {
                this.remainingTime = "Done";
                this.estimation = "Done";
            }
        } else {
            this.estimation = "In Review";
        }
    }

    getEndDate(estimation: any) {
        if (this.project.deadline) {
            return new Date(this.project.deadline);
        }
        const startDate = new Date(this.project.start_time);
        if (estimation instanceof Date) {
            return estimation;
        } else {
            return new Date(startDate.getTime() + estimation);
        }
    }

    getThumbnail(serviceRequest: any) {
        let url = environment.cdn + serviceRequest.asset.url;
        if (!url) {
            return 'assets/placeholder.png'
        }
        return environment.server + "/asset/thumbnail/low/" + url.split("/").slice(-1)[0];
    }

    getTasks(serviceRequest: any) {
        return serviceRequest.service_request_tasks.filter((task: any) => !task.main_task);
    }

    getTotal(serviceRequest: any) {
        return serviceRequest.service_request_tasks.reduce((a: any, b: any) => a + b.price, 0);
    }

    openDetailsDialog(serviceRequest: any) {
        this.activeService = serviceRequest.service_request_tasks[0].id;
        this.getAnnotations(serviceRequest, serviceRequest.service_request_tasks[0]);
        let dialogRef = this.dialog.open(this.detailsDialog, {
            data: {
                serviceRequest
            },
            panelClass: 'details-dialog',
            maxWidth: '100vw',
        });
        dialogRef.afterOpened().subscribe(() => {
            this.checkIfPortrait();
        });
    }

    getInfo(service: any): any[] {
        let infoToShow: any = { description: 'Additional comments', goldenHour: 'Variation', furnitureStyles: 'Furniture style', roomTypes: 'Room type' };

        let info: any[] = [];

        for (let [key, value] of Object.entries(service.data)) {
            if (infoToShow[key] && value) {
                info.push({ key: infoToShow[key], value: this.getValue(key, value) });
            }
        }

        return info;
    }

    closeDialog() {
        this.dialog.closeAll();
    }

    getValue(key: string, value: any) {
        switch (key) {
            case 'goldenHour':
                if (value === '1') {
                    return 'Clear sky';
                } else return 'Dramatic sky'
            case 'furnitureStyles':
                let style = this.allStyles.find((style: any) => style.id === value);
                return style?.name;
            default:
                return value;
        }
    }

    getAnnotations(serviceRequest: any, activeService: any) {
        let service = serviceRequest.service_request_tasks.find((service: any) => service.id === activeService.id);
        if (service) {
            this.currentImageData = service.data?.imageData;
        } else {
            this.currentImageData = [];
        }
    }

    getPositionStyle(annotation: any) {
        return {
            top: `calc(${annotation.positionY}% - 15px)`,
            left: `calc(${annotation.positionX}% - 15px)`,
        };
    }

    expandCollapse(serviceRequest: any, service: any) {
        this.currentImageData = [];
        if (this.activeService === service.id) {
            this.activeService = null;
        } else {
            this.activeService = service.id;
            setTimeout(() => {
                this.getAnnotations(serviceRequest, service);
            }, 300)
        }
    }

    checkField(row: number, column: number) {
        const copyOfData = [...this.currentImageData];
        return copyOfData.filter((item) =>
            item.row === row.toString() && item.col === column.toString()
        ).length > 0;
    }

    getUrl(serviceRequest: any) {
        let url = environment.cdn + serviceRequest.asset.url;
        return environment.server + "/asset/thumbnail/" + url.split("/").slice(-1)[0];
    }


    openPhoneModal() {
        this.dialog.open(UpdatePhoneComponent, {
            panelClass: 'phone-modal'
        });
    }


    serviceRequests() {
        return [...this.project.service_requests, ...this.project.floorplan_requests || []];
    }

    getFloorPlanBumpName(floorplanBump: any) {
        if (floorplanBump.label) {
            return floorplanBump.label;
        }
        return "Floor plan " + (indexOf(this.project.floorplan_requests, floorplanBump) + 1);
    }
}
