<div
    class="wrapper"
    [class]="breakpointService.currentBreakpointClass">
    <div
        class="tabs"
        *ngIf="!isPp">
        <button
            class="required-info"
            (click)="activeTab = 1"
            [ngClass]="activeTab === 1 ? 'active' : ''">
            Required Info
        </button>
        <button
            class="optional-info"
            (click)="activeTab = 2"
            [ngClass]="activeTab === 2 ? 'active' : ''">
            Optional Info
        </button>
    </div>
    <div
        class="select"
        *ngIf="activeTab === 1 || isPp">
        <p class="use-grid">
            Please use the <span (click)="scrollToGrid()">grid tool</span> to
            select the area where the items you want removed are located. We'll
            calculate the Item Removal price based on the area you select. If
            you have any specific instructions or preferences, feel free to
            leave a comment in the “Optional Info” tab.
        </p>
        <div class="item-removal-data">
            <p>
                1% = <span class="blue">{{ task.price | currency }}</span>
            </p>
            <p>
                Area Selected
                <span class="orange"
                    >{{ service.data.imageData?.length * 2.5 }}%</span
                >
            </p>
            <p>
                Total cost:
                <span>{{
                    service.data.imageData?.length * 2.5 * task.price | currency
                }}</span>
            </p>
        </div>
    </div>
    <div
        class="comments"
        *ngIf="activeTab === 2 || isPp">
        <h4 *ngIf="!isPp"><label for="comment-box">Your comments:</label></h4>
        <button
            class="comments-label-pp"
            *ngIf="isPp"
            (click)="expanded.comments = !expanded.comments">
            <h3>Your comments (optional)</h3>
            <mat-icon
                *ngIf="!expanded.comments"
                svgIcon="plus3" />
            <mat-icon
                *ngIf="expanded.comments"
                svgIcon="minus3" />
        </button>
        <textarea
            id="comment-box"
            *ngIf="expanded.comments || !isPp"
            [(ngModel)]="service.data.description"
            (ngModelChange)="checkIfNeedsToSave()"
            placeholder="Please use the grid tool to select the area where the items you want removed are located. We'll calculate the Item Removal price based on the area you select. If there are a few items in the area you selected, and you need to clarify which one(s) you need removed, please leave a comment here. Otherwise, this field is optional and can be left blank as long as the grid has been selected."
            name="comments"
            rows="9">
        </textarea>
    </div>
    <div
        class="upload-ref"
        *ngIf="activeTab === 2 || isPp">
        <app-upload-ref
            (refAdded)="addReferenceImage($event)"
            (refRemoved)="removeReferenceImage($event)"
            [projectGuid]="projectGuid"
            [serviceRequest]="serviceRequest"
            [service]="service"></app-upload-ref>
    </div>
</div>
