import { Component, Input, TemplateRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { environment } from '../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import { ContinuationPopupComponent } from '../continuation-popup/continuation-popup.component';
import { SharingIsCaringComponent } from 'src/app/components/sharing-is-caring/sharing-is-caring.component';

@Component({
    selector: 'app-project-list-card',
    templateUrl: './project-list-card.component.html',
    styleUrls: ['./project-list-card.component.scss']
})
export class ProjectListCardComponent {
    @ViewChild('renameProjectModal') renameProjectModal: TemplateRef<any> | undefined;
    public currentImage: number = 0;
    @Input() project: any;
    public buttons: any;
    @Output() onProjectDeleted: EventEmitter<any> = new EventEmitter();
    private carouselInterval: any;
    public showTrustpilot: boolean = false;
    public duration: any;
    public estimation: any;
    public remainingTime: any;

    constructor(
        private dialog: MatDialog,
        private api: ApiService,
        public breakpointService: BreakpointService,
        private router: Router
    ) { }

    ngOnInit() {
        this.buttons = new Array(this.project.thumbnails.length);
        if (this.project.status === "Done") {
            this.calculateDeliveryTime();
        }
        if (this.project.status_code === 'in-progress' || this.project.status_code === 'requires-attention') {
            this.calculateRemainingTime();
        }
    }

    showDeliveryTime() {
        return this.project.status === "Done" && (this.project.start_time_old || this.project.start_time) && (this.project.completion_time || this.project.completion_time_old) && this.breakpointService.currentBreakpointClass !== 'is-tablet-portrait';
    }

    calculateDeliveryTime() {
        const startDate: any = this.project.start_time_old
            ? new Date(this.project.start_time_old)
            : new Date(this.project.start_time);
        const finishTime: any = this.project.completion_time_old
            ? new Date(this.project.completion_time_old)
            : new Date(this.project.completion_time);


        const diffInMilliseconds = finishTime - startDate;
        const diffInMinutes = Math.floor(diffInMilliseconds / 60000);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const remainingMinutes = diffInMinutes % 60;


        const hours = diffInHours.toString();
        const minutes = remainingMinutes.toString();

        this.duration = {
            hours: hours.length < 2 ? "0" + hours : hours,
            minutes: minutes.length < 2 ? "0" + minutes : minutes,
        };
    }

    setCurrentImage(index: number): void {
        this.currentImage = index;
    }

    getThumbnail() {
        let url = this.project.thumbnails[this.currentImage];
        if (!url) {
            return 'assets/placeholder.png'
        }

        let newUrl = environment.server + "/asset/thumbnail/low/" + url.split("/").slice(-1)[0];
        return newUrl;
    }

    calculateRemainingTime() {
        if (this.project.estimation_time) {
            const endDate: any = this.getEndDate(new Date(this.project.estimation_time));
            const currentTime: any = new Date();
            const duration = endDate - currentTime;

            const countdownEl = {
                hours: Math.floor(duration / (1000 * 60 * 60)),
                minutes: Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60))
            };

            const hours = countdownEl.hours.toString().padStart(2, '0');
            const minutes = countdownEl.minutes.toString().padStart(2, '0');

            if (duration >= 0) {
                this.remainingTime = hours + ":" + minutes;
                this.estimation = countdownEl.hours + " Hours " + countdownEl.minutes + " Minutes";
            } else {
                this.remainingTime = "Done";
                this.estimation = "Done";
            }
        } else {
            this.estimation = "In Review";
        }
    }

    getEndDate(estimation: any) {
        if (this.project.deadline) {
            return new Date(this.project.deadline);
        }
        const startDate = new Date(this.project.start_time);
        if (estimation instanceof Date) {
            return estimation;
        } else {
            return new Date(startDate.getTime() + estimation);
        }
    }

    shouldDisplayEta(project: any) {
        return project.estimation_time && project.start_time && project.deadline && (project.status === "In Progress" || project.status === "Requires Attention") && this.remainingTime !== "Done";
    }

    openRenameModal(): void {
        if (this.renameProjectModal) {
            const dialogRef = this.dialog.open(this.renameProjectModal, { panelClass: 'rename-modal' });
        }
    }

    closeRenameModal(): void {
        this.dialog.closeAll();
    }

    renameProject(): void {
        this.api.put(
            `project/${this.project.guid}`,
            { name: this.project.name },
            {}
        ).then(() => {
            this.closeRenameModal();
        });
    }

    openDeleteModal() {
        const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Delete Project',
                message: 'Are you sure you want to delete this project?',
                confirm: {
                    title: 'Delete',
                },
                reject: {
                    title: 'Cancel'
                }
            }
        });
        confirmDialog.afterClosed().subscribe(result => {
            if (result) {
                this.deleteProject();
            }
        });
    }

    closeDeleteModal(): void {
        this.dialog.closeAll();
    }

    deleteProject(): void {
        this.api.delete(
            `project/${this.project.guid}`
        ).then(() => {
            this.onProjectDeleted.emit(this.project.guid);
            this.closeDeleteModal();
        });
    }

    getStatus() {
        if (this.project.status === "Requires Attention") {
            return "In Progress"
        } else return this.project.status;
    }

    openDraft() {
        this.router.navigate([`/projects/${this.project.guid}/summary`]);
    }

    openProject() {
        if (this.project.shared && !this.shareAccepted()) {
            return;
        }
        switch (this.project.status_code) {
            case 'draft':
                this.openDraft();
                break;
            case 'in-progress':
                this.router.navigate([`/projects/${this.project.guid}/delivery`]);
                break;
            case 'in-review':
                this.router.navigate([`/projects/${this.project.guid}/delivery`]);
                break;
            case 'done':
                this.router.navigate([`/projects/${this.project.guid}/delivery`]);
                break;
            case 'rework':
                this.router.navigate([`/projects/${this.project.guid}/delivery`]);
                break;
            case 'canceled':
                this.router.navigate([`/projects/${this.project.guid}/delivery`]);
                break;
        }
    }

    carouselImages() {
        this.carouselInterval = setInterval(() => {
            if (this.currentImage === this.project.thumbnails.length - 1) {
                this.currentImage = 0;
            } else {
                this.currentImage++;
            }
        }, 1000);

    }

    stopCarousel() {
        clearInterval(this.carouselInterval);
    }

    async leaveFeedback(feedback: any) {
        const newRating = await this.api.post(
            `project/${this.project.guid}/rating`,
            {
                rating: this.project.rated,
                description: feedback,
            }
        );
        this.project.rating = newRating;
        if (this.project.rating.rating >= 4) {
            this.showTrustpilot = true;
        }
        this.project.project_rating = true;
        this.dialog.closeAll();
    }

    closeDialog() {
        this.dialog.closeAll();
    }

    parseInt(value: string): number {
        return parseInt(value, 10);
    }

    getBackgroundColor() {
        switch (this.project.status_code) {
            case 'draft':
                return '#BDBDBD';
            case 'in-progress':
                return '#F9C800';
            case 'in-review':
                return '#F9C800';
            case 'done':
                return '#00AD26';
            case 'rework':
                return '#F9C800';
            case 'canceled':
                return '#ff4800';
            default:
                return '#BDBDBD';
        }
    }

    addMorePhotos() {
        if (this.project.status_code === 'draft') {
            this.openDraft();
            return;
        }
        this.dialog.open(ContinuationPopupComponent, {
            data: {
                project: this.project
            },
            maxWidth: '100vw',
        });
    }

    async openShareModal() {
        let data = await this.api.get(`project/${this.project.guid}`);
        this.dialog.open(SharingIsCaringComponent, {
            data: {
                data
            },
            maxWidth: '100vw',
            maxHeight: '100dvh',
        })
    }

    respondToShare(accept: boolean) {
        if (accept) {
            this.api.post(`project/${this.project.guid}/accept`).then(() => {
                this.project.accepted = '1';
            });
        } else {
            this.api.post(`project/${this.project.guid}/reject`).then(() => {
                // remove project from list
                this.onProjectDeleted.emit(this.project.guid);
            });
        }
    }

    confirmAccept() {
        const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Accept Project',
                message: 'Are you sure you want to accept this project?',
                confirm: {
                    title: 'Accept',
                },
                reject: {
                    title: 'Cancel'
                }
            }
        });
        confirmDialog.afterClosed().subscribe(result => {
            if (result) {
                this.respondToShare(true);
            }
        });
    }

    confirmDecline() {
        const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Decline Project',
                message: 'Are you sure you want to decline this project?',
                confirm: {
                    title: 'Decline',
                },
                reject: {
                    title: 'Cancel'
                }
            }
        });
        confirmDialog.afterClosed().subscribe(result => {
            if (result) {
                this.respondToShare(false);
            }
        });
    }

    shareAccepted(): boolean {
        return this.project.accepted === '0' ? false : true;
    }

    gutTable(): boolean {
        return this.breakpointService.currentBreakpointClass === 'is-phone-portrait' || this.breakpointService.currentBreakpointClass === 'is-phone-landscape' || this.breakpointService.currentBreakpointClass === 'is-tablet-portrait' || this.breakpointService.currentBreakpointClass === 'is-custom-portrait' || this.breakpointService.currentBreakpointClass === 'is-tablet-landscape';
    }
}
